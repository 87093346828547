import React, { useState, useEffect } from "react";
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import auth, {
  facebookProvider,
  googleProvider,
} from "../../firebase/firebaseAuth";
import {
  addUserDataToFirebase,
  getDataFromFirebase,
  checkIfUserExists,
} from "../../firebase/firebaseFunctions";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import axios from "axios";
import { getLoginTemplate } from "../../emailTemplates/LoginTemplate";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if user is already logged in
  //   if (localStorage.getItem("userData")) {
  //     navigate("/dashboard"); // Redirect to dashboard if user is logged in
  //   }
  // }, [navigate]);
  const sendEmail = async (user) => {
    try {
      // Fetch user data from Firebase based on email
      const firstName = user.displayName.split(" ")[0];
      const lastName = user.displayName.split(" ")[1];
      const loginTimeStamp = new Date();
      let ipAddress = "";
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((data) => {
          console.log("ip address", data.ip);
          ipAddress = data.ip;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
      console.log("ip address--------->", ipAddress);
      const emailData = {
        to: user.email,
        subject: "New Device Login Alert - Human Concern USA",
        html: `${getLoginTemplate(
          user.displayName,
          ipAddress,
          loginTimeStamp
        )}`,
      };
      try {
        const response = await axios.post(
          "https://hci-webapp.onrender.com/send-email",
          emailData
        );
        console.log("response: ", response);
        if (response.status == 200) {
          console.log("Email sent successfully");
          toast.success("Email has been sent");
          // setEmailData({to:"", subject:"", text:""});
        } else {
          console.error("Failed to send email");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data");
    }
  };

  const forgotpasswordchecker = async () => {
    try {
      getDataFromFirebase("users", async (usersArray) => {
        const user = usersArray.find((user) => user.email === email);
        const userExists = await checkIfUserExists(email);
        if (!userExists || typeof userExists === "object") {
          toast.error("Account is not registered");
          return;
        } else {
          sendPasswordResetEmail(auth, email)
            .then(() => {
              toast.success("Reset link sent to your email");
            })
            .catch((error) => {
              toast.error("Error sending reset link");
            });
        }
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Fetch user data from Firebase based on email
      getDataFromFirebase("users", async (usersArray) => {
        const user = usersArray.find((user) => user.email === email);
        const userExists = await checkIfUserExists(email);
        if (!userExists || typeof userExists === "object") {
          console.log("object");
          toast.error("Account is not registered");
          return;
        }
        if (userExists) {
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              const user = userCredential.user;
              console.log("User logged in successfully:", user);
              // sendEmail(user);
              toast.success("Login successful");
              // localStorage.setItem("isVerified", true);
        localStorage.setItem('tempUser', JSON.stringify(user));

              navigate('/send-otp');

            })
            .catch((error) => {
              console.error("Error logging in:", error);
              toast.error("Try  SignIn with google");
            });
        }
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log("user --- ",user);
      const userExists = await checkIfUserExists(user.email);
      if (!userExists || typeof userExists === 'object') {
        await user.delete(); // Delete the unregistered user from Firebase Auth
        toast.error('Account is not registered');
        return;
      }
        await sendEmail(user);
        localStorage.setItem("isVerified", true);
        navigate("/dashboard");

    } catch (error) {
      console.error("Error signing up with Google:", error);
      toast.error("Error signing up with Google");
    }
  };

  // const handleFacebookSignIn = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, facebookProvider);
  //     const user = result.user;
  //     console.log('user:', user);

  //     if (user.emailVerified) {
  //       await addUserDataToFirebase(user.uid, {
  //         firstName: user.displayName.split(' ')[0],
  //         lastName: user.displayName.split(' ')[1],
  //         email: user.email,
  //         role: 'user'
  //       });
  //       // sendEmail(user);
  //       toast.success('Sign up successful');
  //       navigate('/dashboard');
  //     } else {
  //       setMessage('Please verify your email to complete the signup process.');
  //     }
  //   } catch (error) {
  //     console.error("Error signing up with Facebook:", error);
  //     toast.error('Error signing up with Facebook');
  //   }
  // };

  return (
    <>
      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container authContainer">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
                      Sign In
                    </h1>
                    <p>Enter your email and password to sign in.</p>
                  </Col>
                </Row>
                <form onSubmit={handleLogin}>
                  <Row className="gap-3">
                    <Col xs={12}>
                      <label className="form-label">Email Address:</label>
                      <input
                        className="form-control"
                        type="email"
                        value={email}
                        placeholder="eg. ron@xyz.com"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Col>
                    <Col xs={12}>
                      <label className="form-label d-flex justify-content-between">
                        <span>Password:</span>{" "}
                        <Link
                          onClick={() => forgotpasswordchecker()}
                          className="text-danger"
                        >
                          Forgot Password?
                        </Link>
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        value={password}
                        placeholder="********"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </Col>
                    <Col xs={12} className="mt-2">
                      <button
                        className="btn btn-danger w-100 mb-2 fw-bold r-100"
                        type="submit"
                      >
                        Login
                      </button>
                      <p className="mb-0">
                        Don't have an account?{" "}
                        <Link className="text-danger" to={"/signup"}>
                          Register
                        </Link>{" "}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      className="text-center d-flex align-items-center gap-2"
                    >
                      {/* <Link className='p-2 btn btn-dark w-100 r-100 d-flex justify-content-center align-items-center gap-1' >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_643_1690)">
                            <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_643_1690">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        Sign in with Facebook
                      </Link> */}
                      <Link
                        className="p-2 btn btn-dark w-100 r-100 d-flex justify-content-center align-items-center gap-2"
                        onClick={handleGoogleSignIn}
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_643_1584)">
                            <path
                              d="M24.2663 12.2765C24.2663 11.4608 24.2001 10.6406 24.059 9.83813H12.7402V14.4591H19.222C18.953 15.9495 18.0888 17.2679 16.8233 18.1056V21.104H20.6903C22.9611 19.014 24.2663 15.9274 24.2663 12.2765Z"
                              fill="#4285F4"
                            />
                            <path
                              d="M12.74 24.0008C15.9764 24.0008 18.7058 22.9382 20.6944 21.1039L16.8274 18.1055C15.7516 18.8375 14.3626 19.252 12.7444 19.252C9.61376 19.252 6.95934 17.1399 6.00693 14.3003H2.01648V17.3912C4.05359 21.4434 8.20278 24.0008 12.74 24.0008Z"
                              fill="#34A853"
                            />
                            <path
                              d="M6.00277 14.3002C5.50011 12.8099 5.50011 11.196 6.00277 9.70569V6.61475H2.01674C0.314734 10.0055 0.314734 14.0004 2.01674 17.3912L6.00277 14.3002Z"
                              fill="#FBBC04"
                            />
                            <path
                              d="M12.74 4.74966C14.4508 4.7232 16.1043 5.36697 17.3433 6.54867L20.7694 3.12262C18.6 1.0855 15.7207 -0.034466 12.74 0.000808666C8.20277 0.000808666 4.05359 2.55822 2.01648 6.61481L6.00252 9.70575C6.95052 6.86173 9.60935 4.74966 12.74 4.74966Z"
                              fill="#EA4335"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_643_1584">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        Sign in with Google
                      </Link>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
