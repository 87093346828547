import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import { useAuth } from '../Auth/AuthContext';
import { Button } from 'react-bootstrap';

export default function Blog() {
    const [blogs, setBlogs] = useState([]);
    const [blog, setBlog] = useState({});
    const params = useParams();
    const [comment, setComment] = useState('');
    const auth = useAuth();
    console.log('auth object--------->', auth);


    useEffect(() => {
        getDataFromFirebase('Blog', (blogs) => setBlogs(blogs))
    }, [])

    useEffect(() => {
        console.log('blogs:', blogs);
        if (blogs.length > 0) {
            const requiredBlog = blogs.find(blog => blog.id === params.id);
            console.log('required blog:', requiredBlog);
            if (requiredBlog) {
                setBlog(requiredBlog)
            }
        }
    }, [blogs])

    useEffect(() => {
        console.log('single blog:', blog);
    }, [blog])

    const formatDate = (date) => {
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        };
        
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(date);
        
        // Construct the formatted date string
        const month = parts.find(part => part.type === 'month').value;
        const day = parts.find(part => part.type === 'day').value;
        const year = parts.find(part => part.type === 'year').value;
        const hour = parts.find(part => part.type === 'hour').value;
        const minute = parts.find(part => part.type === 'minute').value;
        const ampm = parts.find(part => part.type === 'dayPeriod').value;
      
        return `${month} ${day}, ${year} ${hour}:${minute} ${ampm}`;
      };

      const addComment = async (blogId, comments) => {
        console.log('before pushing comments-------->', comments);
        const now = formatDate(new Date());
        const commentObject = { submitter: auth?.currentUser?.displayName, comment: comment, time: now };
        comments.push("commentObject", commentObject);
        console.log('after pushing comments-------->', comments);
        await updateFieldsInFirebase(`Blog/${blogId}`, { comments: JSON.stringify(comments) });
        setComment('');
    }

    return (
        <>
            <section className="blog-section-three top-bottom-padding">
                <div className="container">
                    <div className="row gy-24 gap-x-25" >
                        {blog &&
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 view-wrapper">
                                <div >
                                    <h2 className="title text-capitalize" style={{ fontSize: "29px" }}>{blog.blogtitle}</h2>
                                    <p className="subtitle" style={{ fontSize: "20px" }}>{blog.blogDescription}</p>
                                </div>
                                <div className="single-blog h-calc" style={{ height: "auto" }}>
                                    <div className="blog-img">
                                        <img src="/assets/site/images/gallery/blog-7.png" className="img-fluid w-100" alt="img" />
                                        <div className="brush-bg">
                                            <img src="/assets/site/images/gallery/brush.png" alt="image" />
                                        </div>
                                    </div>
                                    <div className="blog-info">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="blog-info-title" >
                                                <div className="flex mb-15 gap-16 align-items-center" >
                                                    <div className="user flex gap-10 align-items-center">
                                                        <i className="ri-user-line" style={{ fontSize: "20px" }}></i>
                                                        <p className="info" style={{ fontSize: "20px" }}>By: admin</p>
                                                    </div>
                                                    <div className="donate flex gap-10 align-items-center">
                                                        <i className="ri-chat-3-line" style={{ fontSize: "20px" }}></i>
                                                        <span style={{ color: 'blue', cursor: "pointer" }}>{ blog.comments && JSON.parse(blog.comments).length > 0 ? JSON.parse(blog.comments).length : ''} {blog.comments && JSON.parse(blog.comments).length > 0 ? 'Comments' : ''} </span>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { auth.currentUser &&
                                <div >
                                    <h2 style={{marginBottom:"30px"}}><b>Comments</b></h2>
                                    {blog?.comments && JSON.parse(blog?.comments) && JSON.parse(blog?.comments).map((comment) => (
                                        <div className='mb-3' style={{
                                            borderRadius: "12px"
                                            ,backgroundColor: '#e6e6e6',
                                            padding:"20px"
                                        }}>
                                            <div style={{display: "flex", gap: "4px"}}>
                                                <b>{comment.submitter}</b>
                                                <span>{comment.time}</span>
                                            </div>
                                            <div >
                                                {comment.comment}
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                              }
                              {auth.currentUser &&
                                <div style={{ marginTop: '150px' }}>
                                    <div>
                                        <textarea placeholder='Write a Comment' value={comment} style={{ width: '100%' }} onChange={(event) => { setComment(event.target.value) }}></textarea>
                                    </div>
                                    <div className='d-flex'>
                                        <Button onClick={() => addComment(blog.id, JSON.parse(blog.comments))}> Write Comment</Button>
                                    </div>
                                </div>
                                }

                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
