import axios from "axios";
import { getPayPalAccessToken } from "../../Paypal/payPalApiCalls";
export const accessToken = await getPayPalAccessToken();

export const fetchPaypalSubscription = async (subscriptionId) => {
  try {
    // Make a request to fetch the subscription details
    const response = await axios({
      method: "GET",
      url: `https://api-m.sandbox.paypal.com/v1/payments/billing-agreements/${subscriptionId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    // Handle the response and return the subscription details
    console.log("subscriptionId", subscriptionId);
    console.log("paypal sub", response.data);
    return { ...response.data, status: response.data.state.toLowerCase() };
  } catch (error) {
    console.error("Error fetching PayPal subscription details:", error);
    throw error;
  }
};
