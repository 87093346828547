import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageCarousel = ({ images = [] }) => {
  const settings = {
    dots: false,
    infinite: true, // Infinite loop
    speed: 500,
    slidesToShow: 6, // Default number of images to show at a time
    slidesToScroll: 1, // Move one image at a time
    autoplay: true, // Enable auto play
    autoplaySpeed: 2000, // Auto slide every 2 seconds
    arrows: false, // Hide arrows for controls
    pauseOnHover: false, // Do not pause on hover
    pauseOnFocus: false, // Do not pause on focus
    responsive: [
      {
        breakpoint: 1024, // Tablet size and below
        settings: {
          slidesToShow: 5 // Show 5 images at a time on tablets
        }
      },
      {
        breakpoint: 768, // Mobile size
        settings: {
          slidesToShow: 3 // Show 3 images at a time on mobile
        }
      }
    ]
  };

  // Ensure images array exists and has content
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div className="image-carousel">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-image">
            <img
              src={image}
              alt={`carousel-${index}`}
              style={{ width: '100px', height: '100px' }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
