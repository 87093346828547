import React from "react";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Lottie from "lottie-react";
import animationData from '../../loading.json';

const LoadingOverlay = ({ loading, children }) => {
  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            zIndex: 500,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ width: 120, height: 120 }}
          />
        </div>
      )}
      <div style={{ opacity: loading ? 0.5 : 1 }}>{children}</div>
    </div>
  );
};

export default LoadingOverlay;
