import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export default function NotFound() {
  return (
    <>
    <section className="hero-area">
      <div className="single-slider hero-padding">
        <div className="container">
          <Row>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
                Error 404!
              </h1>
              <p>
              We apologize, but the page you requested is not available. Please navigate back or use the search bar to find what you need.
              </p>
              <Link
                to={"/"}
                className="btn-primary-fill pill-btn"
              >
                Back to Home Page
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </section>
    </>
  )
}
