// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyAKF4R6rcKXPCiooQfglD_pR8J9CW57qb8",
//     authDomain: "hci-canada.firebaseapp.com",
//     projectId: "hci-canada",
//     storageBucket: "hci-canada.appspot.com",
//     messagingSenderId: "457968313782",
//     appId: "1:457968313782:web:87407a360a91d09ff04d17"
//   };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// export default app;


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAnegdAJzofxKOVmoniP9HsI9APGjOMwsM",
  authDomain: "human-concern-usa.firebaseapp.com",
  projectId: "human-concern-usa",
  databaseURL: "https://human-concern-usa-default-rtdb.firebaseio.com/",
  storageBucket: "human-concern-usa.appspot.com",
  messagingSenderId: "629783766938",
  appId: "1:629783766938:web:8791461bb6d152cf557c23",
  measurementId: "G-8BJ9WVY7DV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const imageDb = getStorage(app);

export default app;