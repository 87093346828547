import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import auth from '../../firebase/firebaseAuth';
import { FaSignOutAlt } from 'react-icons/fa';

function Signout() {
  const navigate = useNavigate();

  const handleSignout = async () => {
    try {
      await auth.signOut(); // Firebase sign out method
      localStorage.removeItem('userData'); // Remove user data from localStorage
      localStorage.setItem('isVerified',false);
      navigate('/login'); // Redirect to login page after sign out
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle sign out error
    }
  };


  return (
    <Link onClick={handleSignout} className="text-body ms-0">
      <FaSignOutAlt className="me-2 icon-md"/>
      <span>Log Out</span>
    </Link>
  );
}

export default Signout;
