import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'react-bootstrap';
import { addDataToFirebase, getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';

function AddUpdateJobOpening() {
    const [formData, setFormData] = useState({ title: "", description: "", fileUrl: "", isActive: false });
    const [jobOpenings, setJobOpenings] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        getDataFromFirebase('jobOpenings', (jobOpenings) => setJobOpenings(jobOpenings));
    }, []);

    useEffect(() => {
        if (params.id) {
            let requiredJobOpening = {};
            if (jobOpenings.length > 0) {
                requiredJobOpening = jobOpenings.find(jobOpening => jobOpening.id === params.id);
            }
            if (requiredJobOpening) {
                console.log('Required Job Opening:', requiredJobOpening);
                const jobOpening = { 
                    title: requiredJobOpening.title, 
                    description: requiredJobOpening.description, 
                    fileUrl: requiredJobOpening.fileUrl,
                    isActive: requiredJobOpening.isActive || false 
                };
                setFormData(jobOpening);
            }
        }
    }, [params.id, jobOpenings]);

    useEffect(() => {
        console.log('Form Data:', formData);
    }, [formData]);

    const handleJobOpening = async (e) => {
        e.preventDefault();
        try {
            if (formData.title && formData.description && formData.fileUrl) {
                const jobOpeningObject = { 
                    title: formData.title, 
                    description: formData.description, 
                    fileUrl: formData.fileUrl,
                    isActive: formData.isActive 
                };
                if (params.id) {
                    await updateFieldsInFirebase(`jobOpenings/${params.id}`, jobOpeningObject);
                } else {
                    await addDataToFirebase('jobOpenings', jobOpeningObject);
                }
                navigate('/dashboard/job-openings');
                setError('');
            } else {
                setError('Please fill all fields');
            }
        } catch (error) {
            console.error("Error saving job opening:", error);
            toast.error('Error saving job opening');
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h4 className='title font-700 wow fadeInUp mt-2 mb-3'>{params.id ? "Update Job Opening" : "Create Job Opening"}</h4>
                        </Col>
                    </Row>
                    <form onSubmit={handleJobOpening}>
                        <Row className='gap-3'>
                            <Col xs={12}>
                                <label className='form-label'>Job Title</label>
                                <input className='form-control' name='title' type="text" value={formData.title} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Job Description</label>
                                <textarea className='form-control' name='description' type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required >
                                </textarea>
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>File URL</label>
                                <input className='form-control' name='fileUrl' type="text" value={formData.fileUrl} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12} className='d-flex gap-2 align-items-center'>
                                <label className='form-label mb-0'>Active</label>
                                <input
                                    type="checkbox"
                                    name="isActive"
                                    checked={formData.isActive}
                                    onChange={(e) => setFormData({ ...formData, isActive: e.target.checked })}
                                />
                            </Col>
                            {error && <div><span style={{ color: 'red' }}>{error}</span></div>}
                        </Row>
                        <Row className='mt-3'>
                            <div className='d-flex'>
                                <Button className='btn btn-success' type="submit">{params.id ? "Update Job Opening" : "Create Job Opening"}</Button>
                                <Button className='btn btn-danger' style={{ marginLeft: "15px" }} onClick={() => navigate('/dashboard/job-openings')}>Cancel</Button>
                            </div>
                        </Row>
                    </form>
                </Col>
            </Row>
        </>
    );
}

export default AddUpdateJobOpening;
