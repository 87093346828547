import React, { useEffect } from "react";
import {
  capturePaypalPayment,
  getPayPalAccessToken,
  executePayPalBillingAgreement,
} from "../../Paypal/payPalApiCalls"; // Import the executePayPalBillingAgreement function
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { storePaymentDataInFirebase } from "../Utils/storePaymentDataInFirebase";
import Lottie from "lottie-react";
import animationData from "../../loading.json";
import { Col, Row } from "react-bootstrap";

export default function CapturePayPal() {
  const navigate = useNavigate();

  const capturePayment = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      const payerID = urlParams.get("PayerID");
      const isRecurring = localStorage.getItem("isRecurring") === "true";

      if (token) {
        const payPalToken = await getPayPalAccessToken();
        const { formDataArray, checkoutID, paymentGateway } = JSON.parse(
          localStorage.getItem("checkoutData")
        );

        if (isRecurring) {
          // Recurring payment flow: execute the billing agreement
          const billingAgreement = await executePayPalBillingAgreement(
            payPalToken,
            token
          );

          if (billingAgreement.state === "Active") {

            await storePaymentDataInFirebase(
              checkoutID,
              formDataArray,
              paymentGateway,
              billingAgreement.id
            );

            toast.success("Your subscription has been successfully activated.");
            localStorage.removeItem("checkoutData");
            localStorage.removeItem("isRecurring");
            navigate("/thank-you");
          } else {
            toast.error("Failed to activate subscription.");
          }
        } else {
          // One-time payment flow: capture the payment
          if (payerID) {
            const paymentCaptured = await capturePaypalPayment(
              payPalToken,
              token
            );

            if (paymentCaptured.status === "COMPLETED") {
              // const { formDataArray, checkoutID } = JSON.parse(
              //   localStorage.getItem("checkoutData")
              // );

              await storePaymentDataInFirebase(
                checkoutID,
                formDataArray,
                paymentGateway,
              );

              toast.success("Your payment has been successful.");
              localStorage.removeItem("checkoutData");
              localStorage.removeItem("isRecurring");
              navigate("/thank-you");
            } else {
              toast.error("Payment capture failed.");
            }
          } else {
            toast.error("Missing PayPal parameters in URL.");
          }
        }
      } else {
        toast.error("Missing PayPal parameters in URL.");
      }
    } catch (error) {
      toast.error("Error processing PayPal payment: " + error.message);
      console.error("Error processing PayPal payment:", error);
    }
  };

  useEffect(() => {
    capturePayment();
  }, []);

  return (
    <section className="hero-area">
      <div className="single-slider hero-padding">
        <div className="container">
          <Row>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{ width: 120, height: 120 }}
              />
              <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
                Processing Your Pal Payment!
              </h1>
              <p>Happy Donations. Evey penny means a lot.</p>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
