import React, { useContext, useState, useEffect } from "react";
import auth from "../../firebase/firebaseAuth"; // Import Firebase auth
import { getDatabase, ref, onValue } from "firebase/database";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Function to listen for user data changes in the Realtime Database
  const listenToUserData = (uid) => {
    const db = getDatabase();
    const userRef = ref(db, `users/${uid}`);

    // Listen for data changes at the user's reference
    const unsubscribe = onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      console.log("User data updated:", data);
      setCurrentUser((prevUser) => ({ ...prevUser, ...data }));
    }, (error) => {
      console.error("Error listening to user data: ", error);
    });

    return unsubscribe;
  };

  useEffect(() => {
    let userUnsubscribe = null;

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User logged in:", user);
        userUnsubscribe = listenToUserData(user.uid);
        setCurrentUser(user); // Initial set of user data
      } else {
        console.log("No user logged in");
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
      if (userUnsubscribe) {
        userUnsubscribe();
      }
    };
  }, []);

  const value = {
    currentUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
