import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DataTable from '../DataTable';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { useNavigate } from 'react-router-dom';

export default function AdminJobOpenings() {
    const [jobOpenings, setJobOpenings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('jobOpenings', (jobOpenings) => {
            console.log('Job Openings:', jobOpenings);
            setJobOpenings(jobOpenings);
        });
    }, []);

    const handleRowClick = (jobOpening) => {
        console.log('On job opening click', jobOpening);
        navigate(`/dashboard/job-opening/${jobOpening.id}`);
    };

    const columns = [
        {
            Header: 'Job Title',
            accessor: 'title', // accessor is the "key" in the data
        },
        {
            Header: 'Job Description',
            accessor: 'description', // accessor is the "key" in the data
        },
        {
            Header: 'File URL',
            accessor: 'fileUrl', // accessor is the "key" in the data
        },
        {
            Header: 'Status',
            accessor: 'isActive',
            Cell: ({ value }) => (value ? 'Active' : 'Inactive') // Custom cell to display status based on isActive value
        }
    ];

    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="mb-0">Job Openings</h4>
                        <Button style={{ textAlignLast: "end" }} onClick={() => { navigate('/dashboard/job-opening') }}>Add Job Opening</Button>
                    </div>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable columns={columns} data={jobOpenings} onRowClick={handleRowClick} />
                </Col>
            </Row>
        </>
    );
}
