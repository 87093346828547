import React from 'react'
import { Link } from 'react-router-dom'

export default function PrivacyPolicy() {
  return (
    <>
    <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-12 col-xl-12 col-lg-12 my-auto">
                <div>
                  <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">Privacy <span className='text-red'>Policy</span></h1>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  At Human Concern USA (“HC USA”), accessible from humanconcernusa.org (the “Website”), one of our main priorities is the privacy of our visitors. In order to demonstrate our firm commitment to full transparency and best practices, we have developed the following Privacy Policy. Please read the following carefully to understand our practices regarding your personal information and how we will treat it. By engaging with the Website, you acknowledge that you have read, understood and agree to this Privacy Policy.

This Privacy Policy sets forth the policy of Human Concern USA, (“us,” “we,” or “our”) concerning the collection, handling and storage of user information collected through the use of humanconcernusa.org, which links to this Privacy Policy. Please read this Privacy Policy carefully to understand how we process and use your information. This Privacy Policy may be amended by Human Concern USA from time to time and was last updated on 10/01/2020.

This Privacy Policy applies only to our online activities and is valid for visitors to our Website with regards to the information that they shared and/or that was collected by Human Concern USA via the Website. This policy is not applicable to any information collected offline or via channels other than this Website.
                  </p>
                  <h4 className="font-700 wow fadeInUp mt-4" >CONSENT</h4>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  By using our Website, you hereby consent to our Privacy Policy and agree to its terms.
                  </p>
                  <h4 className="font-700 wow fadeInUp mt-4">INFORMATION WE COLLECT</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  At Human Concern USA, we do not require you to register or provide information to us in order to view our Website. The personal information that you may be asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
</p>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
</p>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
</p>

<h4 className="font-700 wow fadeInUp mt-4">HOW WE USE YOUR INFORMATION</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
Human Concern USA  is the sole owner of any information collected on this Website. We do not sell personal information collected from our Website. We use the information we collect in various ways, including to:
</p>

<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Provide, operate, and maintain our Website;</p>
<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Improve, personalize, and expand our Website;</p>
<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Understand and analyze how you use our Website;</p>
<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Develop new products, services, features, and functionality;</p>
<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the Website, and for marketing and promotional purposes;</p>
<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Send you emails;</p>
<p className="pera wow fadeInUp mb-0" data-wow-delay="0.3s">- Find and prevent fraud.</p>


<h4 className="font-700 wow fadeInUp mt-4">LOG FILES</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  We follow a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of the hosting services’ analytics. The information collected by log files includes IP addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any personally identifiable information. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the Website, and gathering demographic information.
</p>

<h4 className="font-700 wow fadeInUp mt-4">COOKIES AND WEB BEACONS</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  Like any other website, we use “cookies.” These cookies are used to store information including visitors’ preferences, and the pages on the Website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.
</p>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  For more general information on cookies, please read “What Are Cookies.”
</p>

<h4 className="font-700 wow fadeInUp mt-4">PERSONAL INFORMATION AND CREDIT CARD SECURITY</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  We care about the safety and security of your transaction, and we take every precaution to protect your information. Donations made on our Website are secure to the fullest extent possible. We use high-grade, 128-bit encryption and the “https” security protocol through Secure Socket Layer (SSL) technology to communicate with your browser software. SSL protects information as it is transferred through the Internet. This method is the industry standard security protocol, which makes it extremely difficult for anyone else to intercept the credit card information you send to us. Companies we work with to process credit card transactions also use high-grade encryption and security protocols.
</p>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  You can recognize secure website pages by checking for the secure symbol at the bottom of your browser screen. For most browsers, this symbol is either a lock or a key. When the lock symbol appears to be locked, the page is secure. When it appears to be unlocked, the page is insecure.
</p>

<h4 className="font-700 wow fadeInUp mt-4">ADVERTISING PARTNERS PRIVACY POLICIES</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  You may consult this list to find the Privacy Policy for each of the advertising partners of Human Concern USA.
</p>

<h4 className="font-700 wow fadeInUp mt-4">THIRD PARTY PRIVACY POLICIES</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  This Privacy Policy applies only to our Website. The site may contain links to other websites not operated or controlled by us. The policies and procedures described here do not apply to websites or other services that Human Concern USA does not operate or control. These links from our site do not imply that we endorse or have reviewed those websites or other services. We suggest contacting those services directly for information on their privacy policies.
</p>

<h4 className="font-700 wow fadeInUp mt-4">CONTACTING US</h4>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  If you have any questions about this Privacy Policy or the practices described herein, please contact us via:
</p>
<p className="pera wow fadeInUp" data-wow-delay="0.3s">
  Human Concern USA<br />
  600 E Carmel Drive Suite 147 Carmel, IN 46032<br />
  <Link className='text-red' to="tel:18005835841">1-800-583-5841</Link><br/>
  <Link className='text-red' to="mailto:info@humanconcernusa.org">info@humanconcernusa.org</Link>
</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
