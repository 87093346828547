import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "../DataTable";
import {
  _getDataFromFirebase,
  _getDataFromFirebaseReturnConst,
  getDataFromFirebase,
  updateFieldsInFirebase,
} from "../../firebase/firebaseFunctions";
import { useNavigate } from "react-router-dom";

export default function AdminAppeals() {
  const [appeals, setappeals] = useState([]);
  const navigate = useNavigate();

  const fetchFundData = async (projectId) => {
    let fund;
    _getDataFromFirebaseReturnConst(`projects/${projectId}`, (_fund) => {
      fund = _fund;
    });
    return fund;
  };

  const fetchData = async () => {
    getDataFromFirebase("appeals", async (appeals) => {
      const processedAppeals = await Promise.all(
        appeals.map(async (appeal) => {
          const fund = await fetchFundData(appeal.fund_code);
          return {
            ...appeal,
            active: appeal.active ? "Yes" : "No",
            description: `${appeal.description.slice(0, 70)}...`,
            fund_code: `${fund["project-code"]} ${fund["project-name"]}`,
            subRows: [],
          };
        })
      );

      setappeals(processedAppeals);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateActiveStatusOfAppeal = (newStatus, appeal) => {
    updateFieldsInFirebase(`appeals/${appeal.id}`, {
      ...appeal,
      active: newStatus,
    });
  };

  const handleRow = (event) => {
    console.log("on event click", event);
    // navigate(`/dashboard/event/${event.id}`)
  };

  const columns = [
    {
      Header: "Appeal Title",
      accessor: "title", // accessor is the "key" in the data
    },
    {
      Header: "Appeal Description",
      accessor: "description", // accessor is the "key" in the data
    },
    {
      Header: "Fund Code",
      accessor: "fund_code", // accessor is the "key" in the data
    },
    {
      Header: "Goal",
      accessor: "goal", // accessor is the "key" in the data
    },
    {
      Header: "End Date",
      accessor: "end_date", // accessor is the "key" in the data
    },
    // {
    //   Header: "Is Recurring",
    //   acessoor: "is_recurring",
    // },
    // {
    //   Header: "Is Quantity",
    //   accessoor: "quantity_field",
    // },
    {
      Header: "Active",
      accessor: "active",
    },
    {
      Header: "Edit",
      id: "edit",
      Cell: ({ row }) => (
        <Button
          variant="warning"
          onClick={() => {
            navigate(`/dashboard/edit-appeal/${row.original.id}`);
          }}
        >
          Edit Appeal
        </Button>
      ),
    },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) =>
        row.original.active === "Yes" ? (
          <Button
            variant="danger"
            onClick={() => {
              updateActiveStatusOfAppeal(false, row.original);
            }}
          >
            Disable Appeal
          </Button>
        ) : (
          <Button
            onClick={() => {
              updateActiveStatusOfAppeal(true, row.original);
            }}
          >
            Activate Appeal
          </Button>
        ),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">Appeals</h4>
            <Button
              style={{ textAlignLast: "end" }}
              onClick={() => {
                navigate("/dashboard/add-appeal");
              }}
            >
              Add Appeal
            </Button>
          </div>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable columns={columns} data={appeals} onRowClick={handleRow} />
        </Col>
      </Row>
    </>
  );
}
