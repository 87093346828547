// src/firebase/firebaseFunctions.js
import {
  child,
  get,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import app from "./firebaseConfig";
import { getAuth } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";

//UpdateFieldsInFirebase
export const updateFieldsInFirebase = (referencePath, data) => {
  const database = getDatabase(app);
  const dataRef = ref(database, referencePath);
  return update(dataRef, data);
};

export const updateOtpVerifiedStatus = async (userId, status) => {
  try {
    console.log("object -------------------- updateOtpVerifiedStatus");

    const database = getDatabase(app);
    // Reference to the user's data in the database
    const userRef = ref(database, `users/${userId}`);
    // Update the isVerified field to true
    await update(userRef, {
      isVerified: status,
    });

    console.log("isVerified status updated successfully");
  } catch (error) {
    console.error("Error updating isVerified status:", error);
  }
};

export const getOtpVerifiedStatus = async (userId) => {
  try {
    const db = getDatabase(app);

    // Reference to the user's data in the database
    const userRef = ref(db, `users/${userId}`);

    // Get the user's data
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const userData = snapshot.val();
      const isVerified = userData.isVerified;

      console.log("isVerified status:", isVerified);
      return isVerified;
    } else {
      console.log("No data available for this user");
      return null;
    }
  } catch (error) {
    console.error("Error fetching isVerified status:", error);
  }
};

export const deletDataFromFirebase = (referencePath, data) => {
  const database = getDatabase(app);
  const dataRef = ref(database, referencePath);
  return set(dataRef, data);
};

// Function to add data to Firebase Realtime Database
export const setDataToFirebase = (referencePath, data) => {
  const database = getDatabase(app);
  const dataRef = ref(database, referencePath);
  return set(dataRef, data);
};

export const addDataToFirebase = (referencePath, data) => {
  const database = getDatabase(app);
  const dataRef = ref(database, referencePath);
  return push(dataRef, data); // Use push instead of set
};

export const addPresetsToFirebase = async (referencePath, formData) => {
  const database = getDatabase(app);
  const dataRef = ref(database, referencePath);

  try {
    // Check if the document exists for the user
    const snapshot = await get(dataRef);

    if (snapshot.exists()) {
      const data = snapshot.val();

      if (data.presets) {
        // If presets array exists, push the new formData to it
        if (data.presets.length >= 2) {
          // If there are already two presets, remove the oldest one
          data.presets.shift();
        }
        data.presets.push(formData);
      } else {
        // If no presets array exists, create it with formData
        data.presets = [formData];
      }

      // Update only the presets field in the existing document
      await update(dataRef, { presets: data.presets });
    } else {
      // If the document doesn't exist, create it with userId and presets array
      const newDocument = {
        userId: referencePath.split("/").pop(), // Extract userId from referencePath
        presets: [formData],
      };
      await set(dataRef, newDocument);
    }

    console.log("Data saved successfully.");
    return true;
  } catch (error) {
    console.error("Error saving data:", error);
    return false;
  }
};

export const deletePresetsFromFirebase = async (referencePath, presetIndex) => {
  // Ensure that referencePath is a string
  if (typeof referencePath !== "string" || !referencePath.trim()) {
    console.error("Invalid reference path:", referencePath);
    return false;
  }

  const database = getDatabase(app);
  const dataRef = ref(database, referencePath);

  console.log(referencePath);
  try {
    // Check if the document exists for the user
    const snapshot = await get(dataRef);
    console.log("first", snapshot);
    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log("second", data);
      console.log("data presets & length", data.presets);
      if (data.presets && data.presets.length > presetIndex) {
        // Remove the preset at the specified index
        data.presets.splice(presetIndex, 1);

        // Update only the presets field in the existing document
        await update(dataRef, { presets: data.presets });

        console.log("Preset deleted successfully.");
        return true;
      } else {
        console.error("Preset not found or invalid index.");
        return false;
      }
    } else {
      console.error("Document does not exist.");
      return false;
    }
  } catch (error) {
    console.error("Error deleting preset:", error);
    return false;
  }
};

export const getSpecificUser = async (uid) => {
  const dbRef = ref(getDatabase(), `users/${uid}`);
  const snapshot = await get(dbRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    console.error("No data available for the user:", uid);
    return null;
  }
};

export const getSubscriptionFromFirebase = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);
  console.log("reference Path", referencePath);
  onValue(
    dbRef,
    (snapshot) => {
      console.log("User Data Snapshot", snapshot.val());
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Transform the data from an object to an array
        const subscriptionsArray = Object.keys(data).map((key) => {
          const createdAt = data[key].createdAt
            ? new Date(data[key].createdAt)
            : null;
          const date = createdAt ? createdAt.toLocaleDateString() : ""; // Extract date
          const time = createdAt
            ? createdAt.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : ""; // Extract time
          const timestamp = createdAt ? createdAt.getTime() : 0; // Convert to timestamp for sorting

          return {
            id: key, // Add the key as an 'id' field if needed
            ...data[key],
            date, // Extracted date
            time, // Extracted time
            timestamp, // Add timestamp for sorting
          };
        });

        // Sort subscriptionsArray by timestamp in descending order
        const sortedArray = subscriptionsArray.sort(
          (a, b) => b.timestamp - a.timestamp
        );

        callback(sortedArray);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const getAppealDetails = (appealId, callback) => {
  const dbRef = ref(getDatabase(), `appeals/${appealId}`);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Return the data directly to the callback
        callback(data);
      } else {
        callback(null); // or an empty object or array if no data exists
      }
    },
    (error) => {
      console.error("Error fetching appeal details:", error);
      callback(null); // or an empty object or array if an error occurs
    }
  );
};

// Function to get data from Firebase Realtime Database
export const getPaymentFromFirebase = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Transform the data from an object to an array
        const projectsArray = Object.keys(data).map((key) => {
          const createdAt = data[key].createdAt
            ? new Date(data[key].createdAt)
            : null;
          const date = createdAt ? createdAt.toLocaleDateString() : ""; // Extract date
          const time = createdAt
            ? createdAt.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            : "";
          const timestamp = createdAt ? createdAt.getTime() : 0; // Convert to timestamp for sorting

          return {
            id: key, // Add the key as an 'id' field if needed
            ...data[key],
            userId: key,
            date, // Extracted date
            time, // Extracted time
            timestamp, // Add timestamp for sorting
          };
        });

        // Sort projectsArray by timestamp in descending order
        const sortedArray = projectsArray.sort(
          (a, b) => b.timestamp - a.timestamp
        );

        callback(sortedArray);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const checkIfUserExists = async (email) => {
  const database = getDatabase(app);

  try {
    // Traverse all users and find a matching email
    const usersRef = ref(database, "users");
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const users = snapshot.val();

      // Iterate over users and check if the email matches
      for (const userId in users) {
        if (users[userId].email === email) {
          return true; // Email exists
        }
      }
    }

    return false; // Email does not exist
  } catch (error) {
    console.error("Error checking user existence by email:", error);
    throw new Error("Error checking user existence by email");
  }
};

export const getDataFromFirebaseReturnConst = (referencePath) => {
  const dbRef = ref(getDatabase(), referencePath);

  return new Promise((resolve, reject) => {
    onValue(
      dbRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("projectsArray", data);
          resolve(data); // Resolve the Promise with the data
        } else {
          resolve(null); // Resolve with null if no data exists
        }
      },
      (error) => {
        console.error("Error fetching data:", error);
        reject(error); // Reject the Promise if there's an error
      }
    );
  });
};
export const getDataFromFirebase = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Transform the data from an object to an array
        const projectsArray = Object.keys(data).map((key) => ({
          id: key, // Add the key as an 'id' field if needed
          ...data[key],
          userId: key,
        }));
        callback(projectsArray);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const getRedirectsFromFirebase = (callback) => {
  const db = getDatabase();
  const referencePath = "redirects"; // Reference path to 'redirects' node in Firebase
  const refInstance = ref(db, referencePath);
  
  onValue(refInstance, (snapshot) => {
    const data = snapshot.val();
    if (data) {
      callback(data);
    } else {
      callback([]);
    }
  });
};

export const getDonationPaymentsFromFirebase = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Transform the data from an object to an array
        const projectsArray = Object.keys(data).map((key) => ({
          id: key, // Add the key as an 'id' field if needed
          ...data[key],
        }));
        callback(projectsArray);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const getCheckoutPresetsFromFirebase = (userId, callback) => {
  const dbRef = ref(getDatabase(), `checkoutDetailsPresets/${userId}`);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();

        // Check if presets array exists and return it, or return an empty array
        const presetsArray = data.presets ? data.presets : [];

        callback(presetsArray);
      } else {
        // If no document is found, return an empty array
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
      callback([]); // Return an empty array on error as well
    }
  );
};

// Function to get campaign from Firebase Realtime Database
export const getNodeFromFirebase = (referencePath, callback) => {
  const database = getDatabase(app);
  const dbRef = ref(database, referencePath);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        data.id = snapshot.key;
        callback(data);
      } else {
        callback(null);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

//update status
export const updateSubscriptionStatus = async (subscriptionId, newStatus) => {
  const db = getDatabase();
  try {
    // Assuming you know the path to the subscription data based on subscriptionId
    const subscriptionRef = ref(db, `subscription/${subscriptionId}`);

    // Update the status field
    await update(subscriptionRef, {
      Status: newStatus,
    });

    console.log(
      `Status updated successfully for subscription ID: ${subscriptionId}`
    );
  } catch (error) {
    console.error(
      `Failed to update status for subscription ID: ${subscriptionId}`,
      error
    );
  }
};

//getUserData
export async function getCurrentUserDetails() {
  try {
    const auth = getAuth();
    const database = getDatabase(app);
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const usersRef = ref(database, `users/${uid}`);
          const snapshot = await get(usersRef);
          if (snapshot.exists()) {
            const userDetails = snapshot.val();

            // Include userId in the userDetails
            const userDetailsWithId = {
              userId: uid,
              ...userDetails,
            };

            resolve(userDetailsWithId);
          } else {
            console.log("No data found for the current user");
            resolve(null);
          }
        } else {
          console.log("No user signed in");
          resolve(null);
        }
      });
    });
  } catch (error) {
    console.error("Error retrieving current user details:", error);
    throw error;
  }
}

//get data from slug
export const fetchDataBySlug = async (slug) => {
  try {
    const database = getDatabase(app);

    // 1. Get slug data (type and id) from 'slugs' node
    const slugRef = ref(database, `slugs/${slug}`);
    const slugSnapshot = await get(slugRef);

    if (!slugSnapshot.exists()) {
      throw new Error(`Slug '${slug}' not found`);
    }

    const slugData = slugSnapshot.val();
    const { type, id } = slugData; // Destructure type and id

    // 2. Validate type and id existence
    if (!type || !id) {
      throw new Error("Missing 'type' or 'id' property in slug data");
    }

    // 3. Construct data reference based on type and id
    const dataRef = ref(database, `${type}/${id}`);

    // 4. Fetch data and get the ID from the snapshot
    const dataSnapshot = await get(dataRef);

    if (dataSnapshot.exists()) {
      const data = dataSnapshot.val();
      // Combine ID from snapshot and data for return
      return { id: dataSnapshot.key, ...data };
    } else {
      return null; // Indicate data not found
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error for further handling
  }
};

// Function to add user data to Firebase Realtime Database
export const addUserDataToFirebase = (userData) => {
  const database = getDatabase(app);
  const userRef = ref(database, `users`);
  return push(userRef, { ...userData });
};

export const generateFormFields = (fields) => {
  return fields
    .map((field) => {
      const { label, type, min, max, id, classes } = field;
      let inputField;

      switch (type) {
        case "text":
        case "email":
        case "password":
        case "textarea":
          inputField = `<input type="${type}" id="${id}" ${
            min ? `min="${min}"` : ""
          } ${max ? `max="${max}"` : ""} class="${classes}" />`;
          break;
        default:
          inputField = `<input type="text" id="${id}" class="${classes}" />`; // Default to text input if type is not recognized
      }

      return `
      <div>
        <label for="${id}">${label}</label>
        ${inputField}
      </div>
    `;
    })
    .join("");
};

export const getPayemtFromFirebase = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);
  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Transform the data from an object to an array
        const projectsArray = Object.keys(data).map((key) => ({
          id: key, // Add the key as an 'id' field if needed
          ...data[key],
        }));

        callback(projectsArray);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const addUserDataToFirebasewithUid = (userData) => {
  const database = getDatabase(app);
  const userRef = ref(database, `users/${userData.uid}`); // Use uid as the documentId
  return set(userRef, { ...userData });
};

export const updateAppealRaisedAmount = async (appealId, amountToAdd) => {
  const db = getDatabase();
  const appealRef = ref(db, `appeals/${appealId}`);

  try {
    // Fetch the current data for the appeal
    const snapshot = await get(appealRef);
    const currentData = snapshot.val();

    if (currentData) {
      // Calculate the new raised amount
      const newRaisedAmount =
        (currentData.raisedAmount || 0) + parseInt(amountToAdd);
      console.log(newRaisedAmount);

      // Update the appeal with the new raised amount
      await update(appealRef, {
        raisedAmount: newRaisedAmount,
      });

      console.log(
        `Successfully updated raised amount for Appeal ID: ${appealId}`
      );
    } else {
      console.error(`Appeal ID: ${appealId} does not exist`);
    }
  } catch (error) {
    console.error(
      `Failed to update raised amount for Appeal ID: ${appealId}`,
      error
    );
  }
};

export const _getDataFromFirebase = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Transform the data from an object to an array
        const projectsArray = Object.keys(data).map((key) => ({
          id: key, // Add the key as an 'id' field if needed
          ...data[key],
        }));
        callback(projectsArray);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const _getDataFromFirebaseReturnConst = (referencePath, callback) => {
  const dbRef = ref(getDatabase(), referencePath);

  onValue(
    dbRef,
    (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        callback(data);
      } else {
        callback([]);
      }
    },
    (error) => {
      console.error("Error fetching data:", error);
    }
  );
};

export const renameSlugKeyById = async (id, newKey) => {
  const db = getDatabase();
  const slugsRef = ref(db, "slugs"); // Reference to the slugs node

  try {
    // Fetch all slugs
    const snapshot = await get(slugsRef);

    console.log("snapshots", snapshot)
    if (snapshot.exists()) {
      // Find the key where the id matches
      let oldKey = null;
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val();
        if (childData.id === id) {
          oldKey = childSnapshot.key;
        }
      });

      if (oldKey) {
        const oldRef = ref(db, `slugs/${oldKey}`);
        const newRef = ref(db, `slugs/${newKey}`);

        // Read the data from the old key
        const dataSnapshot = await get(oldRef);

        if (dataSnapshot.exists()) {
          // Write the data to the new key
          const data = dataSnapshot.val();
          await set(newRef, data);

          // Delete the old key
          await remove(oldRef);

          console.log("Slug key renamed successfully");
        } else {
          console.log("No data found for the old key");
        }
      } else {
        console.log("No matching slug found for the provided id");
      }
    } else {
      console.log("No slugs data available");
    }
  } catch (error) {
    console.error("Error renaming slug key:", error);
  }
};
