import React from 'react'
import { Col, Row } from 'react-bootstrap';
import FormGenerator from '../FormGenerator';

export default function AddProject() {

    const fields = [
        { label: 'Project Code', type: 'text', id: 'project-code', classes: '', width: '6', ph: "Enter Project Code" },
        { label: 'Project Name', type: 'text', id: 'project-name', classes: '', width: '6', ph: "Enter Project Code" },
    ];

    const referencePath = "projects";

    return (
        <>
        <Row>
            <Col>
                <h4 className="mb-3 mb-md-0">Add Campaign</h4>
                <hr/>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGenerator fields={fields} referencePath={referencePath} buttonLabel="Add Project" name="Project"/>
            </Col>
        </Row>
        </>
    )
}
