import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Row, Table, Spinner, Alert, Button } from "react-bootstrap";
import { getDataFromFirebase } from "../../firebase/firebaseFunctions";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import DataTable from "../DataTable";
import DatePicker from "react-datepicker";
import {useAuth} from "../Auth/AuthContext";


export default function Dashboard() {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [donationsToday, setDonationsToday] = useState(0);
  const [donationsThisWeek, setDonationsThisWeek] = useState(0);
  const [donationsThisMonth, setDonationsThisMonth] = useState(0);
  const [totalDonors, setTotalDonors] = useState(0);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [projects, setProjects] = useState([]);
  const [appeals, setAppeals] = useState([]);
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const projectsUpdated = useRef(false);
  const [shouldCalculate, setShouldCalculate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch donations
        getDataFromFirebase("donation_payments", (data) => {
          processDonationData(data);
          setDonations(data);
        });

        // Fetch projects
        getDataFromFirebase("projects", (data) => {
          setProjects(data);
          setTotalProjects(data.length);
        });

        // Fetch campaigns
        getDataFromFirebase("campaigns", (data) =>
          setTotalCampaigns(data.length)
        );

        // Fetch appeals
        getDataFromFirebase("appeals", (data) => setAppeals(data));
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (donations.length > 0 && projects.length > 0) {
      setShouldCalculate(true);
    }
  }, [donations.length, projects.length]);

  useEffect(() => {
    if (shouldCalculate) {
      calculateDonationAmounts();
      setShouldCalculate(false); // reset the flag after calculation
    }
  }, [shouldCalculate]);
const auth = useAuth();

if(auth.currentUser?.role !== "admin"){
navigate("donations");
}
  const isInRange = (date) => {
    if (!startDate || !endDate) return true;
    date = new Date(date);
    return date >= startDate && date <= endDate;
  };

  const processDonationData = (data) => {
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    let donationsToday = 0;
    let donationsThisWeek = 0;
    let donationsThisMonth = 0;
    let donors = new Set();

    data.forEach((donation) => {
      const donationDate = new Date(donation.timestamp);
      const amount = parseFloat(donation.amount);

      if (donationDate >= startOfToday) {
        donationsToday += amount;
      }
      if (donationDate >= startOfWeek) {
        donationsThisWeek += amount;
      }
      if (donationDate >= startOfMonth) {
        donationsThisMonth += amount;
      }
      donors.add(donation.userId);
    });

    setDonationsToday(donationsToday);
    setDonationsThisWeek(donationsThisWeek);
    setDonationsThisMonth(donationsThisMonth);
    setTotalDonors(donors.size);
  };

  const calculateDonationAmounts = () => {
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const startOfQuarter = new Date(
      today.getFullYear(),
      Math.floor(today.getMonth() / 3) * 3,
      1
    );
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(today.getFullYear(), 0, 0);

    const updatedProjects = projects.map((project) => {
      const campaignCount = appeals.filter(
        (appeal) => appeal.fund_code === project.id
      ).length;

      let thisWeek = 0;
      let thisMonth = 0;
      let lastMonth = 0;
      let thisQuarter = 0;
      let thisYear = 0;
      let lastYear = 0;
      let totalCollection = 0;
      let donationDate;
      donations.forEach((donation) => {
        donationDate = new Date(donation.timestamp).toDateString();
        const amount = parseFloat(donation.amount);
        if (!isInRange(donation.timestamp)) return;

        if (donation.campaignData?.fund_code === project.id) {
          if (donationDate >= startOfWeek) {
            thisWeek += amount;
          }
          if (donationDate >= startOfMonth) {
            thisMonth += amount;
          }
          if (
            donationDate >= startOfLastMonth &&
            donationDate <= endOfLastMonth
          ) {
            lastMonth += amount;
          }
          if (donationDate >= startOfQuarter) {
            thisQuarter += amount;
          }
          if (donationDate >= startOfYear) {
            thisYear += amount;
          }
          if (
            donationDate >= startOfLastYear &&
            donationDate <= endOfLastYear
          ) {
            lastYear += amount;
          }
          totalCollection += amount;
        }
      });

      return {
        ...project,
        thisWeek,
        thisMonth,
        lastMonth,
        thisQuarter,
        thisYear,
        lastYear,
        totalCollection,
        campaignCount,
      };
    });
    setData(updatedProjects);
    setProjects(updatedProjects);
  };

  const exportToExcel = () => {
    const formatDate = (date) => {
      return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "2-digit",
      });
    };
    const formattedTime = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const data = [
      ["Generation Data", formatDate(new Date())],
      ["Generation Time", formattedTime],
    ];

    if (startDate && endDate) {
      data.push([
        "Data of",
        `${formatDate(startDate)} - ${formatDate(endDate)}`,
      ]);
    }

    data.push(
      [], // Empty row for spacing
      [
        "Project Code",
        "Project Name",
        "This Week",
        "This Month",
        "Last Month",
        "This Quarter",
        "This Year",
        "Last Year",
        "Total Collection",
        "Sub Campaigns Count",
      ]
    );

    // Add project data rows
    projects.forEach((project) => {
      data.push([
        project["project-code"],
        project["project-name"],
        project["thisWeek"],
        project["thisMonth"],
        project["lastMonth"],
        project["thisQuarter"],
        project["thisYear"],
        project["lastYear"],
        project["totalCollection"],
        project["campaignCount"],
      ]);
    });

    // Convert data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);
    const summaryData = [
      ["Donations Today", `$${donationsToday.toFixed(2)}`],
      ["Donations This Week", `$${donationsThisWeek}`],
      ["Donations This Month", `$${donationsThisMonth}`],
      ["Total Donors", totalDonors],
      ["Total Campaigns", totalCampaigns],
      ["Total Projects", totalProjects],
    ];
    const sm = XLSX.utils.aoa_to_sheet(summaryData);

    // Create workbook and append the sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects");
    XLSX.utils.book_append_sheet(wb, sm, "Summary");

    // Write workbook to file
    let fileName = `Dashboard`;
    if (startDate && endDate) {
      fileName += ` - ${formatDate(startDate)} to ${formatDate(endDate)}`;
    }
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleRow = (project) => {
    navigate(`/dashboard/project/${project.id}`);
  };

  const columns = [
    {
      Header: "Project Name",
      accessor: "project-name", // accessor is the "key" in the data
    },
    {
      Header: "Campaign Count",
      accessor: "campaignCount", // accessor is the "key" in the data
    },
    {
      Header: "Total Collection",
      accessor: "totalCollection", // accessor is the "key" in the data
    },
    {
      Header: "This week",
      accessor: "thisWeek", // accessor is the "key" in the data
    },
    {
      Header: "This Month",
      accessor: "thisMonth", // accessor is the "key" in the data
    },
    {
      Header: "Last Month",
      accessor: "lastMonth", // accessor is the "key" in the data
    },
    {
      Header: "This Quarter",
      accessor: "thisQuarter", // accessor is the "key" in the data
    },
    {
      Header: "This Year",
      accessor: "thisYear", // accessor is the "key" in the data
    },
    {
      Header: "Last Year",
      accessor: "lastYear", // accessor is the "key" in the data
    },
    // {
    //   Header: "Total Goals",
    //   accessor: "totalGoalAchieved", // accessor is the "key" in the data
    // },
  ];

  const formatDate = (date) => {
    const dateObject = new Date(date);

    // Extract the month, day, and year
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    // Format the date as "MM/DD/YYYY"
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  useEffect(() => {
    if (startDate && endDate) {
      calculateDonationAmounts();
    }
  }, [startDate, endDate]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3 mb-md-0">Dashboard</h4>
          <hr />
        </Col>
      </Row>
      <div style={{ textAlignLast: "end", marginBottom: "15px" }}>
        <Button variant="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>

      <Row>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations Today</h6>
              <h2 className="text-red fw-bold">${donationsToday.toFixed(2)}</h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations This Week</h6>
              <h2 className="text-red fw-bold">
                ${donationsThisWeek.toFixed(2)}
              </h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations This Month</h6>
              <h2 className="text-red fw-bold">
                ${donationsThisMonth.toFixed(2)}
              </h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Donors</h6>
              <h2 className="text-red fw-bold">{totalDonors}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Campaigns</h6>
              <h2 className="text-red fw-bold">{totalCampaigns}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Projects</h6>
              <h2 className="text-red fw-bold">{totalProjects}</h2>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex">
            <h4 className="mb-3 mb-md-0">Projects Overview</h4>
            <div className="d-flex" style={{ marginLeft: "auto" }}>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="form-control"
                  placeholderText="Start Date"
                />
              </div>

              <div style={{ marginLeft: "15px" }}>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="form-control"
                  placeholderText="End Date"
                />
              </div>
            </div>
          </div>

          <hr />
          <DataTable
            columns={columns}
            data={projects}
            onRowClick={handleRow}
          ></DataTable>
        </Col>
      </Row>
    </>
  );
}
