import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./Components/Auth/Signup";
import EmailVerified from "./Components/FrontEnd/EmailVerified";
import Login from "./Components/Auth/Login";
import Dashboard from "./Components/BackEnd/Dashboard";
import SiteLayout from "./Components/Layouts/SiteLayout";
import Home from "./Components/FrontEnd/Home";
import DashboardLayout from "./Components/Layouts/DashboardLayout";
import Signout from "./Components/Auth/Signout";
import Profile from "./Components/BackEnd/Profile";
import Campaigns from "./Components/FrontEnd/Campaigns";
import AddCampaign from "./Components/FrontEnd/AddCampaign";
import Appeals from "./Components/FrontEnd/Appeals";
import AddAppeal from "./Components/BackEnd/AddAppeal";
import AddProject from "./Components/BackEnd/AddProject";
import HtmlEditor from "./Components/BackEnd/HtmlEditor";
import EmailTemplates from "./Components/BackEnd/EmailTemplates";
import UsersTemplate from "./Components/BackEnd/UsersTemplate";
import Projects from "./Components/BackEnd/Projects";
import Donations from "./Components/BackEnd/Donations";
import HomeNew from "./Components/FrontEnd/HomeNew";
import About from "./Components/FrontEnd/About";
import CampaignsFront from "./Components/FrontEnd/Appeals";
import Blogs from "./Components/FrontEnd/Blogs";
import AppealSingle from "./Components/FrontEnd/CampaignSingle";
import DonationForm from "./Components/FrontEnd/DonationForm";
import DonationFormParent from "./Components/FrontEnd/DonationFormParent";
import AuthProvider, { useAuth } from "../src/Components/Auth/AuthContext";
import Contact from "./Components/FrontEnd/Contact";
import ThankYou from "./Components/FrontEnd/ThankYou";
import DonationDetails from "./Components/BackEnd/DonationDetails";
import CustomDonationFormParent from "./Components/FrontEnd/Custom_Donation/CustomDonationFormParent";
import Cart from "./Components/FrontEnd/Cart";
import { FormDataProvider } from "./Components/FrontEnd/FormDataContext";
import AddRole from "./Components/BackEnd/Roles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CampaignTable from "./Components/BackEnd/CampaignTable";
import DonorTable from "./Components/BackEnd/DonorTable";
import AppealTable from "./Components/BackEnd/AppealTable";
import DonorDetailsPage from "./Components/BackEnd/DonorDetailsPage";
import Tickets from './Components/BackEnd/Tickets';
import AddTicket from './Components/BackEnd/AddTicket';
import TicketDiscussion from './Components/BackEnd/TicketDiscussion';
import SendOtp from './Components/Auth/SendOtp';
import AddGroup from './Components/BackEnd/AddGroup';
import Groups from './Components/BackEnd/Groups';
import UpdateGroup from './Components/BackEnd/UpdateGroup';
import AddUpdateBlog from './Components/FrontEnd/AddUpdateBlog';
import Events from './Components/FrontEnd/Events';
import AddUpdateEvent from './Components/FrontEnd/AddUpdateEvent';
import AddUpdateReport from './Components/FrontEnd/AddUpdateReport';
import Reports from './Components/FrontEnd/Reports';
import Blog from './Components/FrontEnd/Blog';
import SendEmail from './Components/BackEnd/SendEmail';
import AdminBlogs from './Components/BackEnd/AdminBlogs';
import AdminEvents from './Components/BackEnd/AdminEvents';
import AdminReports from './Components/BackEnd/AdminReports';
import Adminappeals from './Components/BackEnd/AdminAppeals';
import Subscription from './Components/FrontEnd/Subscription';
import Payment from './Components/FrontEnd/Payment';
import SubscriptionDetail from './Components/FrontEnd/SubscriptionDetail';
import CapturePayPal from './Components/FrontEnd/CapturePayPal';
import NotFound from './Components/FrontEnd/NotFound';
import Volunteers from './Components/FrontEnd/Volunteers';
import Careers from './Components/FrontEnd/Careers';
import AddUpdateJobOpening from './Components/BackEnd/AddUpdateJobOpening';
import AdminJobOpenings from './Components/BackEnd/AdminJobOpenings';
import EditAppeal from './Components/BackEnd/EditAppeal';
import { toast } from "react-toastify";
import EmailVerifiation from "./Components/FrontEnd/EmailVerifiation";
import TravelWithUs from "./Components/FrontEnd/TravelWithUs";
import Loading from "./Components/Layouts/Loading";
import LoadingPage from "./Components/Layouts/LoadingPage";
import OurTeam from "./Components/FrontEnd/OurTeam";
import ZakatCalculator from "./Components/FrontEnd/ZakatCalculator";
import PrivacyPolicy from "./Components/FrontEnd/PrivacyPolicy";
import { getRedirectsFromFirebase } from "./firebase/firebaseFunctions";

const stripePromise = loadStripe('pk_test_51PJQcsHXm3AIrcKTHKRprNqZTtHGfNiyOyKzVV6R28SjytoZpxwgsxuzR2JGI2nr6yqBa3qAmbCUAadXPwClfO5o00pVf9hd7P');


// Function to render the protected route or navigate to login
const Redirect = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [to]);

  return null; // Prevent rendering anything
};

const ProtectedRoute = ({ element, ...rest }) => {
  const auth = useAuth();
  const isverify = localStorage.getItem("isVerified");

  if (isverify=="false") {
    toast.warning("Account is not verified")
    return <Redirect to="/login" />;
  }
  if (!auth) {
    return <Redirect to="/login" />;
  }

  return element;
};


function PrivateRoute({ children }) {
  const auth = useAuth();
  const isverify = localStorage.getItem("isVerified");

  if (isverify=="false") {
    toast.warning("Account is not verified")
    return <Redirect to="/login" />;
  }
  if (!auth) {
    return <Redirect to="/login" />;
  }

  if (auth.currentUser.role !== "admin") {
    return <Navigate to="/" />;
  }

  return children;
}

  function RedirectComponent({ redirects }) {
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}`;

    // Check if redirects are loaded and not undefined
    if (!redirects || redirects.length === 0) {
      return <LoadingPage />;
    }

    // Check if currentUrl matches any source URL in redirects
    const matchingRedirect = redirects.find((redirect) => redirect.source === currentUrl);

    if (matchingRedirect) {
      window.location.href = matchingRedirect.target; // Redirect to the target URL
      return <LoadingPage />;
    }

    // Render the NotFound page if no matching redirect is found
    return <NotFound />;
  }


function App() {

  const [redirects, setRedirects] = useState([]);

  useEffect(() => {
    // Fetch redirects from Firebase
    getRedirectsFromFirebase((redirectsArray) => {
      setRedirects(redirectsArray); // Update state with fetched redirects
    });
  }, []); // Empty dependency array to run only on initial render

  // Separate useEffect to observe state changes
  useEffect(() => {
    console.log("Updated redirects:", redirects);

    // Check for redirects every time they are updated
    const location = window.location.pathname;
    const currentUrl = `${window.location.origin}${location}`;
    const matchingRedirect = redirects.find((redirect) => redirect.source === currentUrl);
  
    if (matchingRedirect) {
      window.location.href = matchingRedirect.target; // Redirect to the target URL
    }
  }, [redirects]); // This will run whenever 'redirects' is updated


  return (
    <>
      <FormDataProvider>
        <AuthProvider>
          <ToastContainer />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<SiteLayout />}>
                <Route index element={<HomeNew />} />
                <Route path="about" element={<About />} />
                <Route path="appeals" element={<Appeals />} />
                <Route path="appeal/:slug" element={<AppealSingle />} />
                <Route
                  path="donation-form/:slug"
                  element={<DonationFormParent />}
                />
                <Route path="send-otp" element={<SendOtp />} />
                <Route path="loading" element={<LoadingPage />} />
                <Route path="our-team" element={<OurTeam />} />
                <Route path="email-verify" element={<EmailVerifiation />} />
                <Route path="email-verified" element={<EmailVerified />} />
                <Route path="events" element={<Events />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/event" element={<AddUpdateEvent />} />
                <Route path="/event/:id" element={<AddUpdateEvent />} />
                <Route path="reports" element={<Reports />} />
                <Route path="/report" element={<AddUpdateReport />} />
                <Route path="/report/:id" element={<AddUpdateReport />} />
                <Route path="blogs" element={<Blogs />} />
                <Route path="zakat-calculator" element={<ZakatCalculator />} />
                <Route path="/blog" element={<AddUpdateBlog />} />
                <Route path="/blog/single/:id" element={<Blog />} />
                <Route path="signup" element={<Signup />} />
                <Route path="careers" element={<Careers />} />
                <Route path="travel-with-us" element={<TravelWithUs />} />
                <Route path="login" element={<Login />} />
                <Route path="volunteer" element={<Volunteers />} />
                <Route path="signout" element={<Signout />} />
                <Route path="contact" element={<Contact />} />
                <Route path="thank-you" element={<ThankYou />} />
                <Route
                  path="custom-donation"
                  element={<CustomDonationFormParent />}
                />
                <Route
                  path="cart"
                  element={
                    <Elements stripe={stripePromise}>
                      <Cart />
                    </Elements>
                  }
                />
                <Route path="capture" element={<CapturePayPal />} />
                {/* <Route path="*" element={<NotFound />} /> */}
                <Route path="*" element={<RedirectComponent redirects={redirects} />} />
                <Route path="404" element={<NotFound />} />
              </Route>
              {/* Protected route for dashboard */}
              <Route
                path="/dashboard/*"
                element={<ProtectedRoute element={<DashboardLayout />} />}
              >
                <Route index element={<Dashboard />} />
                <Route path='profile' element={<Profile />} />
                <Route path='subscription' element={<Subscription />} />
                <Route path='subscription/detail/:subId' element={<SubscriptionDetail />} />
                <Route path='payment' element={<PrivateRoute><Payment /></PrivateRoute>} />
                <Route path='tickets' element={<PrivateRoute><Tickets /></PrivateRoute>} />
                <Route path='blogs' element={<PrivateRoute><AdminBlogs /></PrivateRoute>} />
                <Route path="blog" element={<PrivateRoute><AddUpdateBlog /></PrivateRoute>} />
                <Route path="blog/:id" element={<PrivateRoute><AddUpdateBlog /></PrivateRoute>} />
                <Route path='reports' element={<PrivateRoute><AdminReports /></PrivateRoute>} />
                <Route path="report" element={<PrivateRoute><AddUpdateReport /></PrivateRoute>} />
                <Route path="report/:id" element={<PrivateRoute><AddUpdateReport /></PrivateRoute>} />
                <Route path='appeals' element={<PrivateRoute><Adminappeals /></PrivateRoute>} />
                <Route path='events' element={<PrivateRoute><AdminEvents /></PrivateRoute>} />
                <Route path="event/:id" element={<PrivateRoute><AddUpdateEvent /></PrivateRoute>} />
                <Route path="event" element={<PrivateRoute><AddUpdateEvent /></PrivateRoute>} />
                <Route path='job-openings' element={<PrivateRoute><AdminJobOpenings /></PrivateRoute>} />
                <Route path='job-opening/:id' element={<PrivateRoute><AddUpdateJobOpening /></PrivateRoute>} />
                <Route path='job-opening' element={<PrivateRoute><AddUpdateJobOpening /></PrivateRoute>} />
                <Route path='tickets/:id' element={<PrivateRoute><TicketDiscussion /></PrivateRoute>} />
                <Route path='ticket/:id' element={<TicketDiscussion />} />
                <Route path='add-ticket' element={<AddTicket />} />
                <Route path="send-email" element={<PrivateRoute><SendEmail /></PrivateRoute>} />
                <Route path='add-appeal' element={<PrivateRoute><AddAppeal /></PrivateRoute>} />
                <Route path='edit-appeal/:id' element={<PrivateRoute><EditAppeal /></PrivateRoute>} />
                <Route path='add-group' element={<PrivateRoute><AddGroup /></PrivateRoute>} />
                <Route path='groups' element={<PrivateRoute><Groups /></PrivateRoute>} />
                <Route path='group/:id' element={<PrivateRoute><UpdateGroup /></PrivateRoute>} />
                <Route path='add-project' element={<PrivateRoute><AddProject /></PrivateRoute>} />
                <Route path="html-editor" element={<PrivateRoute><HtmlEditor /></PrivateRoute>} />
                <Route path="email-templates" element={<EmailTemplates />} />                
                <Route path="users-signup" element={<UsersTemplate />} />
                <Route path="projects" element={<Projects />} />
                <Route path="add-campaign" element={<PrivateRoute><AddCampaign /></PrivateRoute>}/>
                <Route path="campaigns" element={<Campaigns />} />
                <Route path="donations" element={<Donations />} />
                <Route path="donation-details/:id" element={<DonationDetails />}/>
                <Route path="roles" element={<PrivateRoute>      <AddRole /></PrivateRoute>  }/>
                <Route path="project/:projectId" element={<CampaignTable />} />
                <Route path="campaigns/:campaignId" element={<AppealTable />} />
                <Route path="appeals/:appealId" element={<DonorTable />} />
                <Route path="donor-details/:id/:donorEmail" element={<DonorDetailsPage />}/>
                <Route path="donor-details" element={<DonorDetailsPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </FormDataProvider>
    </>
  );
}

export default App;
