import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DataTable from '../DataTable';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { useNavigate } from 'react-router-dom';

export default function AdminReports() {
    const [reports, setReports] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('reports', (reports) => {
            console.log('reports:', reports);
            setReports(reports);
        });
    }, []);

    const handleRow =(report) => {
        console.log('on report click', report);
        navigate(`/dashboard/report/${report.id}`)
    }


    const columns = [
        {
            Header: 'Report title',
            accessor: 'title', // accessor is the "key" in the data
        },
        {
            Header: 'Report Description',
            accessor: 'description', // accessor is the "key" in the data
        },
        {
            Header: 'Report Category',
            accessor: 'category', // accessor is the "key" in the data
        },
    ];

    return (
    <>
    <Row>
        <Col>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className="mb-0">Reports</h4>
                <Button style={{textAlignLast:"end"}} onClick={()=>{navigate('/dashboard/report')}}>Add Report</Button>
            </div>
            <hr/>
        </Col>
    </Row>
    <Row>
        <Col>
            <DataTable columns={columns} data={reports} onRowClick={handleRow} />
        </Col>
    </Row>
    </>
    )
}
