import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { getDataFromFirebase } from "../../firebase/firebaseFunctions";
import * as XLSX from "xlsx";
import DataTable from "../DataTable";
import DatePicker from "react-datepicker";

export default function AppealTable() {
  const { campaignId } = useParams();
  const [appeals, setAppeals] = useState([]);
  const [donations, setDonations] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();

  const isInRange = (date) => {
    if (!startDate || !endDate) return true;
    date = new Date(date);
    return date >= startDate && date <= endDate;
  };

  const fetchDonationsData = () => {
    const donationsReferencePath = "donation_payments";

    // Fetch donations data
    getDataFromFirebase(donationsReferencePath, (donationsData) => {
      setDonations(donationsData.filter((data) => isInRange(data.timestamp)));
    });
  };

  const fetchAppeals = async () => {
    const appealsReferencePath = "appeals";

    // Fetch appeals data
    getDataFromFirebase(appealsReferencePath, (appealsData) => {
      const campaignAppeals = appealsData.filter(
        (appeal) => appeal.campaign_code === campaignId
      );
      setAppeals(campaignAppeals);
    });
    fetchDonationsData();
  };

  useEffect(() => {
    fetchAppeals();
  }, [campaignId]);

  useEffect(() => {
    fetchDonationsData();
  }, [startDate, endDate]);

  const calculateData = () => {
    if (appeals.length > 0) {
      const today = new Date();
      const startOfWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay()
      );
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const startOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      const startOfQuarter = new Date(
        today.getFullYear(),
        Math.floor(today.getMonth() / 3) * 3,
        1
      );
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
      const endOfLastYear = new Date(today.getFullYear(), 0, 0);

      const updatedAppeals = appeals.map((appeal) => {
        let thisWeek = 0;
        let thisMonth = 0;
        let lastMonth = 0;
        let thisQuarter = 0;
        let thisYear = 0;
        let lastYear = 0;
        let totalGoalAchieved = 0;

        donations.forEach((donation) => {
          const donationDate = new Date(donation.timestamp);
          const amount = parseFloat(donation.amount);

          if (donation.campaignData.id === appeal.id) {
            if (donationDate >= startOfWeek) thisWeek += amount;
            if (donationDate >= startOfMonth) thisMonth += amount;
            if (
              donationDate >= startOfLastMonth &&
              donationDate <= endOfLastMonth
            )
              lastMonth += amount;
            if (donationDate >= startOfQuarter) thisQuarter += amount;
            if (donationDate >= startOfYear) thisYear += amount;
            if (
              donationDate >= startOfLastYear &&
              donationDate <= endOfLastYear
            )
              lastYear += amount;
            totalGoalAchieved += amount;
          }
        });

        return {
          ...appeal,
          thisWeek,
          thisMonth,
          lastMonth,
          thisQuarter,
          thisYear,
          lastYear,
          totalGoalAchieved,
        };
      });

      setAppeals(updatedAppeals);
    }
  };

  useEffect(() => {
    calculateData();
  }, [donations]);
  ///**** cards

  const [donationsToday, setDonationsToday] = useState(0);
  const [donationsThisWeek, setDonationsThisWeek] = useState(0);
  const [donationsThisMonth, setDonationsThisMonth] = useState(0);
  const [totalDonors, setTotalDonors] = useState(0);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [projects, setProjects] = useState([]);
  // const [appeals, setAppeals] = useState([]);
  // const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const projectsUpdated = useRef(false);
  const [shouldCalculate, setShouldCalculate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch donations
        getDataFromFirebase("donation_payments", (data) => {
          processDonationData(data);
          setDonations(data);
        });

        // Fetch projects
        getDataFromFirebase("projects", (data) => {
          setProjects(data);
          setTotalProjects(data.length);
        });

        // Fetch campaigns
        getDataFromFirebase("campaigns", (data) => {
          setTotalCampaigns(data.length);
          const campaign = data.find((p) => p.id === campaignId);
          if (campaign) {
            setCampaignName(campaign["campaign-name"]);
          }
        });

        // Fetch appeals
        getDataFromFirebase("appeals", (data) => setAppeals(data));
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!projectsUpdated.current && projects.length > 0 && appeals.length > 0) {
      const updatedProjects = projects.map((project) => {
        const campaignCount = appeals.filter(
          (appeal) => appeal.fund_code === project.id
        ).length;
        return { ...project, campaignCount };
      });
      setProjects(updatedProjects);
      projectsUpdated.current = true; // Mark projects as updated
    }
  }, [projects, appeals]);

  useEffect(() => {
    if (donations.length > 0 && projects.length > 0) {
      setShouldCalculate(true);
    }
  }, [donations.length, projects.length]);

  useEffect(() => {
    if (shouldCalculate) {
      calculateDonationAmounts();
      setShouldCalculate(false); // reset the flag after calculation
    }
  }, [shouldCalculate]);

  const processDonationData = (data) => {
    const filteredDonations = data.filter(
      (donation) => donation.campaignData.campaign_code === campaignId
    );
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    let donationsToday = 0;
    let donationsThisWeek = 0;
    let donationsThisMonth = 0;
    let donors = new Set();

    filteredDonations.forEach((donation) => {
      const donationDate = new Date(donation.timestamp);
      const amount = parseFloat(donation.amount);

      if (donationDate >= startOfToday) {
        donationsToday += amount;
      }
      if (donationDate >= startOfWeek) {
        donationsThisWeek += amount;
      }
      if (donationDate >= startOfMonth) {
        donationsThisMonth += amount;
      }
      donors.add(donation.userId);
    });

    setDonationsToday(donationsToday);
    setDonationsThisWeek(donationsThisWeek);
    setDonationsThisMonth(donationsThisMonth);
    setTotalDonors(donors.size);
  };

  const calculateDonationAmounts = () => {
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const startOfQuarter = new Date(
      today.getFullYear(),
      Math.floor(today.getMonth() / 3) * 3,
      1
    );
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(today.getFullYear(), 0, 0);

    const updatedProjects = projects.map((project) => {
      let thisWeek = 0;
      let thisMonth = 0;
      let lastMonth = 0;
      let thisQuarter = 0;
      let thisYear = 0;
      let lastYear = 0;
      let totalGoalAchieved = 0;
      donations.forEach((donation) => {
        const donationDate = new Date(donation.timestamp);
        const amount = parseFloat(donation.amount);

        if (donation.campaignData.fund_code === project.id) {
          if (donationDate >= startOfWeek) {
            thisWeek += amount;
          }
          if (donationDate >= startOfMonth) {
            thisMonth += amount;
          }
          if (
            donationDate >= startOfLastMonth &&
            donationDate <= endOfLastMonth
          ) {
            lastMonth += amount;
          }
          if (donationDate >= startOfQuarter) {
            thisQuarter += amount;
          }
          if (donationDate >= startOfYear) {
            thisYear += amount;
          }
          if (
            donationDate >= startOfLastYear &&
            donationDate <= endOfLastYear
          ) {
            lastYear += amount;
          }
          totalGoalAchieved += amount;
        }
      });

      return {
        ...project,
        thisWeek,
        thisMonth,
        lastMonth,
        thisQuarter,
        thisYear,
        lastYear,
        totalGoalAchieved,
      };
    });

    setProjects(updatedProjects);
  };

  const exportToExcel = () => {
    const formatDate = (date) => {
      return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "2-digit",
      });
    };
    const formattedTime = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const data = [
      ["Generation Data", formatDate(new Date())],
      ["Generation Time", formattedTime],
      ["Campaign Name", campaignName],
    ];

    if (startDate && endDate) {
      data.push([
        "Data of",
        `${formatDate(startDate)} - ${formatDate(endDate)}`,
      ]);
    }

    data.push(
      [], // Empty row for spacing
      [
        "Appeal Title",
        "This Week",
        "This Month",
        "Last Month",
        "This Quarter",
        "This Year",
        "Last Year",
        "Total Goals",
      ]
    );

    // Add project data rows
    appeals.forEach((appeal) => {
      data.push([
        appeal["title"],
        appeal["thisWeek"],
        appeal["thisMonth"],
        appeal["lastMonth"],
        appeal["thisQuarter"],
        appeal["thisYear"],
        appeal["lastYear"],
        appeal["totalGoalAchieved"],
      ]);
    });

    // Convert data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);
    const summaryData = [
      ["Donations Today", `$${donationsToday.toFixed(2)}`],
      ["Donations This Week", `${donationsThisWeek}`],
      ["Donations This Month", `${donationsThisMonth}`],
      ["Total Donors", totalDonors],
      ["Total Campaigns", totalCampaigns],
      ["Total Projects", totalProjects],
    ];
    const sm = XLSX.utils.aoa_to_sheet(summaryData);

    // Create workbook and append the sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects");
    XLSX.utils.book_append_sheet(wb, sm, "Summary");

    // Write workbook to file
    let fileName = `Appeal`;
    if (startDate && endDate) {
      fileName += ` - ${formatDate(startDate)} to ${formatDate(endDate)}`;
    }
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }
  const columns = [
    {
      Header: "Appeal",
      accessor: "title", // accessor is the "key" in the data
    },
    {
      Header: "This week",
      accessor: "thisWeek", // accessor is the "key" in the data
    },
    {
      Header: "This Month",
      accessor: "thisMonth", // accessor is the "key" in the data
    },
    {
      Header: "Last Month",
      accessor: "lastMonth", // accessor is the "key" in the data
    },
    {
      Header: "This Quarter",
      accessor: "thisQuarter", // accessor is the "key" in the data
    },
    {
      Header: "This Year",
      accessor: "thisYear", // accessor is the "key" in the data
    },
    {
      Header: "Last Year",
      accessor: "lastYear", // accessor is the "key" in the data
    },
    {
      Header: "Total Goals",
      accessor: "totalGoalAchieved", // accessor is the "key" in the data
    },
  ];

  const handleRow = (appeal) => {
    navigate(`/dashboard/appeals/${appeal.id}`);
  };
  return (
    <div>
      <h4 className="mb-3 mb-md-0">Appeals for Campaign {campaignName}</h4>
      <hr />
      <div style={{ textAlignLast: "end", marginBottom: "15px" }}>
        <Button variant="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </div>

      <Row>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations Today</h6>
              <h2 className="text-red fw-bold">${donationsToday.toFixed(2)}</h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations This Week</h6>
              <h2 className="text-red fw-bold">
                ${donationsThisWeek.toFixed(2)}
              </h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations This Month</h6>
              <h2 className="text-red fw-bold">
                ${donationsThisMonth.toFixed(2)}
              </h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Donors</h6>
              <h2 className="text-red fw-bold">{totalDonors}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Campaigns</h6>
              <h2 className="text-red fw-bold">{totalCampaigns}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Projects</h6>
              <h2 className="text-red fw-bold">{totalProjects}</h2>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="d-flex" style={{ marginLeft: "auto" }}>
        <div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="form-control"
            placeholderText="Start Date"
          />
        </div>

        <div style={{ marginLeft: "15px" }}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="form-control"
            placeholderText="End Date"
          />
        </div>
      </div>

      <DataTable data={appeals} columns={columns} onRowClick={handleRow} />
    </div>
  );
}
