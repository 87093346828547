import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { addDataToFirebase, getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

export default function AddGroup() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);   
    const [groupName, setGroupName] = useState('');
    const [selectedUsers, setSelectedUsers]= useState([]);
    const [selectedEmails, setSelectedeEmails]= useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch users from Firebase
        getDataFromFirebase('users', (usersArray) => {
            setUsers(usersArray.map(user => ({ ...user, isActive: user.isActive !== false }))); // By default, everyone is active
        });

        // Fetch roles from Firebase
        getDataFromFirebase('roles', (rolesArray) => {
            setRoles(rolesArray);
        });
    }, []);

    const handleChange = (event, user) => {
        const selectUsers = [...selectedUsers];
        const selectEmails =[...selectedEmails];
        console.log('value:', user);
        const currentUser = user.firstName + ' ' + user.lastName;
        const currentEmail = user.email
        if (event.target.checked) {
            selectUsers.push(currentUser);
            selectEmails.push(currentEmail);
            console.log('selected users:', selectUsers);
            console.log('selected emails:', selectEmails);
            setSelectedUsers(selectUsers);
            setSelectedeEmails(selectEmails);
        }
        else {
            console.log('selected users:', selectedUsers, 'current user', currentUser);
            const filteredUsers =selectUsers?.filter(user=>user !== currentUser);
            const filteredEmails = selectEmails?.filter(email=>email !== currentEmail);
            console.log('selected users:', filteredUsers);
            console.log('selected emails:', filteredEmails);
            setSelectedUsers(filteredUsers)
            setSelectedeEmails(filteredEmails)
        }
    }

    useEffect(() => {
        if (users.length > 0) {
            console.log('users data----->', users);
            console.log('selected users:', selectedUsers);
        }
    }, [users, selectedUsers])

    const saveUserGroup = async () =>{
        if(groupName && selectedUsers.length > 0) {
            const groupData = {name:groupName, members:selectedUsers, emails:selectedEmails}
            await addDataToFirebase('groups',groupData)
            navigate('/dashboard/groups');
        }
        else {
                setError('Please type group name and select user from table')
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <div>
                        <h3>Create New User Group</h3>
                    </div>
                    <div className='mt-3'>
                        <label className='form-label'>Group Name</label>
                        <input className='form-control' type="text" placeholder='eg. ' value={groupName} onChange={(e) => setGroupName(e.target.value)} required />
                    </div>
                    {error && 
                    <span style={{color:"red"}}>{error}</span>}
                    <div className='mt-3 mb-4'>
                    {/* <label className='form-label'>Selected Users</label> */}
                    <Row>
                    { selectedUsers.map((selectedUser)=>{
                         return <Col sm={3} className="mt-2">   <div style={{background:'white', borderRadius:"12px", padding:'10px', border:'1px solid black'}}>{selectedUser}</div></Col>
                    })}
                    </Row>
                   
                    </div>
                    <div className='mb-4 text-align-center'>
                        <Button onClick={saveUserGroup} >Save User Group</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th> Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Role</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.userId} style={{ cursor: 'pointer' }}>
                                    <td> <input type="checkbox" 
                                    // checked={checked} 
                                    onChange={(event)=>handleChange(event, user)} style={{ marginRight: "15px" }}></input>
                                        {user.firstName + ' ' + user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td>{user.role}</td>
                                    <td>{user.isActive ? 'Active' : 'InActive'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}
