import React,{useEffect,useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingOverlay from "./LoadingOverlay"
import {useNavigate} from 'react-router-dom'
export default function EmailVerified() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulate an async operation like fetching data
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("isVerified",true)
        navigate("/dashboard")
      }, 3000);
    }, []);
  return (
    <LoadingOverlay loading={loading}>
    <section className="hero-area">
      <div className="single-slider hero-padding">
        <div className="container">
          <Row>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
              Email  verified successfully</h1>
            </Col>
          </Row>
        </div>
      </div>
    </section>
    </LoadingOverlay>
  )
}
