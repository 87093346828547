import React, { useState, useEffect } from 'react';
import { addDataToFirebase, getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { Form, useNavigate } from 'react-router-dom';
import { Button, Card, Col, FormGroup, FormLabel, FormText, Row, Table } from 'react-bootstrap';
import DataTable from '../DataTable';

const AddRole = () => {
  const [roleName, setRoleName] = useState('');
  const [payment, setPayment] = useState(false);
  const [appeal, setAppeal] = useState(false);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch roles from Firebase
    getDataFromFirebase('roles', (rolesArray) => {
      setRoles(rolesArray);
    });
  }, []);

  const saveRole = async (event) => {
    event.preventDefault();
    if (roleName.trim() !== '') {
      const roleData = {
        roleName,
        payment,
        appeal,
        timestamp: Date.now(),
      };
      try {
        await addDataToFirebase('roles', roleData);
        alert('Role saved!');
        navigate('/dashboard/roles'); // Navigate to the roles list page
      } catch (error) {
        console.error('Error saving role:', error);
        alert('Failed to save role.');
      }
    } else {
      alert('Please enter a role name.');
    }
  };

  const columns = [
    {
      Header: 'Role Name',
      accessor: 'roleName', // accessor is the "key" in the data
    },
    {
      Header: 'Payment',
      accessor: 'payment', // accessor is the "key" in the data
      Cell: ({ value }) => (value ? 'Yes' : 'No'), // Custom rendering for boolean values
    },
    {
      Header: 'Appeal',
      accessor: 'appeal', // accessor is the "key" in the data
      Cell: ({ value }) => (value ? 'Yes' : 'No'), // Custom rendering for boolean values
    },
    {
      Header: 'Timestamp',
      accessor: 'timestamp', // accessor is the "key" in the data
      Cell: ({ value }) => new Date(value).toLocaleString(), // Formatting timestamp
    },
  ];

  const handleRow =() => {
          
  }

  return (
    <>
      <Row>
          <Col>
              <div >
                  <div className='d-flex'>
                      <h3 className='title font-700 wow fadeInUp mt-2 mb-0'>Add Role</h3>
                  </div>
              </div>
              <hr />
          </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <form onSubmit={saveRole}>
                  <div className="form-group">
                    <label className="form-label">Role Name</label>
                    <input
                      type="text"
                      className='form-control'
                      value={roleName}
                      placeholder='Role Name'
                      onChange={(e) => setRoleName(e.target.value)}
                    />
                  </div>
                  <div className='d-flex gap-4 my-3'>
                    <div className="form-group d-flex align-items-center gap-2">
                        <input
                          type="checkbox"
                          classname='form-control'
                          checked={payment}
                          onChange={(e) => setPayment(e.target.checked)}
                        />
                        <label className="form-label mb-0">Payment</label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-2">
                        <input
                          type="checkbox"
                          classname='form-control'
                          checked={appeal}
                          onChange={(e) => setAppeal(e.target.checked)}
                        />
                        <label className="form-label mb-0">Appeal</label>
                    </div>
                  </div>
                  <div>
                    <Button type="submit">Save Role</Button>
                  </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Row className='mt-4'>
        <Col>
          <h4>All Roles</h4>
          <DataTable columns={columns} data={roles} onRowClick={handleRow} />
        </Col>
      </Row>
    </>
  );
};

export default AddRole;
