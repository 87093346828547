import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  addDataToFirebase,
  getDataFromFirebase,
} from "../../firebase/firebaseFunctions";
import { useParams } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";

const TicketDiscussion = () => {
  const [ticket, setTicket] = useState({
    id: "",
    subject: "",
    priority: "",
    submitter: "",
    status: "",
  });
  const [ticketThread, setTicketThread] = useState("");
  const [ticketThreads, setTicketThreads] = useState([]);
  const [tickets, setTickets] = useState([]);
  const params = useParams();
  const auth = useAuth();

  const threadsContainerRef = useRef(null); // Ref to track the scrollable div

  useEffect(() => {
    getDataFromFirebase("tickets", (tickets) => {
      setTickets(tickets);
    });
  }, []);

  useEffect(() => {
    if (params && tickets.length > 0) {
      const requiredTicket = tickets.find((ticket) => ticket.id === params.id);
      if (requiredTicket) {
        setTicket({
          id: requiredTicket.id,
          subject: requiredTicket.subject,
          priority: requiredTicket.priority,
          submitter: requiredTicket.submitter,
          status: requiredTicket.status,
        });
      }
    }
  }, [params, tickets]);

  useEffect(() => {
    if (params) {
      getDataFromFirebase("ticket-threads", (ticketThreads) => {
        const filteredThreads = ticketThreads
          .filter((thread) => thread.ticketId === params.id)
          .sort((a, b) => new Date(a.timestamp || 0) - new Date(b.timestamp || 0)); // Sort by timestamp in ascending order
        setTicketThreads(filteredThreads);
      });
    }
  }, [params]);

  useEffect(() => {
    // Scroll to the last message whenever ticketThreads is updated
    if (threadsContainerRef.current) {
      threadsContainerRef.current.scrollTo({
        top: threadsContainerRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
    
  }, [ticketThreads]);

  const handleChange = (event) => {
    setTicketThread(event.target.value);
  };

  const sendMessage = async () => {
    const name = auth.currentUser.role === "admin" ? "Admin" : ticket.submitter;
    const ticketThreadObject = {
      message: ticketThread,
      senderEmail: auth.currentUser.email,
      senderName: name,
      ticketId: params.id,
      timestamp: new Date().toISOString(),
    };
    await addDataToFirebase("ticket-threads", ticketThreadObject);
    setTicketThread("");
  };

  return (
    <div>
      <Row>
        <Col xs={6}>
          <Card>
            <Card.Body>
              <h4>Ticket: {ticket.subject}</h4>
              <div className="d-flex gap-3">
                <span>
                  Submitted By:{" "}
                  <b className="text-capitalize">{ticket.submitter}</b>
                </span>
                <span>
                  Priority: <b className="text-capitalize">{ticket.priority}</b>
                </span>
                <span>
                  Status: <b className="text-capitalize">{ticket.status}</b>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <div
                style={{
                  height: "550px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  paddingRight: "20px",
                }}
                ref={threadsContainerRef} // Ref to the scrollable div
              >
                {ticketThreads &&
                  ticketThreads.map((thread) => {
                    return (
                      <div
                        key={thread.timestamp}
                        className="mb-3 pb-3"
                        style={{ borderBottom: "1px solid #e7e7e7" }}
                      >
                        <Row className="mb-2">
                          <Col>
                            <h6 className="font-capitalize fw-bold mb-1">
                              {thread.senderName}
                            </h6>
                            <p className="mb-0">{thread.senderEmail}</p>
                          </Col>
                          <Col className="text-end">
                            <p className="mb-0">
                              {new Date(thread.timestamp).toLocaleString()}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="fs-16 mb-0">{thread.message}</p>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <h4>Response to this ticket</h4>
              <label className="form-label">Message</label>
              <textarea
                className="form-control"
                onChange={handleChange}
                value={ticketThread}
              />
              <Button
                disabled={ticketThread.length === 0}
                className="mt-2"
                onClick={sendMessage}
              >
                Add Message
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TicketDiscussion;
