import React, { useEffect, useState } from "react";
import { addDataToFirebase } from "../firebase/firebaseFunctions";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { getDatabase } from "firebase/database";
import { set, ref } from "firebase/database";
import {
  capturePaypalPayment,
  createPayPalOrder,
  createPayPalProduct,
} from "../Paypal/payPalApiCalls";
import LoadingOverlay from "./FrontEnd/LoadingOverlay";
const AddAppealForm = ({ fields, referencePath, buttonLabel, name }) => {
  const [formData, setFormData] = useState({});
  const [fileData, setFileData] = useState(null);
  const [repeaters, setRepeaters] = useState({});
  const [recurring, setRecurring] = useState(false);
  const [billingCycle, setBillingCycle] = useState([]);
  const [roundDuration, setRoundDuration] = useState("day");
  const [roundCount, setRoundCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { id, type, files, value, checked } = e.target;
    if (type === "file") {
      setFileData(files[0]);
    } else if (type === "checkbox") {
      console.log("id", id);
      if (id === "is_recurring") {
        setRecurring(checked);
      }
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleSelectChange = (id, selectedOption) => {
    setFormData({
      ...formData,
      [id]: selectedOption ? selectedOption.value : null,
    });
  };

  const handleRepeaterChange = (repeaterId, index, fieldId, value) => {
    const newRepeaters = { ...repeaters };
    if (!newRepeaters[repeaterId]) {
      newRepeaters[repeaterId] = [];
    }
    newRepeaters[repeaterId][index] = {
      ...newRepeaters[repeaterId][index],
      [fieldId]: value,
    };
    setRepeaters(newRepeaters);
  };

  const addRepeaterRow = (repeaterId) => {
    console.log("first", {
      ...repeaters,
      [repeaterId]: [...(repeaters[repeaterId] || []), {}],
    });
    setRepeaters({
      ...repeaters,
      [repeaterId]: [...(repeaters[repeaterId] || []), {}],
    });
  };

  const removeRepeaterRow = (repeaterId, index) => {
    const newRepeaters = { ...repeaters };
    if (newRepeaters[repeaterId]) {
      newRepeaters[repeaterId].splice(index, 1);
      setRepeaters(newRepeaters);
    }
  };

  const uploadImage = (formData) => {
    return axios.post(
      "https://hci-webapp.onrender.com/upload-images",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let updatedFormData = { ...formData };
      if (recurring) {
        if (billingCycle.length === 0) {
          toast.error("Please select at least one Billing Cycle");
          setLoading(false);
          return;
        }
        updatedFormData = { ...updatedFormData, billingCycle };
      }

      if (fileData) {
        const formData = new FormData();
        formData.append("files", fileData);

        // Call uploadImage and wait for the response
        const response = await uploadImage(formData);

        // Log the response to the console
        console.log("response:", response);

        // Check if the response indicates success
        if (response.data.success) {
          const fileName = response.data.fileNames;
          // console.log(fileName[0]);

          // Update the form data state with the new thumbnail URL
          updatedFormData = {
            ...updatedFormData,
            ...repeaters,
            thumbnail: `https://hci-webapp.onrender.com/images/${fileName[0]}`,
          };
        } else {
          // Throw an error if the file upload failed
          throw new Error("File upload failed");
        }
      }

      // New addition: Extract donation child items and send them to the server
      if (repeaters.donation_options) {
        const donationResponse = await axios.post(
          "https://hci-webapp.onrender.com/create-stripe-products",
          // "http://localhost:3001/create-stripe-products",
          {
            donationChilds: repeaters.donation_options,
            ...updatedFormData,
          }
        );
        // const payPalResponse = await createPayPalProduct();

        if (donationResponse.data.success) {
          const products = donationResponse.data.products;
          const prices = donationResponse.data.prices;

          // Update donation options with product and price IDs
          const updatedDonationOptions = repeaters.donation_options.map(
            (option, index) => {
              return {
                ...option,
                product_id: products[index].id,
                price_id: prices[index].id,
              };
            }
          );

          updatedFormData.donation_options = updatedDonationOptions;
        }
      }

      // Update formData state
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...updatedFormData,
      }));

      // Wait for the state update
      const dataSnapshot = await addDataToFirebase(referencePath, {
        ...updatedFormData,
        active: true,
      });
      const key = dataSnapshot.key;

      const slug = {
        type: referencePath,
        id: key,
      };
      const slugRef = ref(getDatabase(), `slugs/${formData.slug}`);
      await set(slugRef, slug);

      toast.success(name + " Added Successfully");
      // Reset form data
      setFormData({});
      setFileData(null);
      setRepeaters({});
    } catch (error) {
      console.error("Error adding data to Firebase:", error);
      toast.error("Error Adding " + name);
    } finally {
      setLoading(false);
    }
  };

  const generateFormFields = () => {
    return fields.map((field) => {
      const {
        label,
        type,
        min,
        max,
        id,
        classes,
        width,
        rows,
        ph,
        options,
        faqChilds,
        donationChilds,
      } = field;

      let inputField;
      switch (type) {
        case "text":
        case "email":
        case "date":
        case "password":
          inputField = (
            <input
              type={type}
              placeholder={ph}
              id={id}
              min={min}
              name={id}
              max={max}
              className={"form-control " + classes}
              onChange={handleChange}
              value={formData[id] || ""}
            />
          );
          break;
        case "textarea":
          inputField = (
            <textarea
              rows={rows}
              placeholder={ph}
              id={id}
              min={min}
              name={id}
              max={max}
              className={"form-control " + classes}
              onChange={handleChange}
              value={formData[id] || ""}
            ></textarea>
          );
          break;
        case "file":
          inputField = (
            <input
              type="file"
              placeholder={ph}
              id={id}
              name={id}
              className={"form-control " + classes}
              onChange={handleChange}
            />
          );
          break;
        case "number":
          inputField = (
            <input
              type="number"
              placeholder={ph}
              min={min}
              max={max}
              id={id}
              name={id}
              className={"form-control " + classes}
              onChange={handleChange}
              value={formData[id] || ""}
            />
          );
          break;
        case "select":
          inputField = (
            <Select
              id={id}
              name={id}
              className={"basic-single " + classes}
              onChange={(selectedOption) =>
                handleSelectChange(id, selectedOption)
              }
              options={options}
              value={
                options
                  ? options.find((option) => option.value === formData[id])
                  : ""
              }
            />
          );
          break;
        case "checkbox":
          inputField = (
            <input
              type="checkbox"
              id={id}
              name={id}
              className={"form-check-input " + classes}
              onChange={handleChange}
              checked={!!formData[id]}
            />
          );
          break;
        case "repeater":
          const childFields = field.faqChilds || field.donationChilds || [];
          inputField = (
            <div className={"repeater " + classes}>
              {(repeaters[id] || []).map((repeaterData, index) => (
                <Row
                  key={index}
                  className="repeater-row d-flex align-items-center"
                >
                  <Col xs={11}>
                    <Row>
                      {childFields.map((childField) => {
                        const childId = `${id}-${index}-${childField.id}`;
                        return (
                          <Col
                            xs={childField.width}
                            className="form-group"
                            key={childId}
                          >
                            <label className="form-label" htmlFor={childId}>
                              {childField.label}
                            </label>
                            <input
                              type={childField.type}
                              placeholder={childField.ph}
                              id={childId}
                              name={childId}
                              className={"form-control " + childField.classes}
                              onChange={(e) =>
                                handleRepeaterChange(
                                  id,
                                  index,
                                  childField.id,
                                  e.target.value
                                )
                              }
                              value={repeaterData[childField.id] || ""}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col xs={1} className="form-group">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => removeRepeaterRow(id, index)}
                    >
                      Remove
                    </button>
                  </Col>
                </Row>
              ))}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => addRepeaterRow(id)}
              >
                Add {label}
              </button>
            </div>
          );
          break;
        default:
          inputField = (
            <input
              type="text"
              placeholder={ph}
              id={id}
              name={id}
              className={"form-control " + classes}
              onChange={handleChange}
              value={formData[id] || ""}
            />
          ); // Default to text input if type is not recognized
      }

      return (
        <Col xs={width} className="form-group" key={id}>
          <label className="form-label" htmlFor={id}>
            {label}
          </label>
          {inputField}
        </Col>
      );
    });
  };

  const addBillingCycle = () => {
    setBillingCycle((prev) => {
      const duplicate = prev.some(
        (cycle) =>
          cycle.roundDuration === roundDuration &&
          cycle.roundCount === roundCount
      );

      // If duplicate, do not add it; otherwise, add the new cycle
      if (duplicate) {
        toast.error("This billing cycle already exists!");
        return prev;
      }

      return [
        ...prev,
        {
          roundDuration,
          roundCount,
        },
      ];
    });
  };

  const removeBillingCycle = (index) => {
    setBillingCycle((prev) => prev.filter((val, i) => i !== index));
  };

  return (
    <LoadingOverlay loading={loading}>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Row>{generateFormFields()}</Row>
            {recurring && (
              <Form.Group>
                <h5 className="font-700 wow fadeInUp mb-2 mt-2">
                  Billing Cycles
                </h5>
                {billingCycle.map((cycle, index) => (
                  <Row xs={6} key={index} className="align-items-center mb-2">
                    <Col>
                      <p className="mb-0 text-capitalize">
                        {cycle.roundCount + " " + cycle.roundDuration}
                      </p>
                    </Col>
                    <Col xs="auto">
                      <button
                        type="button"
                        onClick={() => removeBillingCycle(index)}
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                    </Col>
                  </Row>
                ))}

                <Col xs={6}>
                  <label className="form-label">Round Duration</label>
                  <select
                    className="form-control"
                    name="roundDuration"
                    value={roundDuration}
                    onChange={(e) => setRoundDuration(e.target.value)}
                  >
                    <option value={"day"}>Day</option>
                    <option value={"week"}>Week</option>
                    <option value={"month"}>Month</option>
                    <option value={"year"}>Year</option>
                  </select>
                  <label className="form-label">Round Count</label>
                  <input
                    className="form-control"
                    name="roundCount"
                    type="number"
                    value={roundCount}
                    onChange={(e) => setRoundCount(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={addBillingCycle}
                    className="btn btn-primary mt-3"
                  >
                    Add Billing Cycle
                  </button>
                </Col>
              </Form.Group>
            )}
            <button className="btn btn-danger mt-3" type="submit">
              {buttonLabel}
            </button>
          </Col>
        </Row>
      </form>
    </LoadingOverlay>
  );
};

export default AddAppealForm;
