import { getAuth, GoogleAuthProvider, FacebookAuthProvider, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Set up an authentication state observer
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, save the UID to localStorage
    localStorage.setItem('uid', user.uid);
  } else {
    // User is signed out, remove the UID from localStorage
    localStorage.removeItem('uid');
  }
});

export { googleProvider, facebookProvider };

export default auth;
