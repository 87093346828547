import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { useAuth } from '../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function EmailTemplates() {
  const navigate = useNavigate();
  const currentUser = useAuth();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    let referencePath = 'email_templates';
    console.log(referencePath);

    getDataFromFirebase(referencePath, (templatesArray) => {
      setTemplates(templatesArray);
    });
  }, [currentUser]);

  const handleRowClick = (template) => {
    navigate(`/dashboard/email-template-details/${template.id}`);
  };

  return (
    <>
      <Row>
        <Col>
          <div className='d-flex justify-content-between align-items-center'>
            <h4 className="mb-0">Email Templates</h4>
          </div>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Content</th>
              </tr>
            </thead>
            <tbody>
              {templates.map((template) => (
                <tr key={template.id} onClick={() => handleRowClick(template)}>
                  <td>{template.templateName}</td>
                  <td>{template.content}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
