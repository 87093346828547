import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import animationData from '../../confirmed.json';
import Lottie from 'lottie-react';

const ThankYou = () => {
  return (
    <>
      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <Row>
              <Col className='d-flex flex-column text-center justify-content-center align-items-center'>
              
                <Lottie 
                  animationData={animationData} 
                  loop={true} 
                  autoplay={true} 
                  style={{ width: 120, height: 120 }} 
                />
                <h1 className='title font-700 wow fadeInUp mt-2 mb-3'>Thank you for your donation!</h1>
                <p>We deeply appreciate your support and are excited to put your contribution to work for our cause. Your generosity helps us make a difference and reach our goals.</p>
                <Link to={'/'} className='btn-primary-fill pill-btn'>Back to home</Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  )
}

export default ThankYou