import React, { useState, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTable, usePagination, useGlobalFilter, useSortBy } from "react-table";
// var fetch = require('node-fetch');




const DataTable = ({ columns, data, onRowClick }) => {
  const [searchInput, setSearchInput] = useState("");

  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy, // Add useSortBy here
    usePagination
  );
  

  const handleSearch = (event) => {
    const value = event.target.value || "";
    setGlobalFilter(value);
    setSearchInput(value);
  };

  // const cancelSubscription = async (subscription) => {
  //   try {
  //     console.log(
  //       "Cancelling subscription for ID:",
  //       subscription.subscriptionId
  //     );
  //     console.log("Payment Gateway:", subscription.paymentGateWay);

  //     let response;

  //     if (subscription.paymentGateWay === "paypal") {
  //       fetch(`https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscription.subscriptionId}/cancel`, {
  //           method: 'POST',
  //           headers: {
  //               'Authorization': `Bearer access_token${PAYPAL_CLIENT_SECRET}`,
  //               'Content-Type': 'application/json',
  //               'Accept': 'application/json'
  //           },
  //           body: JSON.stringify({ "reason": "Not satisfied with the service" })
  //       });
  //       await updateSubscriptionStatus(subscription.id); 
  //       console.log("Handling PayPal cancellation (not implemented)");
  //       // Add your PayPal cancellation logic here
  //     } else if (subscription.paymentGateWay === "stripe") {
  //       // Check if the subscription exists before attempting to cancel
  //       try {
  //         const subscriptionData = await stripe.subscriptions.retrieve(
  //           subscription.subscriptionId
  //         );
  //         console.log("Subscription found:", subscriptionData);

  //         response = await stripe.subscriptions.cancel(
  //           subscription.subscriptionId
  //         );
  //         await updateSubscriptionStatus(subscription.id); 
  //         console.log("Stripe cancellation response:", response);
  //       } catch (stripeError) {
  //         console.error("Stripe error:", stripeError);
  //       }
  //     }

      
  //   } catch (error) {
  //     console.error("Error cancelling subscription:", error);
  //     // Optionally add user feedback here, e.g., showing an error message
  //   }
  // };

  return (
    <>
      <Row>
        <Col>
          <input
            value={searchInput}
            onChange={handleSearch}
            placeholder="Search"
            className="form-control mb-3 mt-1"
            style={{ height: "50px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <table className="table table-hover" {...getTableProps()}>
              <thead>
  {headerGroups.map((headerGroup) => (
    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
      {headerGroup.headers.map((column) => (
        <th
          {...column.getHeaderProps(column.getSortByToggleProps())} // Enable sorting on header click
          key={column.id}
          className="p-3"
        >
          {column.render("Header")}
          <span>
            {column.isSorted
              ? column.isSortedDesc
                ? " ↓" // Descending
                : " ↑" // Ascending
              : ""}
          </span>
        </th>
      ))}
    </tr>
  ))}
</thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={row.id}
                        onClick={() => onRowClick(row.original)}
                      >
 
  <>
    {row.cells.map((cell) => (
      <td
        {...cell.getCellProps()}
        key={cell.column.id}
        className="p-3 border"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
          maxWidth: "200px",
        }}
      >
        {cell.render("Cell")}
      </td>
    ))}
    {/* Adding the Cancel button as the last column */}
    {/* <td className="p-3 border">
      <button
        className="btn btn-danger"
        onClick={(e) => {
          e.stopPropagation(); // Prevents row click event from triggering
          cancelSubscription(row.original); // Pass the row data if needed
        }}
      >
        Cancel
      </button>
    </td> */}
  </>


                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Row>
                <Col className="d-flex gap-2 align-items-center justify-content-between">
                  <div>
                    <select
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                      style={{ marginLeft: "10px", width: "100px" }}
                      className="form-control"
                    >
                      {[10, 20, 30, 40, 50].map((size) => (
                        <option key={size} value={size}>
                          Show {size}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <span style={{ marginLeft: "10px" }}>
                      Page{" "}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>
                    </span>
                  </div>
                  <div>
                    <button
                      className="btn btn-dark"
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-dark mx-2"
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Next
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataTable;
