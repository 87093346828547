import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataTable from '../DataTable';
import { _getDataFromFirebase, getDataFromFirebase, getNodeFromFirebase } from '../../firebase/firebaseFunctions';
import { useAuth } from '../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function Donations() {
    const navigate = useNavigate();
    const auth = useAuth();
    
    const [donations, setDonations] = useState([]);

    useEffect(() => {
        console.log("Donations:", donations);
    }, [donations]);

    useEffect(() => {
        let referencePath = 'donation_payments';

        const fetchAndSetDonations = async (projectsArray) => {
            const enrichedDonations = await Promise.all(
                projectsArray.map(async (donation) => {
                    const project = await getProjectName(donation.project);
                    const campaign = await getCampaignTitle(donation.campaign);
                    return { ...donation, project, campaign };
                })
            );

            // Sort donations by timestamp in descending order (latest first)
            const sortedDonations = enrichedDonations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setDonations(sortedDonations);
            console.log("Sorted Donations:", sortedDonations);
        };

        if (auth.currentUser?.role === "admin") {
            // Fetch all donations for admin
            getDataFromFirebase(referencePath, fetchAndSetDonations);
        } else {
            // Fetch only the current user's donations
            _getDataFromFirebase(referencePath, (projectsArray) => {
                const userDonations = projectsArray.filter(
                    (donation) => donation.userId === auth.currentUser.uid
                );
                fetchAndSetDonations(userDonations);
            });
        }
    }, [auth.currentUser]);

    const getProjectName = async (projectId) => {
        return new Promise((resolve) => {
            getNodeFromFirebase(`projects/${projectId}`, (project) => {
                resolve(project && project['project-name'] ? project['project-name'] : 'custom');
            });
        });
    };

    const getCampaignTitle = async (campaignId) => {
        return new Promise((resolve) => {
            getNodeFromFirebase(`campaigns/${campaignId}`, (campaign) => {
                resolve(campaign && campaign.title ? campaign.title : 'custom');
            });
        });
    };

    const handleRowClick = (donation) => {
        navigate(`/dashboard/donation-details/${donation.id}`);
    };

    const columns = [
        {
            Header: 'Amount',
            accessor: 'amount',
        },
        {
            Header: 'Campaign',
            accessor: 'campaign',
        },
        {
            Header: 'Project',
            accessor: 'project',
        },
        {
            Header: 'Transaction ID',
            accessor: 'checkoutID',
        },
        {
            Header: 'Date',
            accessor: (row) => new Date(row.timestamp).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true
            }),
        },
    ];

    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="mb-0">Donations</h4>
                    </div>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable columns={columns} data={donations} onRowClick={handleRowClick} />
                </Col>
            </Row>
        </>
    );
}
