import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from "react-select";
import { Button, Col, Row } from 'react-bootstrap';
import { addDataToFirebase, getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';

function AddUpdateReport() {
    const [formData, setFormData] = useState({ title: "", description: "", image: "", category: "", document: "", status: "" });
    const [reports, setReports] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        getDataFromFirebase('reports', (reports) => setReports(reports));
    }, [])

    useEffect(() => {
        if (params.id) {
            let requiredReport = {}
            if (reports.length > 0) {
                requiredReport = reports.find(report => report.id === params.id);
            }
            if (requiredReport) {
                console.log('required report----------->', requiredReport);
                const report = {
                    title: requiredReport.title,
                    description: requiredReport.description,
                    image: requiredReport.image,
                    document: requiredReport.document,
                    category: requiredReport.category,
                    status: requiredReport.status
                }
                setFormData(report)
            }
        }
    }, [params.id, reports]);

    useEffect(() => {
        console.log('form data--------------->', formData);
    }, [formData])

    const handleFile = (event) => {
        const file = event.target.files[0];
        setFormData({ ...formData, [event.target.name]: event.target.value });
        console.log('file:', file);
    }

    const handleReport = async (e) => {
        e.preventDefault();
        try {
            if (formData.title && formData.description && formData.category) {
                const reportObject = {
                    title: formData.title,
                    description: formData.description,
                    image: formData.image,
                    document: formData.document,
                    category: formData.category,
                    status: formData.status
                };
                if (params.id) {
                    console.log('about to update the report-------->', reportObject)
                    await updateFieldsInFirebase(`reports/${params.id}`, reportObject);
                } else {
                    await addDataToFirebase('reports', reportObject);
                }
                navigate('/dashboard/reports');
                setError('');
            } else {
                setError('Please fill all fields');
            }
        } catch (error) {
            console.error("Error updating/creating report:", error);
            toast.error('Error updating/creating report');
        }
    };

    const handleStatusChange = (selectedOption) => {
        setFormData({ ...formData, status: selectedOption.value });
    };

    const statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ];

    return (
        <>
            <section className="hero-area">
                <div className="single-slider hero-padding">
                    <div className="container authContainer">
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <h1 className='title font-700 wow fadeInUp mt-2 mb-3'>{params.id ? "Update Report" : "Create Report"}</h1>
                                    </Col>
                                </Row>
                                <form onSubmit={handleReport}>
                                    <Row className='gap-3'>
                                        <Col xs={12}>
                                            <label className='form-label'>Report Title</label>
                                            <input className='form-control' name='title' type="text" value={formData.title} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                                        </Col>
                                        <Col xs={12}>
                                            <label className='form-label'>Report Description</label>
                                            <input className='form-control' name='description' type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                                        </Col>
                                        <Col xs={12}>
                                            <label className='form-label'>Report Category</label>
                                            <input className='form-control' name='category' type="text" value={formData.category} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                                        </Col>
                                        <Col xs={12}>
                                            <label className='form-label'>Report Status</label>
                                            <Select
                                                value={statusOptions.find(option => option.value === formData.status)}
                                                onChange={handleStatusChange}
                                                options={statusOptions}
                                                className="form-control p-0"
                                                required
                                            />
                                        </Col>
                                        {error &&
                                            <div>
                                                <span style={{ color: 'red' }}>{error}</span>
                                            </div>
                                        }
                                    </Row>
                                    <Row className='mt-3'>
                                        <div className='d-flex'>
                                            <Button className='btn btn-info' type="submit">{params.id ? "Update Report" : "Create Report"}</Button>
                                            <Button className='btn btn-danger' style={{ marginLeft: "15px" }} onClick={() => navigate('/dashboard/reports')}>Cancel</Button>
                                        </div>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AddUpdateReport;
