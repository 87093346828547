import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import {
  getDataFromFirebase,
  getDataFromFirebaseReturnConst,
  getDonationPaymentsFromFirebase,
} from "../../firebase/firebaseFunctions";
import * as XLSX from "xlsx";
import DataTable from "../DataTable";

export default function DonorTable() {
  const { appealId } = useParams();
  const [donors, setDonors] = useState([]);
  const [donorName, setDonorName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDonors = async () => {
      const donationsReferencePath = "donation_payments";

      // Fetch donations data
      getDonationPaymentsFromFirebase(
        donationsReferencePath,
        async (donationsData) => {
          // Filter donations for the specific appeal
          const appealDonations = donationsData.filter((donation) => {
            return donation.appealId === appealId;
          });

          // Extract unique user IDs from donations
          const userIds = [
            ...new Set(appealDonations.map((donation) => donation.userId)),
          ];

          // Aggregate donations for each user
          const appealDonors = await Promise.all(
            userIds.map(async (userId) => {
              const user = await getDataFromFirebaseReturnConst(
                `users/${userId}`
              );
              console.log("Processing userId:", userId, "User found:", user);

              return {
                id: userId,
                email: user ? user.email : "Unknown",
                name: user ? `${user.firstName} ${user.lastName}` : "Unknown",
                donationAmount: appealDonations
                  .filter((donation) => donation.userId === userId)
                  .reduce(
                    (total, donation) => total + parseFloat(donation.amount),
                    0
                  ),
              };
            })
          );

          setDonors(appealDonors);
        }
      );
    };

    fetchDonors();
  }, [appealId]);

  const exportToExcel = () => {
    const donorDataToExport = donors.map((donor) => ({
      "Donor Name": donor.name,
      Email: donor.email,
      "Total Donation Amount": donor.donationAmount.toFixed(2),
    }));

    const worksheet = XLSX.utils.json_to_sheet(donorDataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Donors");
    XLSX.writeFile(workbook, `donors_for_appeal_${appealId}.xlsx`);
  };

  useEffect(() => {
    getDataFromFirebase("appeals", (data) => {
      const appeal = data.find((p) => p.id === appealId);
      if (appeal) {
        setDonorName(appeal.title);
      }
    });
  }, []);
  const columns = [
    {
      Header: "Donor Name",
      accessor: "name", // accessor is the "key" in the data
    },
    {
      Header: "Total Donation Amount $",
      accessor: "donationAmount", // accessor is the "key" in the data
    },
  ];
  const handleRow = (donor) => {
    navigate(`/dashboard/donor-details/${donor.id}/${donor.email}`);
  };
  return (
    <div>
      <h4 className="mb-3 mb-md-0">Donors for Appeal {donorName}</h4>
      <div style={{ textAlignLast: "end", marginBottom: "15px" }}>
        <Button onClick={exportToExcel} className="mb-3">
          Export to Excel
        </Button>
      </div>
      <hr />
      <DataTable data={donors} columns={columns} onRowClick={handleRow} />
    </div>
  );
}
