import axios from "axios";
import dayjs from "dayjs";

const PAYPAL_CLIENT_ID =
  "AZ7_K_u1gkUNusfgLySNl_hDqIHZTqXtFhZBcf41CmEJ4rYojuzUH_Rjmf4nM0pnDjnc-YRn5bcxT5m_";
export const PAYPAL_CLIENT_SECRET =
  "EN0QaJHtKCWbviW5emdzKGDiOv9-ie4zvVqs1PkPh_0PRJKw6odjzqxUtTZqoWPt4WzJJYm_BWJZSpaw";
const PAYPAL_API = "https://api-m.sandbox.paypal.com"; // For live use 'https://api-m.paypal.com'

export const getPayPalAccessToken = async () => {
  const response = await axios({
    url: `${PAYPAL_API}/v1/oauth2/token`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: PAYPAL_CLIENT_ID,
      password: PAYPAL_CLIENT_SECRET,
    },
    data: "grant_type=client_credentials",
  });
  return response.data.access_token;
};

export const createPayPalProduct = async () => {
  const accessToken = await getPayPalAccessToken();
  const productData = {
    name: "Recurring Subscription Product", // Product name
    description: "This is a product for subscription payments.", // Product description
    type: "SERVICE", // Product type: PHYSICAL or SERVICE
    category: "SOFTWARE", // Product category
    // Custom fields
  };

  const response = await axios({
    url: `${PAYPAL_API}/v1/catalogs/products`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: productData,
  });
  return response;
};

export const createPayPalOrder = async (accessToken, amount) => {
  // const accessToken = await getPayPalAccessToken();
  const orderData = {
    intent: "CAPTURE",
    purchase_units: [
      {
        amount: {
          currency_code: "USD",
          value: amount, // Format amount to 2 decimal places
        },
      },
    ],
    application_context: {
      return_url: "http://localhost:3000/capture", // Replace with your return URL
      cancel_url: "http://localhost:3000/cart", // Replace with your cancel URL
    },
  };

  try {
    const response = await axios.post(
      `${PAYPAL_API}/v2/checkout/orders`,
      orderData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data; // Return the response data containing order details
  } catch (error) {
    console.error("Error creating PayPal order:", error);
    throw new Error("Failed to create PayPal order");
  }
};

export const capturePaypalPayment = async (accessToken, orderId) => {
  try {
    // const accessToken = await getPayPalAccessToken();

    // Capture payment for the specified order ID
    const captureResponse = await axios.post(
      `${PAYPAL_API}/v2/checkout/orders/${orderId}/capture`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return captureResponse.data;
  } catch (error) {
    console.error("Error capturing PayPal payment:", error);
    throw new Error("Failed to capture PayPal payment");
  }
};

export const createPayPalBillingPlan = async (
  accessToken,
  amount,
  billingCycle,
  productId,
  subscriptionEndDate
) => {
  const frequencyMap = {
    day: "DAY",
    week: "WEEK",
    month: "MONTH",
    year: "YEAR",
  };

  const startDate = dayjs(); // Assuming start date is now; you can also pass this as an argument if needed
  const endDate = dayjs(subscriptionEndDate);
  const totalDuration = endDate.diff(startDate, billingCycle.roundDuration); // Total duration in 'roundDuration' units
  const cycles = Math.ceil(totalDuration / billingCycle.roundCount);

  const body = JSON.stringify({
    name: `Plan for ${productId}`, // You can customize the plan name
    description: "Plan with regular payment definition.",
    type: "FIXED",
    payment_definitions: [
      {
        name: "Regular payment definition",
        type: "REGULAR",
        frequency: billingCycle.roundDuration.toUpperCase(),
        frequency_interval: `${billingCycle.roundCount}`, // Change this if you want a different interval
        amount: { value: `${amount}`, currency: "USD" },
        cycles: `${cycles}`, // Number of cycles; change as needed
      },
    ],
    merchant_preferences: {
      return_url: "http://localhost:3000/capture",
      cancel_url: "http://localhost:3000/cart",
    },
  });

  console.log("billingPlanData", body);

  try {
    const response = await axios.post(
      `https://api-m.sandbox.paypal.com/v1/payments/billing-plans/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error creating PayPal billing plan:",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to create PayPal billing plan");
  }
};

export const activateBillingPlan = async (accessToken, planId) => {
  const patchBody = [
    {
      op: "replace",
      path: "/",
      value: {
        state: "ACTIVE",
      },
    },
  ];

  try {
    const response = await axios.patch(
      `https://api-m.sandbox.paypal.com/v1/payments/billing-plans/${planId}`,
      patchBody,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    console.log("Billing plan activated:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error activating billing plan:",
      error.response ? error.response.data : error.message
    );
    throw new Error("Failed to activate PayPal billing plan");
  }
};

export const createPayPalBillingAgreement = async (accessToken, planId) => {
  const body = {
    name: "Subscription Agreement",
    description: "Agreement for subscription service.",
    start_date: new Date(new Date().getTime() + 5 * 60 * 1000).toISOString(), // Start date must be in the future
    plan: {
      id: planId,
    },
    payer: {
      payment_method: "PAYPAL",
    },
    shipping_address: {
      line1: "111 First Street",
      city: "San Jose",
      state: "CA",
      postal_code: "95131",
      country_code: "US",
    },
  };

  try {
    const response = await axios.post(
      `https://api-m.sandbox.paypal.com/v1/payments/billing-agreements/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    console.log("billingAgreementData", body);

    return response.data; // Return the response data containing agreement details
  } catch (error) {
    console.error("Error creating PayPal billing agreement:", error);
    throw new Error("Failed to create PayPal billing agreement");
  }
};

export const executePayPalBillingAgreement = async (accessToken, token) => {
  try {
    const response = await axios.post(
      `https://api-m.sandbox.paypal.com/v1/payments/billing-agreements/${token}/agreement-execute`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log("executePayPalBillingAgreement", response);
    return response.data; // Return the response data containing agreement execution details
  } catch (error) {
    console.error("Error executing PayPal billing agreement:", error);
    throw new Error("Failed to execute PayPal billing agreement");
  }
};
