import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row,Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { getCustomTemplate } from '../../emailTemplates/CutomTemplate';


function SendEmail() {
    const [emailData, setEmailData] = useState({ to: "", subject: "", html: "", text:""});
    const [error, setError] = useState('');
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    // const groups = ['admin', 'user', 'tester'];

    useEffect(() => {
        console.log('form data--------------->', emailData);
    }, [emailData]);

    const handleChange = (data)=> {
        console.log('value:', data);
        const emails= data.map(row=>row.value);
        console.log('emails:', emails.toString());
        setEmailData({...emailData, to:emails.toString()})
    }

    useEffect(()=>{
        let updatedUsers;
        getDataFromFirebase('users',(users)=>{
            // console.log('users:', users);
             updatedUsers = users.map((user)=>{
                return {
                    label:user.firstName + ' ' + user.lastName + ' - ' + user.email,
                    value:user.email
                }
            })
            console.log('updated users:', updatedUsers);
            // options.push(updatedUsers)
            // setOptions(updatedUsers)
        })
        getDataFromFirebase('groups',(groups)=>{
            // console.log('groups:', groups);
            const updatedGroups = groups.map(group => ({
                label: `Group - ${group.name}` ,
                value: group.emails.join(', ')
              }));
           
            console.log('updated groups',updatedGroups);
           const combinedOptions= updatedUsers.concat(updatedGroups);
           console.log('merged options:', combinedOptions);
           setOptions(combinedOptions);
        })
     
    },[])


    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            // Fetch user data from Firebase based on email
            const emailObject = {
                to:emailData.to,
                subject:emailData.subject,
                html:getCustomTemplate(emailData.text)
            }
            if (emailData.to && emailData.text && emailData.subject) {
                try {
                    const response = await axios.post('https://hci-webapp.onrender.com/send-email', emailObject);
                    console.log('response: ', response);
                    if (response.status == 200) {
                        toast.success('Email has been sent');
                      console.log('Email sent successfully');
                      setEmailData({to:"", subject:"", text:""});
                      setError("")
                    } else {
                      console.error('Failed to send email');
                    }
                  } catch (error) {
                    console.error('Error:', error);
                  }

            }
            else {
                setError('Please fill all fields')
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            toast.error('Error fetching user data');
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h4 className='title font-700 wow fadeInUp mt-2 mb-3'>Send Email</h4>
                        </Col>
                    </Row>
                    <form onSubmit={sendEmail}>
                        <Row className='gap-3'>
                            <Col xs={12}>
                                <label className='form-label'>To</label>
                                <Select 
                                    options={options} 
                                    isMulti
                                    closeMenuOnSelect={false}
                                    onChange={handleChange}
                                />
                                {/* <input className='form-control' name='title' type="text" value={emailData.title} onChange={(e) => setEmailData({ ...emailData, [e.target.name]: e.target.value })} required /> */}
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Subject</label>
                                <input className='form-control' name='subject' type="text" value={emailData.subject} onChange={(e) => setEmailData({ ...emailData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Text</label>
                                <textarea className='form-control' rows={6} cols={6} name='text' type="text" value={emailData.text} onChange={(e) => setEmailData({ ...emailData, [e.target.name]: e.target.value })} required />
                            </Col>
                            {error &&
                                <div>
                                    <span style={{ color: 'red' }}>{error}</span>
                                </div>
                            }
                        </Row>
                        <Row className='mt-3'>
                            <div className='d-flex'><Button  onClick={sendEmail}>Send Email</Button>
                                <Button  style={{ marginLeft: "15px" }} onClick={() => navigate('/reports')}>Cancel</Button>
                            </div>
                        </Row>
                    </form>
                </Col>
            </Row>
        </>
    );
}

export default SendEmail;
