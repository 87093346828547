import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import DashFooter from './DashFooter'
import Sidebar from './Sidebar'
import { FaBell, FaEdit, FaGift, FaGrin, FaMailBulk, FaSignOutAlt, FaUser } from 'react-icons/fa'
import Signout from '../Auth/Signout'
import auth from '../../firebase/firebaseAuth';
import {updateOtpVerifiedStatus} from '../../firebase/firebaseFunctions'


export default function DashboardLayout() {
  const navigate = useNavigate();
  const handleSignout = async () => {
    try {
      await auth.signOut(); // Firebase sign out method
      localStorage.setItem('isVerified',false);
      console.log("object -------------------- signout")
      const userId=localStorage.getItem("uid");
      await updateOtpVerifiedStatus(userId,false)
      navigate('/login'); // Redirect to login page after sign out
    } catch (error) {
      console.error("Error signing out:", error);
      // Handle sign out error
    }
  };

  const formatDate = (timestamp) => {
    // Check if timestamp is valid
    if (!timestamp || isNaN(timestamp)) {
      return 'Invalid date';
    }
    
    const date = new Date(parseInt(timestamp));
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };


  const [userData, setUserData] = useState([]);

  useEffect(() => {
    var userDataString = localStorage.getItem('userData')||'{}';
    if (userDataString) {
        var userDataJson = JSON.parse(userDataString);
        setUserData(userDataJson);
        console.log(userData);
    }
  }, []);

  return (
    <>
        <div className="main-wrapper">
          <nav className="sidebar">
            <div className="sidebar-header">
              <Link to={'/'} className="sidebar-brand">
                <svg width="180" viewBox="0 0 253 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M77.263 13.6659C77.263 19.5472 77.263 25.4285 77.263 31.3707C76.341 31.3707 75.3553 31.3707 74.2949 31.3707C74.2949 23.8073 74.2949 16.2574 74.2949 8.64355C75.9643 8.64355 77.6621 8.64355 79.4919 8.64355C80.182 13.6047 80.8746 18.5838 81.589 23.7192C81.9464 21.5389 82.278 19.4902 82.6189 17.4431C83.0751 14.7036 83.5257 11.963 84.0208 9.23053C84.0653 8.98513 84.4119 8.6171 84.6294 8.60902C86.1104 8.55399 87.5945 8.58022 89.139 8.58022C89.139 16.1944 89.139 23.7447 89.139 31.3633C88.087 31.3633 87.0392 31.3633 85.9064 31.3633C85.9064 25.753 85.9064 20.1716 85.9237 14.4118C85.941 13.5352 85.941 12.837 85.941 12.1388C85.8826 12.1382 85.8242 12.1376 85.7657 12.137C85.6983 12.8322 85.6309 13.5274 85.5635 14.2227C84.7709 19.9079 83.9783 25.5931 83.166 31.4192C82.15 31.4192 81.1621 31.4471 80.179 31.3913C80.0178 31.3822 79.772 31.0184 79.743 30.7955C79.0054 25.1331 78.2916 19.4676 77.5809 13.6381C77.5021 12.8862 77.4161 12.299 77.3301 11.7117C77.2737 11.7166 77.2173 11.7216 77.1608 11.7265C77.1949 12.373 77.2289 13.0195 77.263 13.6659Z" fill="#E2262A"/>
                  <path d="M113.055 22.7166C113.055 18.0367 113.055 13.3568 113.055 8.62817C114.045 8.62817 115.065 8.62817 116.145 8.62817C116.145 16.1934 116.145 23.7455 116.145 31.4289C115 31.4289 113.845 31.4648 112.697 31.3963C112.487 31.3838 112.191 30.9746 112.122 30.7013C111.007 26.2896 109.922 21.8703 108.831 17.4527C108.769 17.2021 108.706 16.9517 108.642 16.5524C108.548 16.0762 108.456 15.7488 108.364 15.4213C108.315 15.4316 108.267 15.4418 108.219 15.4521C108.248 15.8245 108.278 16.197 108.307 16.5694C108.307 21.4774 108.307 26.3854 108.307 31.3624C107.314 31.3624 106.27 31.3624 105.18 31.3624C105.18 23.7796 105.18 16.2311 105.18 8.63117C106.521 8.63117 107.833 8.63117 109.264 8.63117C110.406 13.2655 111.555 17.9267 112.705 22.7393C112.801 23.2783 112.895 23.6661 112.99 24.0538C113.039 24.0432 113.088 24.0326 113.137 24.0219C113.109 23.5868 113.082 23.1517 113.055 22.7166Z" fill="#E2262A"/>
                  <path d="M50.4909 16.34C50.4909 16.7828 50.4909 17.1285 50.4909 17.5285C51.7863 17.5285 52.9713 17.5285 54.2634 17.5285C54.2634 14.5781 54.2634 11.6202 54.2634 8.62207C55.4579 8.62207 56.5475 8.62207 57.7132 8.62207C57.7132 16.1871 57.7132 23.7618 57.7132 31.384C56.5996 31.384 55.512 31.384 54.3249 31.384C54.3249 27.9028 54.3249 24.4285 54.3249 20.8891C53.0199 20.8891 51.8125 20.8891 50.4876 20.8891C50.4876 24.3899 50.4876 27.865 50.4876 31.3847C49.2832 31.3847 48.1948 31.3847 47.0269 31.3847C47.0269 23.8244 47.0269 16.275 47.0269 8.65257C48.1164 8.65257 49.2305 8.65257 50.491 8.65257C50.491 11.1627 50.491 13.7028 50.4909 16.34Z" fill="#E2262A"/>
                  <path d="M61.1351 29.056C60.9271 28.0081 60.6117 27.0276 60.6031 26.0444C60.5538 20.4152 60.5771 14.7853 60.5779 9.15571C60.5779 8.99872 60.6019 8.84172 60.6189 8.63403C61.775 8.63403 62.8899 8.63403 64.1268 8.63403C64.1268 8.9919 64.1268 9.33695 64.1268 9.682C64.1269 14.9881 64.1217 20.2942 64.1349 25.6003C64.1363 26.1767 64.1774 26.7694 64.3169 27.3253C64.535 28.1947 65.1876 28.5753 66.0615 28.5572C66.9057 28.5398 67.4849 28.1185 67.6736 27.2897C67.7944 26.7597 67.8564 26.2049 67.8578 25.661C67.871 20.3549 67.8658 15.0488 67.8659 9.74273C67.8659 9.39236 67.8659 9.042 67.8659 8.6356C69.0421 8.6356 70.1551 8.6356 71.3368 8.6356C71.3513 8.95817 71.3775 9.2707 71.3777 9.58324C71.3801 14.9217 71.3794 20.2601 71.3788 25.5986C71.3788 25.8898 71.3816 26.1812 71.3701 26.4721C71.1959 30.8842 66.8266 33.3112 62.9844 31.0608C62.2521 30.6319 61.768 29.7793 61.1351 29.056Z" fill="#E3282C"/>
                  <path d="M91.1361 30.0674C92.2884 22.8761 93.4287 15.7691 94.5746 8.6272C96.2533 8.6272 97.8597 8.6272 99.5723 8.6272C100.836 16.1809 102.102 23.7501 103.379 31.3813C102.147 31.3813 100.992 31.3813 99.7127 31.3813C99.4052 29.3818 99.094 27.3578 98.7808 25.3214C97.5988 25.3214 96.5046 25.3214 95.3095 25.3214C95.0355 27.3313 94.7627 29.3326 94.484 31.3776C93.3078 31.3776 92.1913 31.3776 90.9563 31.3776C91.0151 30.948 91.0696 30.5498 91.1361 30.0674ZM97.7787 17.5706C97.5599 15.7469 97.3411 13.9233 97.1222 12.0997C97.0865 12.0971 97.0509 12.0946 97.0152 12.092C96.6005 15.3936 96.1858 18.6951 95.7677 22.0232C96.7165 22.0232 97.5081 22.0232 98.3894 22.0232C98.1846 20.549 97.9898 19.1461 97.7787 17.5706Z" fill="#E2272B"/>
                  <path d="M225.332 29.767C224.138 32.0648 221.866 32.9802 218.902 32.4061C216.708 31.9811 215.255 30.3529 215.186 27.8393C215.073 23.6707 215.124 19.4975 215.111 15.3262C215.105 13.3878 215.11 11.4494 215.11 9.44116C216.275 9.44116 217.387 9.44116 218.63 9.44116C218.63 9.78367 218.63 10.1263 218.63 10.4689C218.63 15.8049 218.627 21.1409 218.637 26.4769C218.638 26.9896 218.671 27.5147 218.785 28.012C218.993 28.9174 219.603 29.3629 220.543 29.3511C221.448 29.3397 222.01 28.8847 222.194 28.0166C222.3 27.5162 222.348 26.9946 222.349 26.4824C222.359 21.1788 222.355 15.8751 222.355 10.5715C222.355 10.2185 222.355 9.86555 222.355 9.4418C223.514 9.4418 224.626 9.4418 225.822 9.4418C225.838 9.74258 225.87 10.0528 225.87 10.363C225.873 15.7313 225.899 21.0999 225.849 26.4678C225.839 27.5476 225.538 28.6247 225.332 29.767Z" fill="#E32528"/>
                  <path d="M235.044 14.5182C235.017 13.9139 235.041 13.3894 234.958 12.8825C234.836 12.1384 234.483 11.4812 233.657 11.396C232.861 11.3137 232.335 11.7876 232.031 12.5178C231.528 13.7226 231.586 14.9419 232.096 16.0964C232.28 16.5158 232.762 16.9621 233.197 17.0894C238.147 18.5349 239.358 22.8874 238.759 27.4626C238.647 28.3178 238.414 29.1703 238.131 29.9872C237.6 31.5185 236.441 32.2948 234.855 32.4594C230.135 32.9493 228.009 31.0316 228 26.284C227.999 25.9354 228 25.5868 228 25.1715C229.247 25.1715 230.394 25.1715 231.71 25.1715C231.71 25.5915 231.65 26.0375 231.724 26.4598C231.844 27.1455 231.918 27.9 232.27 28.4641C232.524 28.8721 233.216 29.2479 233.68 29.2126C234.086 29.1817 234.649 28.6249 234.795 28.1851C235.436 26.246 235.382 24.2634 234.406 22.4589C234.064 21.8249 233.276 21.2183 232.575 21.0278C229.278 20.1324 228.12 17.8793 228.131 14.8242C228.135 13.5191 228.282 12.1546 228.688 10.924C229.424 8.69212 231.561 7.66039 234.27 7.96941C236.553 8.2298 238.278 9.89281 238.588 12.2688C238.711 13.2185 238.608 14.1978 238.608 15.3145C237.583 15.3145 236.529 15.3439 235.481 15.2829C235.324 15.2738 235.192 14.8421 235.044 14.5182Z" fill="#E32529"/>
                  <path d="M249.206 12.743C250.31 19.3121 251.395 25.7987 252.49 32.3498C251.228 32.3498 250.075 32.3498 248.823 32.3498C248.521 30.3512 248.218 28.3566 247.909 26.314C246.759 26.314 245.639 26.314 244.419 26.314C244.184 28.0542 243.908 29.8022 243.731 31.5602C243.663 32.2286 243.443 32.4478 242.785 32.4105C241.893 32.36 240.995 32.398 240.02 32.398C241.255 24.7304 242.471 17.1842 243.693 9.59595C245.365 9.59595 246.997 9.59595 248.686 9.59595C248.856 10.6351 249.021 11.6479 249.206 12.743ZM245.812 15.8016C245.497 18.1858 245.182 20.5699 244.865 22.9719C245.768 22.9719 246.581 22.9719 247.478 22.9719C247.05 19.6139 246.634 16.3443 246.218 13.0747C246.177 13.078 246.136 13.0812 246.094 13.0844C246.005 13.9323 245.916 14.7802 245.812 15.8016Z" fill="#E32529"/>
                  <path d="M208.896 30.7017C208.881 31.0452 208.866 31.2975 208.849 31.6068C207.558 31.6068 206.311 31.6068 204.933 31.6068C203.669 26.4913 202.396 21.3392 201.123 16.1872C201.095 16.1891 201.066 16.1909 201.038 16.1928C201.038 21.3158 201.038 26.4389 201.038 31.6146C199.961 31.6146 198.943 31.6146 197.871 31.6146C197.871 24.0284 197.871 16.4713 197.871 8.86499C199.199 8.86499 200.511 8.86499 201.942 8.86499C203.136 13.6894 204.335 18.5319 205.6 23.6396C205.6 18.572 205.6 13.7671 205.6 8.88915C206.701 8.88915 207.744 8.88915 208.896 8.88915C208.896 16.1383 208.896 23.3744 208.896 30.7017Z" fill="#E4262A"/>
                  <path d="M157.847 31.6679C156.949 31.6678 156.143 31.6678 155.217 31.6678C153.938 26.504 152.662 21.356 151.387 16.208C151.336 16.2226 151.285 16.2373 151.234 16.2519C151.234 21.3597 151.234 26.4674 151.234 31.6216C150.221 31.6216 149.202 31.6216 148.12 31.6216C148.12 24.0325 148.12 16.483 148.12 8.87476C149.446 8.87476 150.755 8.87476 152.203 8.87476C153.401 13.7084 154.607 18.5762 155.813 23.444C155.844 23.4416 155.876 23.4391 155.908 23.4366C155.908 18.6014 155.908 13.7662 155.908 8.87378C156.985 8.87378 158.006 8.87378 159.084 8.87378C159.084 16.4238 159.084 23.9765 159.084 31.6679C158.685 31.6679 158.312 31.6679 157.847 31.6679Z" fill="#E3262A"/>
                  <path d="M191.663 27.0616C191.661 25.9618 191.69 24.9578 191.652 23.9564C191.569 21.7995 190.902 21.2776 188.481 21.5153C188.481 24.845 188.481 28.1884 188.481 31.5989C187.26 31.5989 186.151 31.5989 184.976 31.5989C184.976 24.0319 184.976 16.4824 184.976 8.72681C187.275 8.82688 189.568 8.7928 191.824 9.06625C193.711 9.29489 194.933 10.7028 195.145 12.6043C195.289 13.9095 195.282 15.2438 195.209 16.5578C195.131 17.9807 194.531 19.1769 193.115 19.952C194.933 20.9613 195.074 22.6762 195.107 24.3938C195.152 26.7813 195.118 29.1702 195.118 31.6133C194.139 31.6133 193.054 31.6133 191.808 31.6133C191.759 30.1108 191.711 28.6347 191.663 27.0616ZM191.264 17.8162C191.451 16.7229 191.772 15.6327 191.798 14.5356C191.853 12.1904 190.895 11.4508 188.484 11.8264C188.484 14.0294 188.484 16.2396 188.484 18.4835C189.468 18.5546 190.398 18.6519 191.264 17.8162Z" fill="#E3262A"/>
                  <path d="M135.266 29.8512C134.939 28.545 134.439 27.3046 134.403 26.0511C134.294 22.2045 134.313 18.3518 134.396 14.5039C134.42 13.4033 134.657 12.2309 135.107 11.2314C136.117 8.9882 138.407 8.11798 141.385 8.62793C143.434 8.97896 144.955 10.6338 145.262 12.914C145.377 13.7755 145.448 14.6512 145.442 15.5197C145.411 19.4628 145.447 23.4105 145.255 27.3466C145.138 29.7477 143.78 31.2974 141.675 31.7302C139.278 32.2227 136.938 32.2266 135.266 29.8512ZM137.91 22.1112C137.91 22.4666 137.899 22.8224 137.912 23.1774C137.96 24.5293 137.932 25.8912 138.094 27.2295C138.227 28.3243 138.953 28.8282 139.961 28.8024C140.97 28.7766 141.574 28.2268 141.754 27.1239C141.821 26.7114 141.843 26.2876 141.844 25.8689C141.851 22.1206 141.856 18.3723 141.839 14.624C141.837 14.0809 141.78 13.5224 141.643 12.9989C141.407 12.1016 140.763 11.6987 139.837 11.7108C138.931 11.7225 138.37 12.2062 138.14 13.0252C137.985 13.576 137.926 14.1687 137.92 14.7436C137.897 17.1346 137.91 19.5259 137.91 22.1112Z" fill="#E32529"/>
                  <path d="M123.256 30.0207C122.936 28.6818 122.453 27.4059 122.419 26.118C122.314 22.1764 122.373 18.2302 122.388 14.2858C122.39 13.7078 122.464 13.1245 122.561 12.5533C122.919 10.4614 124.306 8.97854 126.199 8.62955C128.813 8.14735 130.852 8.83201 131.87 10.5346C132.537 11.6483 132.829 13.6448 132.578 15.4768C131.469 15.4768 130.353 15.4768 129.221 15.4768C129.153 14.6068 129.121 13.7739 129.013 12.9508C128.907 12.1316 128.412 11.7077 127.558 11.7087C126.691 11.7097 126.251 12.1927 126.099 12.9753C126.019 13.384 126.01 13.8109 126.009 14.2297C126.003 18.1741 126.002 22.1186 126.01 26.063C126.012 26.5457 126.025 27.0402 126.128 27.5082C126.306 28.3177 126.796 28.8292 127.69 28.7975C128.552 28.767 128.983 28.2176 129.091 27.4496C129.206 26.6243 129.204 25.7825 129.257 24.8809C130.416 24.8809 131.559 24.8809 133.006 24.8809C132.727 26.435 132.595 27.9447 132.16 29.3611C131.773 30.6188 130.73 31.3849 129.425 31.7458C126.961 32.4276 124.497 31.7678 123.256 30.0207Z" fill="#E4272B"/>
                  <path d="M168.403 13.105C168.262 12.0991 167.73 11.6609 166.798 11.7157C165.88 11.7696 165.517 12.3508 165.429 13.1812C165.392 13.5342 165.34 13.8883 165.339 14.242C165.333 18.1891 165.33 22.1364 165.343 26.0835C165.344 26.5974 165.385 27.1252 165.509 27.6214C165.689 28.3462 166.16 28.803 166.962 28.8001C167.758 28.7972 168.269 28.3802 168.39 27.6191C168.53 26.7322 168.565 25.8284 168.651 24.8726C169.751 24.8726 170.87 24.8726 172.095 24.8726C172.18 26.7567 172.215 28.649 171.027 30.2381C169.643 32.0884 167.597 32.1595 165.556 31.8592C163.094 31.4969 161.837 29.8282 161.789 26.7852C161.722 22.4509 161.749 18.1146 161.784 13.7796C161.79 13.0499 161.951 12.2932 162.187 11.5989C162.886 9.54418 164.464 8.59659 166.544 8.49792C168.502 8.40503 170.327 8.78382 171.33 10.739C172.078 12.1948 172.094 13.7875 172.022 15.4543C170.889 15.4543 169.776 15.4543 168.595 15.4543C168.536 14.7032 168.476 13.9442 168.403 13.105Z" fill="#E32529"/>
                  <path d="M182.681 12.0896C180.98 12.0985 179.373 12.0985 177.724 12.0985C177.724 14.0822 177.724 15.9732 177.724 17.9468C179.359 17.9468 180.96 17.9468 182.634 17.9468C182.634 19.0427 182.634 20.0596 182.634 21.1887C181.038 21.1887 179.411 21.1887 177.734 21.1887C177.734 23.6392 177.734 25.9794 177.734 28.3995C179.621 28.3995 181.483 28.3995 183.436 28.3995C183.316 29.3684 183.242 30.2557 183.072 31.1244C183.031 31.3363 182.679 31.64 182.466 31.6427C179.754 31.6779 177.042 31.6659 174.249 31.6659C174.249 24.0452 174.249 16.4767 174.249 8.8623C177.119 8.8623 179.981 8.8623 183.024 8.8623C182.938 9.95878 182.856 11.0197 182.681 12.0896Z" fill="#E32528"/>
                  <path d="M28.4697 1.87797C35.2444 5.29371 39.2197 10.715 39.9134 18.2235C40.9626 29.5806 32.6215 38.6092 22.528 39.8474C11.9865 41.1404 2.29903 34.0861 0.342481 23.6757C-1.69411 12.8393 5.56609 2.27356 16.4386 0.331301C20.5736 -0.407368 24.5929 0.0810717 28.4697 1.87797ZM18.4504 12.3439C16.0511 9.42112 12.9391 8.01782 9.15056 7.90623C9.15056 9.68085 9.15056 11.3665 9.15056 13.1054C11.0483 13.1838 12.7032 13.8312 14.0343 15.1992C15.3841 16.5864 16.0209 18.2586 16.0018 20.1901C15.975 22.8988 13.9656 26.6153 9.16439 26.9519C9.16439 28.6517 9.16439 30.3525 9.16439 32.1485C9.91224 32.0654 10.6224 32.0404 11.3095 31.9012C15.0445 31.1441 17.9145 29.1968 19.639 25.7249C19.7725 25.4561 19.8638 25.1345 19.8653 24.8369C19.8813 21.617 19.8793 18.397 19.8676 15.1771C19.8667 14.9172 19.8117 14.6284 19.689 14.4037C19.3197 13.727 18.9046 13.0753 18.4504 12.3439ZM26.9999 14.3559C28.1544 13.5207 29.4766 13.1884 30.8754 13.1054C30.8754 11.3627 30.8754 9.66054 30.8754 7.89072C30.2452 7.94867 29.6581 7.96208 29.086 8.0618C25.1583 8.74644 22.1591 10.7313 20.3686 14.3698C20.2454 14.6202 20.1763 14.924 20.1751 15.2034C20.1618 18.4026 20.16 21.602 20.177 24.8012C20.1787 25.1193 20.2736 25.4614 20.4124 25.7505C21.7384 28.5145 23.9408 30.3138 26.7637 31.3823C28.0732 31.8779 29.4419 32.13 30.883 32.0898C30.883 30.3387 30.883 28.6366 30.883 27.0756C29.8394 26.7689 28.8017 26.6154 27.9048 26.1751C23.1914 23.8609 22.6995 17.703 26.9999 14.3559ZM21.3034 10.9569C23.2655 10.0683 24.0471 8.06887 23.166 6.19217C22.3532 4.46101 20.2608 3.73104 18.4974 4.56339C16.8783 5.32756 16.1032 7.28195 16.7573 8.95061C17.4632 10.7516 19.2616 11.5746 21.3034 10.9569Z" fill="#E32327"/>
                  <path d="M18.479 12.3789C18.9047 13.0753 19.3198 13.727 19.6891 14.4037C19.8117 14.6284 19.8668 14.9173 19.8677 15.1771C19.8794 18.397 19.8813 21.617 19.8654 24.8369C19.8639 25.1345 19.7726 25.4561 19.6391 25.7249C17.9146 29.1968 15.0446 31.1441 11.3096 31.9012C10.6225 32.0404 9.91232 32.0654 9.16447 32.1485C9.16447 30.3525 9.16447 28.6518 9.16447 26.9519C13.9657 26.6154 15.9751 22.8988 16.0019 20.1902C16.021 18.2586 15.3842 16.5864 14.0344 15.1992C12.7033 13.8312 11.0484 13.1838 9.15063 13.1054C9.15063 11.3665 9.15063 9.68088 9.15063 7.90625C12.9392 8.01784 16.0512 9.42115 18.479 12.3789Z" fill="#FFFDFD"/>
                  <path d="M26.9623 14.381C22.6996 17.7029 23.1915 23.8608 27.9049 26.175C28.8018 26.6153 29.8395 26.7688 30.8831 27.0755C30.8831 28.6365 30.8831 30.3386 30.8831 32.0897C29.442 32.1299 28.0733 31.8778 26.7638 31.3822C23.9409 30.3137 21.7385 28.5144 20.4124 25.7504C20.2737 25.4613 20.1788 25.1192 20.1771 24.8011C20.1601 21.6019 20.1619 18.4025 20.1752 15.2033C20.1763 14.9239 20.2455 14.6201 20.3687 14.3697C22.1591 10.7312 25.1584 8.74634 29.0861 8.06171C29.6581 7.96198 30.2453 7.94858 30.8755 7.89062C30.8755 9.66045 30.8755 11.3626 30.8755 13.1054C29.4766 13.1883 28.1545 13.5206 26.9623 14.381Z" fill="#FFFDFD"/>
                  <path d="M21.2565 10.9724C19.2617 11.5745 17.4633 10.7514 16.7574 8.95049C16.1033 7.28183 16.8784 5.32744 18.4975 4.56327C20.2609 3.73092 22.3533 4.46089 23.1661 6.19206C24.0472 8.06875 23.2656 10.0682 21.2565 10.9724Z" fill="#FFFDFD"/>
                </svg>
              </Link>
            </div>
            <div className="sidebar-body">
              <Sidebar/>
            </div>
          </nav>
          <div className="page-wrapper">
            <nav className="navbar">
              <div className="navbar-content">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <Link onClick={handleSignout} className="btn-primary-fill pill-btn mx-1">Log out</Link>
                    </a>
                    <div className="dropdown-menu p-0" aria-labelledby="profileDropdown">
                      <div className="d-flex flex-column align-items-center border-bottom px-5 py-3">
                        <div className="mb-3">
                          <img className="wd-80 ht-80 rounded-circle" src="https://via.placeholder.com/80x80" alt=""/>
                        </div>
                        <div className="text-center">
                          <p className="tx-16 fw-bolder mb-0">{userData.email}</p>
                          <p className="tx-12 text-muted mb-0">{userData.email}</p>
                          <p className="tx-12 text-muted mb-0">Last Login: {formatDate(userData.lastLoginAt)}</p>
                        </div>
                      </div>
                      <ul className="list-unstyled p-1">
                        <li className="dropdown-item py-2">
                          <a href="pages/general/profile.html" className="text-body ms-0">
                            <FaUser className="me-2 icon-md"/>
                            <span>Profile</span>
                          </a>
                        </li>
                        <li className="dropdown-item py-2">
                          <a href="javascript:;" className="text-body ms-0">
                            <FaEdit className="me-2 icon-md"/>
                            <span>Edit Profile</span>
                          </a>
                        </li>
                        <li className="dropdown-item py-2">
                          <Signout/>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="page-content">
              <Outlet/>
            </div>
            <DashFooter/>
          </div>
        </div>
    </>
  )
}
