import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import DataTable from '../DataTable';



export default function UserList() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        // Fetch users from Firebase
        getDataFromFirebase('users', (usersArray) => {
            setUsers(usersArray.map(user => ({ ...user, isActive: user.isActive !== false }))); // By default, everyone is active
        });

        // Fetch roles from Firebase
        getDataFromFirebase('roles', (rolesArray) => {
            setRoles(rolesArray);
        });
    }, []);

    const handleRoleChange = async (userId, newRole) => {
        try {
            // Update the user's role in Firebase
            await updateFieldsInFirebase(`users/${userId}`, { role: newRole });
            // Update the local state
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.userId === userId ? { ...user, role: newRole } : user
                )
            );
            alert('Role updated successfully!');
        } catch (error) {
            console.error('Error updating role:', error);
            alert('Failed to update role.');
        }
    };

    const handleActivationChange = async (userId, isActive) => {
        try {
            // Update the user's activation status in Firebase
            await updateFieldsInFirebase(`users/${userId}`, { isActive });
            // Update the local state
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.userId === userId ? { ...user, isActive } : user
                )
            );
            alert(`User ${isActive ? 'activated' : 'deactivated'} successfully!`);
        } catch (error) {
            console.error('Error updating activation status:', error);
            alert('Failed to update activation status.');
        }
    };

    const exportToExcel = () => {
        const userDataToExport = users.map((user) => ({
            'First Name': user.firstName,
            'Last Name': user.lastName,
            Email: user.email,
            'Phone Number': user.phoneNumber,
            Role: user.role || 'N/A',
            Status: user.isActive ? 'Active' : 'Inactive',
        }));

        const worksheet = XLSX.utils.json_to_sheet(userDataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'user_list.xlsx');
    };

    const columns = [
        {
            Header: "First Name",
            accessor: "firstName"
        },
        {
            Header: "Last Name",
            accessor: "lastName"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Phone#",
            accessor: "phone"
        },
        {
            Header: "Role",
            accessor: "role"
        }

    ];

    const handleRow = (user) => {
        navigate(`/dashboard/donor-details/${user.id}/${user.email}`)
    }

    return (
        <>
            <Row>
                <Col>
                    <div >
                        <div className='d-flex '>
                            <h4 >User List</h4>
                            <Button  style={{marginLeft:"auto"}} onClick={exportToExcel}>Export to Excel</Button>
                        </div>
                    </div>
                    <div >
                        <Button  onClick={() => { navigate(`/dashboard/donor-details`) }}>Add User</Button>
                    </div>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable data={users} columns={columns} onRowClick={handleRow}></DataTable>
                </Col>
            </Row>
        </>
    );
}
