import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "../DataTable";
import {
  getSubscriptionFromFirebase,
  getSpecificUser,
  getDataFromFirebaseReturnConst,
} from "../../firebase/firebaseFunctions";
import { useAuth } from "../Auth/AuthContext";
import { fetchStripeSubscription } from "../Utils/fetchStripeSubscription";
import { Link, useNavigate } from "react-router-dom";
import { fetchPaypalSubscription } from "../Utils/fetchPaypalSubscription";
import * as XLSX from "xlsx";

export default function Subscription() {
  const [subscriptionsWithUsers, setSubscriptionsWithUsers] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (auth.currentUser?.role === "admin") {
        getSubscriptionFromFirebase(
          "subscription",
          async (subscriptionsArray) => {
            const updatedSubscriptions = await Promise.all(
              subscriptionsArray.map(async (subscription) => {
                const userData = await getSpecificUser(subscription.userId);
                const appeal = await getDataFromFirebaseReturnConst(
                  `appeals/${subscription.appealId}`
                );
                console.log("appeal", subscription);
                let paymentData;
                let nextPaymentDate;

                if (subscription.paymentGateWay === "stripe") {
                  paymentData = await fetchStripeSubscription(
                    subscription.subscriptionId
                  );
                  const nextPayment = new Date(
                    paymentData.currentPeriodEnd * 1000
                  );

                  // Extract the date components
                  const year = nextPayment.getUTCFullYear();
                  const month = String(nextPayment.getUTCMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Months are zero-based
                  const day = String(nextPayment.getUTCDate()).padStart(2, "0");

                  nextPaymentDate = `${year}-${month}-${day}`;
                } else if (subscription.paymentGateWay === "paypal") {
                  paymentData = await fetchPaypalSubscription(
                    subscription.subscriptionId
                  );

                  const last_payment_date =
                    paymentData.agreement_details.next_billing_date ||
                    paymentData.agreement_details.last_payment_date;
                  const { roundDuration, roundCount } =
                    subscription.billingCycle;
                  const lastPayment = new Date(last_payment_date);
                  nextPaymentDate = new Date(lastPayment); // Copy to avoid modifying the original

                  if (isNaN(nextPaymentDate.getTime())) {
                    console.error(
                      "Invalid last_payment_date:",
                      last_payment_date
                    );
                  } else {
                    // Add the duration to the last payment date
                    switch (roundDuration) {
                      case "day":
                        nextPaymentDate.setDate(
                          nextPaymentDate.getDate() + parseInt(roundCount)
                        );
                        break;
                      case "week":
                        nextPaymentDate.setDate(
                          nextPaymentDate.getDate() + parseInt(roundCount) * 7
                        );
                        break;
                      case "month":
                        nextPaymentDate.setMonth(
                          nextPaymentDate.getMonth() + parseInt(roundCount)
                        );
                        break;
                      case "year":
                        nextPaymentDate.setFullYear(
                          nextPaymentDate.getFullYear() + parseInt(roundCount)
                        );
                        break;
                      default:
                        throw new Error("Invalid roundDuration value");
                    }
                  }
                  nextPaymentDate = nextPaymentDate.toISOString().split("T")[0];
                }

                return {
                  ...subscription,
                  user: userData,
                  appealName: appeal.title,
                  paymentGateway: subscription.paymentGateWay
                    ? subscription.paymentGateWay.toUpperCase()
                    : "",
                  date: subscription.date,
                  time: subscription.time,
                  nextPaymentDate,
                  subscriptionEndDate: subscription.subscritionEndDate,
                };
              })
            );
            setSubscriptionsWithUsers(updatedSubscriptions);
          }
        );
      } else if (auth?.currentUser?.role === "user") {
        getSubscriptionFromFirebase(
          "subscription", // Fetch all subscriptions
          async (subscriptionsArray) => {
            const userSubscriptions = subscriptionsArray.filter(
              (subscription) => subscription.userId === auth.currentUser.uid
            );
            
            

            const updatedSubscriptions = await Promise.all(
              userSubscriptions.map(async (subscription) => {
                const appeal = await getDataFromFirebaseReturnConst(
                  `appeals/${subscription.appealId}`
                );
                let paymentData;
                let nextPaymentDate;

                if (subscription.paymentGateWay === "stripe") {
                  paymentData = await fetchStripeSubscription(
                    subscription.subscriptionId
                  );
                  const nextPayment = new Date(
                    paymentData.currentPeriodEnd * 1000
                  );

                  // Extract the date components
                  const year = nextPayment.getUTCFullYear();
                  const month = String(nextPayment.getUTCMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Months are zero-based
                  const day = String(nextPayment.getUTCDate()).padStart(2, "0");

                  nextPaymentDate = `${year}-${month}-${day}`;
                } else if (subscription.paymentGateWay === "paypal") {
                  paymentData = await fetchPaypalSubscription(
                    subscription.subscriptionId
                  );

                  const last_payment_date =
                    paymentData.agreement_details.next_billing_date ||
                    paymentData.agreement_details.last_payment_date;
                  const { roundDuration, roundCount } =
                    subscription.billingCycle;
                  const lastPayment = new Date(last_payment_date);
                  nextPaymentDate = new Date(lastPayment); // Copy to avoid modifying the original

                  if (isNaN(nextPaymentDate.getTime())) {
                    console.error(
                      "Invalid last_payment_date:",
                      last_payment_date
                    );
                  } else {
                    // Add the duration to the last payment date
                    switch (roundDuration) {
                      case "day":
                        nextPaymentDate.setDate(
                          nextPaymentDate.getDate() + parseInt(roundCount)
                        );
                        break;
                      case "week":
                        nextPaymentDate.setDate(
                          nextPaymentDate.getDate() + parseInt(roundCount) * 7
                        );
                        break;
                      case "month":
                        nextPaymentDate.setMonth(
                          nextPaymentDate.getMonth() + parseInt(roundCount)
                        );
                        break;
                      case "year":
                        nextPaymentDate.setFullYear(
                          nextPaymentDate.getFullYear() + parseInt(roundCount)
                        );
                        break;
                      default:
                        throw new Error("Invalid roundDuration value");
                    }
                  }
                  nextPaymentDate = nextPaymentDate.toISOString().split("T")[0];
                }

                const userData = await getSpecificUser(subscription.userId);
                return {
                  ...subscription,
                  user: userData,
                  appealName: appeal?.title,
                  paymentGateway: subscription.paymentGateWay,
                  subscriptionEndDate: subscription.subscritionEndDate,
                  nextPaymentDate

                };
              })
            );
            setSubscriptionsWithUsers((updatedSubscriptions));
            console.log(
              "Updated Subscriptions for User:",
              updatedSubscriptions
            );
          }
        );
      }
    };

    fetchSubscriptions();
  }, [auth?.currentUser?.role]);

  const columns = [
    {
      Header: "Payment-Gateway",
      accessor: "paymentGateway",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Time",
      accessor: "time",
    },
    {
      Header: "Next Payment Date",
      accessor: "nextPaymentDate",
    },
    {
      Header: "Billing Cycle",
      accessor: (row) =>
        `${row.billingCycle?.roundCount || ""} ${
          row.billingCycle?.roundDuration || ""
        }`,
    },
    {
      Header: "Subscription End Date",
      accessor: "subscriptionEndDate",
    },
    {
      Header: "User Name",
      accessor: (row) =>
        `${row.user?.firstName || ""} ${row.user?.lastName || ""}`,
    },
    {
      Header: "User Email",
      accessor: "user.email",
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}`, // Format as currency
    },
    {
      Header: "Appeal Name",
      accessor: "appealName",
    },
    {
      Header: "Status",
      accessor: "Status",
    },
  ];

  const handleRowClick = (subscription) => {
    
    navigate(`/dashboard/subscription/detail/${subscription.id||subscription.subscriptionId}`);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(subscriptionsWithUsers);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Subs");
    XLSX.writeFile(wb, `subscriptions.xlsx`);
  };

  return (
    <Row>
      <Col>
        <div className="d-flex">
          <h4>Subscription List</h4>
          <Button
            onClick={() => exportToExcel()}
            style={{ marginLeft: "auto" }}
          >
            Export to Excel
          </Button>
        </div>
        <hr />
        <DataTable
          data={subscriptionsWithUsers}
          columns={columns}
          onRowClick={handleRowClick}
        ></DataTable>
      </Col>
    </Row>
  );
}
