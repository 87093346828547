import React from 'react'
import { Col, Row } from 'react-bootstrap'


export default function EmailVerifiation() {
  return (
    <>
    <section className="hero-area">
      <div className="single-slider hero-padding">
        <div className="container">
          <Row>
            <Col className="d-flex flex-column text-center justify-content-center align-items-center">
              <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
              A verification email has been sent to your email address. Please verify your email before signing in.</h1>
            </Col>
          </Row>
        </div>
      </div>
    </section>
    </>
  )
}
