import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { fetchPaypalSubscription } from "../Utils/fetchPaypalSubscription";
import { toast } from "react-toastify";

import {
  getDataFromFirebaseReturnConst,
  updateSubscriptionStatus,
} from "../../firebase/firebaseFunctions";
import {
  fetchStripeSubscription,
  stripe,
} from "../Utils/fetchStripeSubscription";
import { accessToken } from "../Utils/fetchPaypalSubscription";
import { useAuth } from "../Auth/AuthContext";
export default function SubscriptionDetail() {

  const auth = useAuth();
  const { subId } = useParams();
  const [subsciption, setSubscription] = useState(null);
  const [status, setStatus] = useState(null);
  console.log("subsciption", subsciption);
  console.log("subsciption.status", subsciption?.Status);

  const cancelSubscription = async (subscriptions) => {
    try {
      console.log("Cancelling subscription for ID:", subId);

      console.log("Payment Gateway:", subscriptions.paymentGateWay);

      let response;
      if (subscriptions.paymentGateWay === "paypal") {
        response = fetch(
          `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscriptions.subscriptionId}/cancel`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ reason: "Not satisfied with the service" }),
          }
        );
        console.log("Paypal cancellation response:", response);
        await updateSubscriptionStatus(subId, "cancelled");
        toast.success("Subscription cancelled successfully");
        setStatus("cancelled");
        // Add your PayPal cancellation logic here
        console.log("Handling PayPal cancellation (not implemented)");
      } else if (subscriptions.paymentGateWay === "stripe") {
        // Check if the subscription exists before attempting to cancel
        const subscriptionData = await stripe.subscriptions.retrieve(
          subscriptions.subscriptionId
        );
        console.log("Subscription found:", subscriptionData);

        response = await stripe.subscriptions.cancel(
          subscriptions.subscriptionId
        );
        await updateSubscriptionStatus(subId, "cancelled");
        console.log("Stripe cancellation response:", response);
        toast.success("Subscription cancelled successfully");
        setStatus("cancelled");
      }
      console.log(
        "---------------------------------- response --------------------------------",
        response.ok
      );
    } catch (error) {
      toast.error("Subscription cancellation is failed");
      console.error("Error cancelling subscription:", error);
      // Optionally add user feedback here, e.g., showing an error message
    }
  };
useEffect(() => {
  const isverify = localStorage.getItem("isVerified");

  if (isverify=="false") {
    toast.warning("Account is not verified")
    return <Navigate to="/login" />;
  }
  if (!auth) {
    return <Navigate to="/login" />;
  }
},[])
  const fetchSubscriptions = async () => {
    const sub = await getDataFromFirebaseReturnConst(`subscription/${subId}`);
    let subDetail;
    if (sub.paymentGateWay === "stripe") {
      subDetail = await fetchStripeSubscription(sub.subscriptionId);
      subDetail = {
        ...subDetail,
        nextBillingDate: new Date(subDetail.currentPeriodEnd * 1000)
          .toISOString()
          .split("T")[0],
      };
    } else if (sub.paymentGateWay === "paypal") {
      subDetail = await fetchPaypalSubscription(sub.subscriptionId);
      subDetail = {
        ...subDetail,
        nextBillingDate:
          subDetail.agreement_details.next_billing_date.split("T")[0],
      };
    }
    setStatus(subDetail.status);
    const appeal = await getDataFromFirebaseReturnConst(
      `appeals/${sub.appealId}`
    );
    const user = await getDataFromFirebaseReturnConst(`users/${sub.userId}`);
    setSubscription({
      ...sub,
      ...subDetail,
      ...appeal,
      ...user,
      billingCycle: sub.billingCycle,
    });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  if (!subsciption) {
    return <></>;
  }

  return (
    <div>
      <Row>
        <Col xs="12" md="6">
          <h4 className="mb-3 mb-md-0">Subsciption Details</h4>
        </Col>
        {status === "active" && (
          <Col xs="12" md="6" className="text-end">
            <button
              className="btn btn-danger text-capitalize"
              onClick={(e) => {
                e.stopPropagation();
                cancelSubscription(subsciption);
              }}
            >
              {`Cancel ${subsciption.paymentGateWay} subscription`}
            </button>
          </Col>
        )}
      </Row>
      <hr/>
      <Row>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Amount</h6>
              <h3 className="text-red fw-bold">${subsciption.amount}</h3>
              <p className="mb-0">
                This is the amount that will be charged per cycle
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Subscription Start Date</h6>
              <h3 className="text-red fw-bold">
                {subsciption.createdAt.split("T")[0]}
              </h3>
              <p className="mb-0">
                No Transactions will be made after this date
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Subscription End Date</h6>
              <h3 className="text-red fw-bold">
                {subsciption.subscritionEndDate}
              </h3>
              <p className="mb-0">
                No Transactions will be made after this date
              </p>
            </Card.Body>
          </Card>
        </Col>
        {status === "active" && (
          <Col xs="12" md="4" className="mb-4">
            <Card>
              <Card.Body>
                <h6>Next Payment Date</h6>
                <h3 className="text-red fw-bold">
                  {subsciption.nextBillingDate}
                </h3>
                <p className="mb-0">Next Payment Date in the Billing Cycle</p>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Billing Cycle</h6>
              {/* Accessing roundCount and roundDuration explicitly */}
              <h3 className="text-red fw-bold">
                {`${subsciption.billingCycle.roundCount} ${subsciption.billingCycle.roundDuration}`}
              </h3>
              <p className="mb-0">
                Amount will be deducted after this Period Periodically
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Appeal Title</h6>
              <h3 className="text-red fw-bold">{subsciption.title}</h3>
              <p className="mb-0">
                The Appeal Title for which this Subscription is done
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>User Name</h6>
              <h3 className="text-red fw-bold">
                {`${subsciption.firstName} ${subsciption.lastName}`}
              </h3>
              <p className="mb-0">The Person Name who have Subscribed</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>User Email</h6>
              <h3 className="text-red fw-bold">{subsciption.email}</h3>
              <p className="mb-0">The Person Email who have Subscribed</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Payment Gateway</h6>
              <h3 className="text-red fw-bold text-capitalize">{subsciption.paymentGateWay}</h3>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Status</h6>
              <h3 className="text-red fw-bold text-capitalize">{status}</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
