import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DataTable from '../DataTable';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { useNavigate } from 'react-router-dom';

export default function AdminEvents() {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('events', (events) => {
            console.log('events:', events);
            setEvents(events);
        });
    }, []);

    const handleRow = (event) => {
        console.log('on event click', event);
        navigate(`/dashboard/event/${event.id}`)
    }


    const columns = [
        {
            Header: 'Event Title',
            accessor: 'title', // accessor is the "key" in the data
        },
        {
            Header: 'Event Description',
            accessor: 'description', // accessor is the "key" in the data
        },
        {
            Header: 'Booking Url',
            accessor: 'bookingUrl', // accessor is the "key" in the data
        },
        {
            Header: 'Event Date',
            accessor: 'date', // accessor is the "key" in the data
        },
        {
            Header: 'Event Time',
            accessor: 'time', // accessor is the "key" in the data
        },
    ];

    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="mb-0">Events</h4>
                        <Button style={{ textAlignLast: "end" }} onClick={() => { navigate('/dashboard/event') }}>Add Event</Button>
                    </div>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable columns={columns} data={events} onRowClick={handleRow} />
                </Col>
            </Row>
        </>
    )
}
