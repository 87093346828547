import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DataTable from '../DataTable';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { useNavigate } from 'react-router-dom';

export default function Groups() {
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('groups', (groups) => {
            const updatedGroups = groups.map(group=>({...group,memebersLength:group.members.length,members:group.members.join(',') }));
            console.log('updated groups:', updatedGroups);
            setGroups(updatedGroups);
        });
    }, []);

    const handleRow =(group) => {
        console.log('on group click', group);
        navigate(`/dashboard/group/${group.id}`)
    }


    const columns = [
        {
            Header: 'Group Name',
            accessor: 'name', // accessor is the "key" in the data
        },
        {
            Header: 'Group Members',
            accessor: 'members', // accessor is the "key" in the data
        },
        {
            Header: 'Group Members',
            accessor: 'memebersLength', // accessor is the "key" in the data
        },
    ];

    return (
    <>
    <Row>
        <Col>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className="mb-0">Groups</h4>
            </div>
            <hr/>
        </Col>
    </Row>
    <Row>
        <Col>
            <DataTable columns={columns} data={groups} onRowClick={handleRow} />
        </Col>
    </Row>
    </>
    )
}
