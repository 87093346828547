import React, { useEffect, useState } from 'react';
import { getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Row, Col, Button } from 'react-bootstrap';

const UpdateGroup = () => {
    const [group, setGroup] = useState({ name: "", selectedUsers: [] });
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('groups', (groups) => {
            setGroups(groups);
        });
        getDataFromFirebase('users', (users) => {

            const updatedUsers = users.map(user => {
                return {
                    value: user.firstName + ' ' + user.lastName,
                    label: user.firstName + ' ' + user.lastName,
                }
            });
            console.log('users:', updatedUsers);
            setUsers(updatedUsers);
        });
    }, [params]);

    useEffect(() => {
        if (groups.length > 0) {
            const requiredGroup = groups.find(group => group.id === params.id);
            if (requiredGroup) {
                setGroup({ name: requiredGroup.name, selectedUsers: requiredGroup.members })
            }
        }
    }, [groups])

    useEffect(() => {
        console.log('group:', group)
    }, [group]);

    const updateGroup = async () => {
        console.log('selected users', group.selectedUsers);
        let members =[];
        if(group.selectedUsers[0].value) {
            members = group.selectedUsers.map(user=>user.value)
        }
        else {
            members =group.selectedUsers;
        }
        
        console.log('memebers:', members)
        const groupObject ={
            name:group.name,
            members:members
        }
        console.log('group object:', groupObject);
        await updateFieldsInFirebase(`groups/${params.id}`, groupObject);
        navigate('/dashboard/groups');
    }

    const handleChange =(value) => {
        console.log('multi select value', value);
        setGroup({...group,selectedUsers:value})
    }

    const getDefaultValues = () => {
        console.log('selected users:', group.selectedUsers);
        const defaultValues = group.selectedUsers.map(user=>{
            return {
                label:user,
                value:user,
            }
        })
        console.log('default values===>',defaultValues);
        return defaultValues
    }

    return (
        <>
            <section className="hero-area">
                <div className="single-slider hero-padding">
                    {group.name && group.selectedUsers && 
                    <div className="container authContainer">
                        <Row>
                            <Col xs={12}>
                                <h3>Update Group</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <input className='form-control' type='text' name='name' value={group.name} onChange={(event)=>setGroup({...group, name:event.target.value})}></input>
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col xs={12}>
                                <Select
                                    defaultValue={ getDefaultValues()}
                                    isMulti
                                    name="colors"
                                    options={users}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className='mt-4'>
                            <Col xs={12}>
                                <div style={{textAlign:"center"}}>
                                    <Button onClick={updateGroup}>Update Group</Button>
                                    <Button style={{marginLeft:"15px"}} onClick={()=>navigate('/dashboard/groups')}>Cancel</Button>

                                </div>
                            </Col>

                        </Row>
                    </div>
                    }
                </div >
            </section >
        </>
    )
}
export default UpdateGroup;