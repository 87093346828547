import React, { useEffect } from 'react';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Volunteers() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://form.jotform.com/jsform/242626518846061";
    script.async = true;
  
    const jotformContainer = document.querySelector('.jotform-container');
    
    if (jotformContainer) {
      jotformContainer.appendChild(script);
    }
  
    return () => {
      // Safely check if the container and script exist before trying to remove the script
      const container = document.querySelector('.jotform-container');
      if (container && script) {
        container.removeChild(script);
      }
    };
  }, []);
  

  return (
    <>
      <section className="mt-4 p-2">
        <div className="single-slider">
          <div className="container">
            <div
              className="row justify-content-between inner-hero"
              style={{ backgroundImage: "url('/images/volunteer.webp')" }}
            >
              <div className="col-12">
                <h3
                  className="font-700 mb-0 wow fadeInUp"
                  data-wow-delay="0.0s"
                >
                  Volunteer
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-6 col-xl-6 col-lg-6 my-auto">
                <div>
                  <h4 className="key-title font-700 mb-10 mt-20 wow fadeInUp" data-wow-delay="0.0s">Volunteer With Us</h4>
                  <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">Make A Difference, Find <span className='text-red'>Purpose</span>: Volunteer With Human Concern USA</h1>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                    Join Human Concern USA as a volunteer and play a vital role in our global mission to create positive change in underserved communities.
                  </p>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                    At Human Concern USA, we are committed to your growth and empowerment. Our innovative fundraising strategies and personalized one-on-one training with experienced fundraisers and professional coaches are designed to help you reach your full potential.
                  </p>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                    <b>We deeply value you – your time, effort, and dedication are vital to who we are.</b>
                  </p>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                    As a volunteer with Human Concern USA, you’re not just participating in our projects; you’re a crucial part of our identity and success. Sign up today to embark on this transformative journey.
                  </p>
                </div>
              </div>
              <div className="col-xxl-5 col-xl-5 col-lg-5">
                <div className="d-flex gap-44">
                  <img src="https://humanconcern.org/wp-content/uploads/2024/07/Volunter-2-scaled.webp" alt="img" className="w-100 tilt-effect wow fadeInUp imgRounded" data-wow-delay="0.1s" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-12 col-xl-12 col-lg-12 my-auto">
                <div className="jotform-container"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
