import React, { useState } from 'react';

export default function ZakatCalculator() {
  const [values, setValues] = useState({
    cash_value: 0,
    gold_value: 0,
    silver_value: 0,
    business_value: 0,
    share_assets_value: 0,
    RRSP_value: 0,
    RESP_value: 0,
    property_value: 0,
    cash_investment: 0,
    debit_own_value: 0,
    liabilities_value: 0,
    business2_value: 0,
  });

  const goldNisab = 7251; // Gold Nisab value in dollars
  const goldNisabWeight = 87.48; // Nisab weight for gold in grams
  const silverNisab = 557.25; // Silver Nisab value in dollars
  const silverNisabWeight = 612.36; // Nisab weight for silver in grams

  // Calculate price per gram for gold and silver based on Nisab
  const goldPricePerGram = goldNisab / goldNisabWeight;
  const silverPricePerGram = silverNisab / silverNisabWeight;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value ? parseFloat(value) : 0,
    });
  };

  const calculateZakat = () => {
    const totalAssets =
      values.cash_value +
      values.gold_value * goldPricePerGram +
      values.silver_value * silverPricePerGram +
      values.business_value +
      values.share_assets_value +
      values.RRSP_value +
      values.RESP_value +
      values.property_value +
      values.cash_investment +
      values.debit_own_value;
  
    const totalLiabilities = values.liabilities_value + values.business2_value;
  
    // Ensure Zakatable Amount never goes below zero
    const zakatableAmount = Math.max(totalAssets - totalLiabilities, 0);
    const zakatDue = zakatableAmount > 0 ? zakatableAmount / 40 : 0; // 2.5% Zakat
  
    return {
      totalAssets,
      totalLiabilities,
      zakatableAmount,
      zakatDue,
    };
  };
  

  const { totalAssets, totalLiabilities, zakatableAmount, zakatDue } =
    calculateZakat();

  return (
    <>
      <section className="mt-4 p-2">
        <div className="single-slider">
          <div className="container">
            <div
              className="row justify-content-between inner-hero"
              style={{ backgroundImage: "url('/images/volunteer.webp')" }}
            >
              <div className="col-12">
                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">
                  Zakat Calculator
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-area">
        <div className="single-slider mt-4 pt-4 mb-4">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">
                  Calculate & Measure Your <span className="text-red">Impact</span>
                </h1>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  The Zakat is the third pillar of Islam, and is obligatory upon every Muslim who
                  meets certain conditions.
                </p>
                <p>Current Nisab Value as of: <b>March 10th 2023</b></p>
                <p>Gold Nisab: <b className='text-red'>${goldNisab}</b> ({goldNisabWeight}g)</p>
                <p>Silver Nisab: <b className='text-red'>${silverNisab}</b> ({silverNisabWeight}g)</p>
                <p>Gold Price per gram: <b className='text-red'>${goldPricePerGram.toFixed(2)}</b></p>
                <p>Silver Price per gram: <b className='text-red'>${silverPricePerGram.toFixed(2)}</b></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-area">
        <div className="single-slider padding-bottom mb-4">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-12 col-xl-12 col-lg-12 my-auto">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="cash_value" className="form-label">Cash at Home/Bank</label>
                        <input
                          type="number"
                          className="form-control"
                          id="cash_value"
                          name="cash_value"
                          value={values.cash_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="gold_value" className="form-label">Gold (grams)</label>
                        <input
                          type="number"
                          className="form-control"
                          id="gold_value"
                          name="gold_value"
                          value={values.gold_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="silver_value" className="form-label">Silver (grams)</label>
                        <input
                          type="number"
                          className="form-control"
                          id="silver_value"
                          name="silver_value"
                          value={values.silver_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="business_value" className="form-label">Business Assets</label>
                        <input
                          type="number"
                          className="form-control"
                          id="business_value"
                          name="business_value"
                          value={values.business_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="share_assets_value" className="form-label">Share Assets</label>
                        <input
                          type="number"
                          className="form-control"
                          id="share_assets_value"
                          name="share_assets_value"
                          value={values.share_assets_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="RRSP_value" className="form-label">RRSP Assets</label>
                        <input
                          type="number"
                          className="form-control"
                          id="RRSP_value"
                          name="RRSP_value"
                          value={values.RRSP_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="RESP_value" className="form-label">RESP Assets</label>
                        <input
                          type="number"
                          className="form-control"
                          id="RESP_value"
                          name="RESP_value"
                          value={values.RESP_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="property_value" className="form-label">Property</label>
                        <input
                          type="number"
                          className="form-control"
                          id="property_value"
                          name="property_value"
                          value={values.property_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="cash_investment" className="form-label">Cash Investments</label>
                        <input
                          type="number"
                          className="form-control"
                          id="cash_investment"
                          name="cash_investment"
                          value={values.cash_investment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="debit_own_value" className="form-label">Debts Owed to You</label>
                        <input
                          type="number"
                          className="form-control"
                          id="debit_own_value"
                          name="debit_own_value"
                          value={values.debit_own_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="liabilities_value" className="form-label">Liabilities (Personal)</label>
                        <input
                          type="number"
                          className="form-control"
                          id="liabilities_value"
                          name="liabilities_value"
                          value={values.liabilities_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label htmlFor="business2_value" className="form-label">Liabilities (Business)</label>
                        <input
                          type="number"
                          className="form-control"
                          id="business2_value"
                          name="business2_value"
                          value={values.business2_value}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 d-flex mobile-justify">
                    <h5 className="font-700 wow fadeInUp">
                      Total Assets: ${totalAssets.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                    <p className='mobileNone'>----</p>
                    <h5 className="font-700 wow fadeInUp">
                      Total Liabilities: ${totalLiabilities.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                    <p className='mobileNone'>----</p>
                    <h5 className="font-700 wow fadeInUp">
                      Total Zakatable Amount: ${zakatableAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                    <p className='mobileNone'>----</p>
                    <h5 className="font-700 wow fadeInUp">
                      Total Zakat Due: ${zakatDue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h5>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
