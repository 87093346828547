import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import DataTable from "../DataTable";
import { useEffect, useState } from "react";
import {
  addDataToFirebase,
  getDataFromFirebase,
} from "../../firebase/firebaseFunctions";
import { useAuth } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddTicket() {
  const [ticketForm, setTicketForm] = useState({
    subject: "",
    priority: "",
    department: "",
    message: "",
    submitter: "",
  });
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const priorities = ["Low", "Medium", "High"];
  const departments = ["Sales", "Technical", "Biling", "Admin"];
  const users = [
    "Hasnain Ahmed",
    "Abdul Manan",
    "Ahsan Saleem",
    "Hasnain Khan",
  ];
  const auth = useAuth();
  const columns = [
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Priority",
      accessor: "priority",
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Submitter",
      accessor: "submitter",
    },
  ];

  useEffect(() => {
    console.log("T bjashf ", auth.currentUser);
    getDataFromFirebase("tickets", (allTickets) => {
      console.log("all", allTickets);
      const filteredTickets = allTickets.filter(
        (ticket) => ticket.submitterId === auth.currentUser.uid
      );
      setTickets(filteredTickets);
    });
  }, []);

  useEffect(() => {
    console.log("|| tickets", tickets);
  }, [tickets]);

  const createTicket = async () => {
    const { subject, priority, department } = ticketForm;
    if (subject && priority && department) {
      setError("");
      const ticketData = {
        subject: ticketForm.subject,
        priority: ticketForm.priority,
        department: ticketForm.department,
        submitter: auth.currentUser.displayName,
        status: "unanswered",
        submitterId: auth.currentUser.uid,
        timestamp: new Date().toISOString(),
      };
      console.log("ticketData", ticketData);

      const res = await addDataToFirebase("tickets", ticketData);
      const ticketThreadData = {
        message: ticketForm.message,
        senderEmail: auth.currentUser.email,
        senderName: `${auth.currentUser.firstName} ${auth.currentUser.lastName}`,
        ticketId: res.key,
        timestamp: new Date().toISOString(),
      };
      await addDataToFirebase("ticket-threads", ticketThreadData);
      setTicketForm({
        subject: "",
        priority: "",
        department: "",
        submitter: "",
        message: "",
      });
      toast.success("Ticket Added Successfully");
    } else {
      toast.error("Please fill all fields");
      setError("Please fill all fields");
    }
  };

  useEffect(() => {
    if (ticketForm) {
      console.log("ticket state:", ticketForm);
    }
  }, [ticketForm]);

  const handleRow = (ticket) => {
    console.log("ticket", ticket);
    navigate(`/dashboard/ticket/${ticket.id}`);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Row className="mb-2">
                <Col>
                  <h5>Create New Ticket</h5>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label className="form-label">Subject</label>
                  <input
                    className="form-control"
                    type="text"
                    name="subject"
                    value={ticketForm.subject}
                    onChange={(e) =>
                      setTicketForm({
                        ...ticketForm,
                        [e.target.name]: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
                <Col>
                  <label className="form-label">Priority</label>
                  <select
                    name="priority"
                    value={ticketForm.priority}
                    id="cars"
                    className="form-control"
                    style={{ width: "100%" }}
                    onChange={(event) =>
                      setTicketForm({
                        ...ticketForm,
                        priority: event.target.value,
                      })
                    }
                  >
                    <option></option>
                    {priorities.map((priority) => {
                      return <option>{priority}</option>;
                    })}
                  </select>
                </Col>
                <Col>
                  <label className="form-label">Deparment</label>
                  <div>
                    <select
                      name="department"
                      value={ticketForm.department}
                      id="cars"
                      className="form-control"
                      style={{ width: "100%" }}
                      onChange={(event) =>
                        setTicketForm({
                          ...ticketForm,
                          department: event.target.value,
                        })
                      }
                    >
                      <option></option>
                      {departments.map((department) => {
                        return <option>{department}</option>;
                      })}
                    </select>
                  </div>
                </Col>
              </Row>
              {error && (
                <Row className="mt-2">
                  <span style={{ color: "red" }}>{error}</span>
                </Row>
              )}
              <Row>
                <Col>
                  <label className="form-label">Message</label>
                  <input
                    className="form-control"
                    type="text"
                    name="message"
                    value={ticketForm.message}
                    onChange={(e) =>
                      setTicketForm({
                        ...ticketForm,
                        [e.target.name]: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mt-2">
                  <Button onClick={createTicket}>Create Ticket</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Row className="mt-3">
          <Col sm={12}>
            <div>
              <DataTable
                columns={columns}
                data={tickets}
                onRowClick={handleRow}
              />
            </div>
          </Col>
        </Row>
      </Row>
    </div>
  );
}
