import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { useFormData } from "./FormDataContext";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import {
  addDataToFirebase,
  addPresetsToFirebase,
  deletePresetsFromFirebase,
  getCheckoutPresetsFromFirebase,
} from "../../firebase/firebaseFunctions";
import Loading from "../Layouts/Loading";
import {
  activateBillingPlan,
  createPayPalBillingAgreement,
  createPayPalBillingPlan,
  createPayPalOrder,
  createPayPalProduct,
  getPayPalAccessToken,
} from "../../Paypal/payPalApiCalls";
import { storePaymentDataInFirebase } from "../Utils/storePaymentDataInFirebase";
import LoadingOverlay from "./LoadingOverlay";
import { useAuth } from "../Auth/AuthContext";
import axios from "axios";
import { getDonationTemplate } from "../../emailTemplates/donationTemplate";

const stripePromise = loadStripe(
  "pk_test_51PJQcsHXm3AIrcKTHKRprNqZTtHGfNiyOyKzVV6R28SjytoZpxwgsxuzR2JGI2nr6yqBa3qAmbCUAadXPwClfO5o00pVf9hd7P"
);

export default function Cart() {
  const { formDataArray, setFormDataArray } = useFormData();
  const [saveCheckoutDetailsCheck, setSaveCheckoutDetailsCheck] =
    useState(false);
  const [checkoutDetailsPreset, setCheckoutDetailsPreset] = useState([]);
  const [paymentGateway, setPaymentGateway] = useState(null);

  const subtotal = formDataArray.reduce((acc, formData) => {
    return parseFloat(acc) + parseFloat(formData.selectedAmount);
  }, 0);

  const tax = 0; // Assuming tax is 10% of subtotal
  const { currentUser } = useAuth();

  // Total calculation
  const total = parseFloat(subtotal) + parseFloat(tax);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: currentUser === null ? "" : currentUser.email,
    phone: "",
    address: "",
    streetAddress: "",
    stateProvinceRegion: "",
    city: "",
    country: "",
  });

  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [prefilled, setPrefilled] = useState(false);
  const [isVisisblePaypalButton, setIsVisisblePaypalButton] = useState(false);

  const handleDelete = (index) => {
    const newArray = formDataArray.filter((_, i) => i !== index);
    setFormDataArray(newArray);
  };

  const userId = localStorage.getItem("uid");

  useEffect(() => {
    getCheckoutPresetsFromFirebase(userId, (presetsArray) => {
      setCheckoutDetailsPreset(presetsArray);
    });
  }, [userId]);

  const [loading, setLoading] = useState(false);
  const scrollRef = useRef();

  const handlePayPalPayment = async (
    isRecurring,
    amount,
    billingCycle,
    roundEndDate
  ) => {
    try {
      setLoading(true);
      const payPalToken = await getPayPalAccessToken();
      localStorage.setItem("isRecurring", JSON.stringify(isRecurring));

      if (isRecurring) {
        // Create PayPal Product (if not already created, you might want to cache this)
        const productResponse = await createPayPalProduct();
        const productId = productResponse.data.id;

        // Create PayPal Billing Plan
        const billingPlan = await createPayPalBillingPlan(
          payPalToken,
          amount,
          billingCycle,
          productId,
          roundEndDate
        );
        const planId = billingPlan.id;

        const activatePlan = await activateBillingPlan(payPalToken, planId);
        // Create PayPal Billing Agreement
        const billingAgreement = await createPayPalBillingAgreement(
          payPalToken,
          planId
        );

        const approvalUrl = billingAgreement.links.find(
          (link) => link.rel === "approval_url"
        ).href;

        // Redirect user to PayPal approval page for recurring payments
        window.location.href = approvalUrl;
      } else {
        // Create a one-time PayPal Order
        const createdOrder = await createPayPalOrder(payPalToken, amount);
        const approvalUrl = createdOrder.links.find(
          (link) => link.rel === "approve"
        ).href;

        // Redirect user to PayPal approval page for one-time payment
        window.location.href = approvalUrl;
      }
    } catch (error) {
      console.error("Error processing PayPal payment:", error);
      toast.error(`Error processing PayPal payment: ${error}`);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const sendEmail = async (user, amount) => {
    try {
      // Fetch user data from Firebase based on email
      const emailData = {
        to: user.email,
        // to: "mu8494759@gmail.com",
        subject: "Donation - Human Concern USA",
        html: getDonationTemplate(user.displayName, amount),
      };
      try {
        const response = await axios.post(
          "https://hci-webapp.onrender.com/send-email",
          emailData
        );
        console.log("response: ", response);
        if (response.status == 200) {
          toast.success("Email has been sent");
        } else {
          console.error("Failed to send email");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Error fetching user data");
    }
  };

  const handleStripePayment = async (e) => {
    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );
    if (error) {
      toast.error(error.message);
      setLoading(false); // End loading
      return;
    }
    if (!stripe || !elements) {
      setLoading(false); // End loading
      return;
    }

    const requestBody = {
      tokenId: token.id,
      email: currentUser.email,
      cartItems: formDataArray,
      checkoutDetails: formData,
    };

    // const response = await fetch("http://localhost:3001/collect-donation", {
      const response = await fetch("https://hci-webapp.onrender.com/collect-donation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "https://hci-webapp.vercel.app/",
      },
      mode: "cors",
      body: JSON.stringify(requestBody),
    });
    const result = await response.json();
    return result;
  };

  const showPayPalButton = (formData) => {
    let count = 0;
    if (!formData) return count;

    formData.forEach((item) => {
      if (item.is_recurring) {
        count++;
      }
    });

    if (formData.length === 1) {
      return true;
    } else if (count === 0) {
      return true;
    }

    return false;
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    try {
      if (event) event.preventDefault();
      if (formData.email === "") {
        toast.error("Please Login to Continue");
        navigate("/login");
        return;
      }

      const userId = localStorage.getItem("uid");

      if (saveCheckoutDetailsCheck) {
        await addPresetsToFirebase(
          `checkoutDetailsPresets/${userId}`,
          formData
        );
      }

      const appealDetails = formDataArray.map((f) => ({
        appealId: f.campaignData.id,
        amount: f.selectedAmount,
      }));

      const modifiedData = {
        ...formData,
        appealDetails,
        userId,
        paymentGateway,
        createdAt: new Date().toISOString(),
      };

      const checkoutID = await addDataToFirebase(
        "checkoutDetails",
        modifiedData
      );
      setPrefilled(true);

      if (paymentGateway === "stripe") {
        const result = await handleStripePayment();
        if (result.success) {
          await storePaymentDataInFirebase(
            checkoutID.key,
            formDataArray,
            paymentGateway,
            result.recurringTransactionIds[0]
          );
          sendEmail(currentUser, subtotal);
          navigate("/thank-you");
        } else if (result.error) {
          toast.error("Payment failed: " + result.error);
        }
        setLoading(false); // End loading
      } else if (paymentGateway === "paypal") {
        // Save necessary data to localStorage before redirecting
        localStorage.setItem(
          "checkoutData",
          JSON.stringify({
            formDataArray,
            checkoutID: checkoutID.key,
            paymentGateway,
          })
        );
        if (formDataArray.length === 1 && formDataArray[0].is_recurring) {
          handlePayPalPayment(
            true,
            subtotal,
            formDataArray[0].billingCycle,
            formDataArray[0].roundEndDate
          );
        } else {
          handlePayPalPayment(false, subtotal, formDataArray[0].billingCycle); //no need for billing cycle but i still passed the argument
        }
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      toast.error("Error processing payment: " + error.message);
      setLoading(false); // End loading
    } finally {
      setPaymentGateway(null); // reset payment gateway
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fillPresetValues = (index) => {
    setFormData(checkoutDetailsPreset[index]);
  };

  // const handleDetails = () => {
  //   getDataFromFirebase("checkoutDetails", (checkoutDetails) => {
  //     setFormData(checkoutDetails[0]);
  //   });
  // };

  useEffect(() => {
    const val = showPayPalButton(formDataArray);
    setIsVisisblePaypalButton(val);
  }, [formDataArray]);

  useEffect(() => {
    if (paymentGateway !== null) {
      handleSubmit();
    }
  }, [paymentGateway]);

  return (
    <LoadingOverlay loading={loading}>
      {processing && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <Loading />
        </div>
      )}
      <div className="container cart d-flex justify-content-center align-items-center">
        {formDataArray.length === 0 ? (
          <>
            <section className="hero-area">
              <div className="single-slider hero-padding">
                <div className="container">
                  <Row>
                    <Col className="d-flex flex-column text-center justify-content-center align-items-center">
                      <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
                        Your Cart is Empty!
                      </h1>
                      <p>
                        Looks like you haven't added anything to your cart yet.
                        Let's fix that!
                      </p>
                      <Link
                        to={"/appeals"}
                        className="btn-primary-fill pill-btn"
                      >
                        Back to Campaigns
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <Row className="contactSection w-100">
              <Col xs={12} md={7} className="px-4">
                <Row className="expressCheckoutRow">
                  <Col>
                    <h5 ref={scrollRef} className="font-700 wow fadeInUp mb-2">
                      Express Checkout
                    </h5>
                    <div className="d-flex gap-2">
                      {isVisisblePaypalButton && (
                        <button
                          onClick={() => {
                            setPaymentGateway("paypal");
                          }}
                          className="btn btnPayPal paymentBtn"
                        >
                          <svg
                            width="76"
                            height="17"
                            viewBox="0 0 66 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_14_191)">
                              <path
                                d="M20.9185 3.39795C20.7838 3.39795 20.6695 3.49371 20.6484 3.6241L19.09 13.3071C19.0843 13.3425 19.0865 13.3786 19.0965 13.4131C19.1064 13.4475 19.1239 13.4794 19.1477 13.5067C19.1714 13.5339 19.2009 13.5557 19.2341 13.5707C19.2673 13.5857 19.3034 13.5934 19.3399 13.5934H21.1881C21.3226 13.5934 21.4371 13.4975 21.4582 13.3673L21.916 10.5238C21.9368 10.3936 22.0514 10.2976 22.186 10.2976H23.8664C25.8297 10.2976 27.4963 8.89416 27.8008 6.99238C28.1077 5.07452 26.5784 3.40211 24.4109 3.39795H20.9185ZM22.7151 5.55957H24.0595C25.1664 5.55957 25.5268 6.19942 25.4129 6.90703C25.2989 7.61615 24.7391 8.13848 23.6684 8.13848H22.3L22.7151 5.55957ZM31.5598 6.21778C31.0931 6.21948 30.5561 6.31316 29.9532 6.55919C28.5703 7.12391 27.9062 8.29102 27.6242 9.14189C27.6242 9.14189 26.7266 11.7378 28.7546 13.164C28.7546 13.164 30.6355 14.537 32.7527 13.0794L32.7162 13.3071C32.7106 13.3424 32.7128 13.3786 32.7227 13.413C32.7327 13.4474 32.7501 13.4793 32.7738 13.5065C32.7975 13.5337 32.8269 13.5556 32.8601 13.5706C32.8932 13.5856 32.9293 13.5934 32.9658 13.5934H34.7203C34.855 13.5934 34.9693 13.4975 34.9904 13.3673L36.0576 6.73633C36.0633 6.70096 36.0612 6.6648 36.0513 6.63033C36.0413 6.59586 36.0239 6.56391 36.0002 6.53666C35.9765 6.50941 35.947 6.48752 35.9139 6.4725C35.8807 6.45747 35.8446 6.44966 35.808 6.44961H34.0535C33.9189 6.44961 33.8043 6.54556 33.7835 6.67577L33.7261 7.03269C33.7261 7.03269 32.96 6.21267 31.5598 6.21778ZM31.6171 8.31221C31.8186 8.31221 32.0029 8.33947 32.1682 8.39151C32.9252 8.62959 33.3545 9.34211 33.2302 10.1144C33.0771 11.0654 32.281 11.7657 31.2602 11.7657C31.0589 11.7657 30.8746 11.7386 30.7091 11.6865C29.9522 11.4485 29.5203 10.7359 29.6445 9.96361C29.7977 9.01263 30.5962 8.31221 31.6171 8.31221Z"
                                fill="#003087"
                              ></path>
                              <path
                                d="M46.1846 3.39795C46.05 3.39795 45.9356 3.49371 45.9145 3.6241L44.3561 13.3071C44.3504 13.3425 44.3526 13.3786 44.3626 13.4131C44.3726 13.4475 44.39 13.4794 44.4138 13.5067C44.4375 13.5339 44.467 13.5557 44.5002 13.5707C44.5334 13.5857 44.5695 13.5934 44.606 13.5934H46.4542C46.5887 13.5934 46.7032 13.4975 46.7243 13.3673L47.1821 10.5238C47.203 10.3936 47.3175 10.2976 47.4521 10.2976H49.1325C51.0958 10.2976 52.7623 8.89416 53.0665 6.99238C53.3736 5.07452 51.8445 3.40211 49.677 3.39795H46.1846ZM47.9812 5.55957H49.3256C50.4325 5.55957 50.7929 6.19942 50.679 6.90703C50.565 7.61615 50.0054 8.13848 48.9345 8.13848H47.5661L47.9812 5.55957ZM56.8257 6.21778C56.359 6.21948 55.822 6.31316 55.2191 6.55919C53.8362 7.12391 53.1721 8.29102 52.8901 9.14189C52.8901 9.14189 51.9929 11.7378 54.0209 13.164C54.0209 13.164 55.9014 14.537 58.0189 13.0794L57.9823 13.3071C57.9767 13.3425 57.9789 13.3786 57.9889 13.4131C57.9988 13.4475 58.0163 13.4794 58.04 13.5067C58.0638 13.5339 58.0933 13.5557 58.1265 13.5707C58.1597 13.5857 58.1958 13.5934 58.2323 13.5934H59.9866C60.1213 13.5934 60.2356 13.4975 60.2567 13.3673L61.3243 6.73633C61.33 6.70093 61.3279 6.66473 61.3179 6.63024C61.308 6.59574 61.2906 6.56377 61.2668 6.53652C61.243 6.50926 61.2135 6.48738 61.1803 6.47238C61.1471 6.45738 61.1109 6.44961 61.0743 6.44961H59.3198C59.1852 6.44961 59.0706 6.54556 59.0498 6.67577L58.9924 7.03269C58.9924 7.03269 58.2259 6.21267 56.8257 6.21778ZM56.883 8.31221C57.0845 8.31221 57.2688 8.33947 57.4342 8.39151C58.1912 8.62959 58.6204 9.34211 58.4962 10.1144C58.343 11.0654 57.547 11.7657 56.5261 11.7657C56.3248 11.7657 56.1405 11.7386 55.975 11.6865C55.2182 11.4485 54.7862 10.7359 54.9104 9.96361C55.0636 9.01263 55.8622 8.31221 56.883 8.31221Z"
                                fill="#0070E0"
                              ></path>
                              <path
                                d="M36.8905 6.44965C36.7514 6.44965 36.6525 6.5827 36.695 6.71233L38.6186 12.5604L36.8793 15.3162C36.7949 15.45 36.893 15.6228 37.0535 15.6228H39.1092C39.168 15.6228 39.2258 15.6079 39.277 15.5795C39.3282 15.5512 39.371 15.5105 39.4014 15.4612L44.7736 6.75435C44.8561 6.62055 44.7575 6.44946 44.598 6.44946H42.5425C42.4832 6.44951 42.4248 6.4647 42.3733 6.49354C42.3217 6.52237 42.2787 6.56386 42.2485 6.61392L40.1339 10.1116L39.0605 6.63985C39.0256 6.52687 38.9191 6.44946 38.7988 6.44946L36.8905 6.44965Z"
                                fill="#003087"
                              ></path>
                              <path
                                d="M63.3178 3.39795C63.1834 3.39795 63.0689 3.4939 63.0478 3.6241L61.489 13.3067C61.4832 13.3421 61.4854 13.3783 61.4953 13.4128C61.5053 13.4473 61.5227 13.4793 61.5465 13.5065C61.5702 13.5338 61.5997 13.5557 61.633 13.5707C61.6662 13.5857 61.7023 13.5934 61.7389 13.5934H63.5871C63.7216 13.5934 63.8361 13.4975 63.8572 13.3673L65.4156 3.68429C65.4213 3.64895 65.4191 3.61283 65.4091 3.5784C65.3992 3.54398 65.3818 3.51208 65.3581 3.48487C65.3344 3.45767 65.3049 3.43582 65.2718 3.42081C65.2386 3.40581 65.2026 3.39801 65.1661 3.39795H63.3178Z"
                                fill="#0070E0"
                              ></path>
                              <path
                                d="M5.51088 3.39795C5.39182 3.39805 5.2767 3.43974 5.18621 3.51553C5.09571 3.59132 5.03576 3.69625 5.01714 3.81146L4.19678 8.90911C4.23502 8.67103 4.44441 8.4956 4.6905 8.4956H7.09444C9.51384 8.4956 11.5668 6.76642 11.9419 4.42312C11.9698 4.24817 11.9857 4.07158 11.9893 3.89454C11.3744 3.57868 10.6522 3.39795 9.86076 3.39795H5.51088Z"
                                fill="#001C64"
                              ></path>
                              <path
                                d="M11.9892 3.89478C11.9856 4.07181 11.9697 4.24841 11.9418 4.42335C11.5667 6.76665 9.51355 8.49583 7.09435 8.49583H4.69041C4.44451 8.49583 4.23493 8.67108 4.19668 8.90934L3.44236 13.5937L2.96989 16.5323C2.96066 16.5891 2.96412 16.6472 2.98002 16.7025C2.99593 16.7579 3.02391 16.8092 3.06201 16.8529C3.10012 16.8966 3.14745 16.9317 3.20074 16.9558C3.25404 16.9798 3.31203 16.9923 3.37071 16.9922H5.97998C6.09904 16.9921 6.21415 16.9504 6.30465 16.8746C6.39515 16.7989 6.45509 16.6939 6.47371 16.5787L7.16098 12.3079C7.17961 12.1926 7.23962 12.0876 7.33019 12.0118C7.42077 11.936 7.53599 11.8944 7.65511 11.8944H9.19135C11.6107 11.8944 13.6637 10.1652 14.0388 7.82191C14.3052 6.15878 13.4503 4.64534 11.9892 3.89478Z"
                                fill="#0070E0"
                              ></path>
                              <path
                                d="M2.54583 0.000488281C2.29994 0.000488281 2.09036 0.175734 2.05211 0.413622L0.00455106 13.1343C-0.0342752 13.3758 0.156191 13.5942 0.40576 13.5942H3.44212L4.19605 8.90983L5.01641 3.81218C5.03503 3.69697 5.09498 3.59204 5.18548 3.51625C5.27598 3.44046 5.39109 3.39877 5.51015 3.39867H9.86004C10.6516 3.39867 11.3737 3.57959 11.9885 3.89526C12.0306 1.76184 10.2338 0.000488281 7.76342 0.000488281H2.54583Z"
                                fill="#003087"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_14_191">
                                <rect
                                  width="65.4187"
                                  height="16.9919"
                                  fill="white"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      )}
                      {/* {formData && (
                        <Button onClick={handleDetails}>
                          {" "}
                          Auto Fill Details
                        </Button>
                      )} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <div>
                        {checkoutDetailsPreset &&
                          checkoutDetailsPreset.length > 0 && (
                            <h5 className="font-700 wow fadeInUp mb-2">
                              Checkout Details Presets
                            </h5>
                          )}
                        {checkoutDetailsPreset.map((detail, i) => (
                          <div
                            key={i}
                            className="contact-us-section cursor-pointer position-relative p-3 border rounded mb-3"
                            onClick={() => fillPresetValues(i)}
                          >
                            <button
                              type="button"
                              className="btn-close position-absolute top-0 end-0 mt-2 me-2"
                              aria-label="Close"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents triggering fillPresetValues
                                deletePresetsFromFirebase(
                                  `checkoutDetailsPresets/${userId}`,
                                  i
                                );
                              }}
                            ></button>
                            <p>
                              <strong>Email:</strong> {detail.email}
                            </p>
                            <p>
                              <strong>Name:</strong> {detail.firstName}{" "}
                              {detail.lastName}
                            </p>
                            <p>
                              <strong>Address:</strong> {detail.address}
                            </p>
                            <p>
                              <strong>Phone:</strong> {detail.phone}
                            </p>
                            <strong>...</strong>
                          </div>
                        ))}
                      </div>
                      <Form.Group className="mt-4 mb-4">
                        <h5 className="font-700 wow fadeInUp mb-2">
                          Contact Information
                        </h5>
                        <Form.Control
                          className="mb-2"
                          type="text"
                          name="email"
                          value={formData.email}
                          placeholder="Enter your email address here"
                          required
                          readOnly
                        />
                        <Form.Check
                          type="checkbox"
                          label="Email me with news and offers"
                          checked={checked}
                          className="formSelect"
                          onChange={handleCheckboxChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Credit or Debit Card</Form.Label>
                        <CardElement
                          className="form-control"
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mt-4 mb-2">
                        <h5 className="font-700 wow fadeInUp mb-2">
                          Shipping Address
                        </h5>

                        <Row className="mt-3">
                          <Col xs={6}>
                            <label className="form-label">First Name</label>
                            <input
                              className="form-control"
                              type="text"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={6}>
                            <label className="form-label">Last Name</label>
                            <input
                              className="form-control"
                              type="text"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={12}>
                            <label className="form-label">Phone</label>
                            <input
                              className="form-control"
                              type="tel"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={12}>
                            <label className="form-label">Address</label>
                            <input
                              className="form-control"
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={6}>
                            <label className="form-label">Street Address</label>
                            <input
                              className="form-control"
                              type="text"
                              name="streetAddress"
                              value={formData.streetAddress}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={6}>
                            <label className="form-label">
                              State / Province / Region
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="stateProvinceRegion"
                              value={formData.stateProvinceRegion}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={6}>
                            <label className="form-label">City</label>
                            <input
                              className="form-control"
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                          <Col xs={6}>
                            <label className="form-label">Country</label>
                            <input
                              className="form-control"
                              type="text"
                              name="country"
                              value={formData.country}
                              onChange={handleChange}
                              required
                            />
                          </Col>
                        </Row>
                        <div class="form-check mt-2">
                          <input
                            value={saveCheckoutDetailsCheck}
                            onChange={() => {
                              setSaveCheckoutDetailsCheck(
                                !saveCheckoutDetailsCheck
                              );
                            }}
                            type="checkbox"
                            class="form-check-input"
                            id="saveDetails"
                          />
                          <label class="form-check-label" for="saveDetails">
                            Save checkout details
                          </label>
                        </div>
                      </Form.Group>
                      <Form.Group className="d-flex align-items-center gap-4">
                        <Button
                          onClick={() => setPaymentGateway("stripe")}
                          className="btn donateBtn"
                          disabled={loading}
                        >
                          {loading ? (
                            <div className="loader">Loading</div>
                          ) : (
                            "Donate now"
                          )}
                        </Button>
                        <Link className="text-red">Return to home</Link>
                      </Form.Group>
                      {/* {loading && (
                           <div className="loader-container">
                            <div className="loader"></div>
                            <p>Processing...</p>
                          </div>
                        )} */}
                    </Form>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={5} className="cardSidebar">
                <div>
                  {formDataArray.map((formData, index) => (
                    <div key={index}>
                      <Row className="d-flex align-items-center borderBottom">
                        <Col xs={3} className="p-0">
                          <img
                            className="w-100"
                            src={formData.campaignData.thumbnail}
                            alt="campaign thumbnail"
                          />
                        </Col>
                        <Col xs={4}>
                          <h6 className="mb-0 fw-bold">
                            {formData.campaignData.title}
                          </h6>
                          <p className="mb-0">Quantity: {formData.quantity}</p>
                        </Col>
                        <Col xs={4} className="">
                          <h6 className="mb-0 text-end">
                            ${formData.selectedAmount}
                          </h6>
                        </Col>
                        <Col xs={1} className="">
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(index)}
                          >
                            X
                          </button>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
                <div>
                  <Row className="d-flex align-items-center borderBottom">
                    <Col xs={8}>
                      <p className="mb-0 f-500 text-dark">Subtotal</p>
                      <p className="mb-0 f-500 text-dark">Tax</p>
                    </Col>
                    <Col xs={4}>
                      <p className="mb-0 text-end">${subtotal}</p>
                      <p className="mb-0 text-end">${tax}</p>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center borderBottom">
                    <Col xs={8}>
                      <p className="mb-0 f-600 text-dark">Total</p>
                    </Col>
                    <Col
                      xs={4}
                      className="d-flex align-items-end gap-2 justify-content-end"
                    >
                      <p className="mb-0 text-end">USD</p>
                      <h5 className="mb-0 text-end f-900">${total}</h5>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </LoadingOverlay>
  );
}

function generateDonationEmail(
  donorName,
  projectName,
  campaignName,
  appealName,
  donationAmount,
  donationDate,
  recurring,
  nextPaymentDate
) {
  return `<!DOCTYPE html>
<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">

<head>
<title></title>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!-->
<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900" rel="stylesheet" type="text/css"><!--<![endif]-->
<style>
* {
box-sizing: border-box;
}

body {
margin: 0;
padding: 0;
}

a[x-apple-data-detectors] {
color: inherit !important;
text-decoration: inherit !important;
}

#MessageViewBody a {
color: inherit;
text-decoration: none;
}

p {
line-height: inherit
}

.desktop_hide,
.desktop_hide table {
mso-hide: all;
display: none;
max-height: 0px;
overflow: hidden;
}

.image_block img+div {
display: none;
}

@media (max-width:520px) {

.desktop_hide table.icons-inner,
.social_block.desktop_hide .social-table {
display: inline-block !important;
}

.icons-inner {
text-align: center;
}

.icons-inner td {
margin: 0 auto;
}

.image_block div.fullWidth {
max-width: 100% !important;
}

.mobile_hide {
display: none;
}

.row-content {
width: 100% !important;
}

.stack .column {
width: 100%;
display: block;
}

.mobile_hide {
min-height: 0;
max-height: 0;
max-width: 0;
overflow: hidden;
font-size: 0px;
}

.desktop_hide,
.desktop_hide table {
display: table !important;
max-height: none !important;
}

.row-2 .column-1 .block-2.heading_block td.pad {
padding: 0 20px !important;
}

.row-2 .column-1 .block-2.heading_block h1 {
font-size: 20px !important;
}

.row-3 .column-1 .block-1.image_block td.pad {
padding: 15px 0 20px !important;
}

.row-2 .column-1 .block-3.paragraph_block td.pad,
.row-3 .column-1 .block-4.paragraph_block td.pad {
padding: 10px 20px !important;
}
}
</style>
</head>

<body class="body" style="background-color: #FFFFFF; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
<table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;">
<tbody>
<tr>
<td>
<table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f5f5;">
<tbody>
<tr>
<td>
<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 500px; margin: 0 auto;" width="500">
<tbody>
<tr>
<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 10px; padding-top: 20px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
<table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad" style="padding-bottom:10px;width:100%;padding-right:0px;padding-left:0px;">
<div class="alignment" align="center" style="line-height:10px">
<div style="max-width: 225px;"><img src="https://9ac26a990d.imgdist.com/pub/bfra/33iro7rc/6gn/gks/2k4/hc%20usa%20logo.svg" style="display: block; height: auto; border: 0; width: 100%;" width="225" alt="your-logo" title="your-logo" height="auto"></div>
</div>
</td>
</tr>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f5f5;">
<tbody>
<tr>
<td>
<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 500px; margin: 0 auto;" width="500">
<tbody>
<tr>
<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 10px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
<table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad" style="padding-bottom:20px;width:100%;padding-right:0px;padding-left:0px;">
<div class="alignment" align="center" style="line-height:10px">
<div class="fullWidth" style="max-width: 500px;"><img src="https://humanconcern.org/wp-content/uploads/2024/06/Guided.jpeg" style="display: block; height: auto; border: 0; width: 100%;" width="500" alt="reset-password" title="reset-password" height="auto"></div>
</div>
</td>
</tr>
</table>
<table class="heading_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad" style="padding-left:10px;padding-right:10px;text-align:center;width:100%;">
<h1 style="margin: 0; color: #e3222a; direction: ltr; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; font-size: 25px; font-weight: 600; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 30px;">Thank You for Your Generous Donation!</h1>
</td>
</tr>
</table>
<table class="paragraph_block block-3" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
<tr>
<td class="pad">
<div style="color:#393d47;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:14px;line-height:150%;text-align:left;mso-line-height-alt:21px;">
<p style="margin: 0; word-break: break-word;"><strong>Dear Donor,</strong></p>
<p style="margin: 0; word-break: break-word;">We are sincerely grateful for your generous donation to Human Concern USA. Your support is absolutely invaluable in helping us achieve our mission and make a difference in the lives of those we serve.</p>
<p style="margin: 0; word-break: break-word;">&nbsp;</p>

<p style="margin: 0;">Your contribution significantly impacts our efforts, enabling us to provide essential services and support to those in need. We are deeply appreciative of your kindness and commitment to our cause.</p>
<p style="margin: 0;">&nbsp;</p>
<p style="margin: 0;"><strong>Warm regards,</strong></p>
<p style="margin: 0;"><strong>Human Concern USA</strong></p>
<p style="margin: 0;">info@humanconcernusa.org<br>1-800-583-5841</p>
</div>
</td>
</tr>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fff;">
<tbody>
<tr>
<td>
<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 500px; margin: 0 auto;" width="500">
<tbody>
<tr>
<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
<table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad" style="padding-bottom:25px;padding-top:20px;width:100%;padding-right:0px;padding-left:0px;">
<div class="alignment" align="center" style="line-height:10px">
<div style="max-width: 175px;"><img src="https://9ac26a990d.imgdist.com/pub/bfra/33iro7rc/6gn/gks/2k4/hc%20usa%20logo.svg" style="display: block; height: auto; border: 0; width: 100%;" width="175" alt="your-logo" title="your-logo" height="auto"></div>
</div>
</td>
</tr>
</table>
<table class="social_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad" style="text-align:center;padding-right:0px;padding-left:0px;">
<div class="alignment" align="center">
<table class="social-table" width="168px" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block;">
<tr>
<td style="padding:0 5px 0 5px;"><a href="https://www.facebook.com/HumanConcernUSA" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png" width="32" height="auto" alt="Facebook" title="Facebook" style="display: block; height: auto; border: 0;"></a></td>
<td style="padding:0 5px 0 5px;"><a href="https://twitter.com/hci_usa" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/twitter@2x.png" width="32" height="auto" alt="Twitter" title="Twitter" style="display: block; height: auto; border: 0;"></a></td>
<td style="padding:0 5px 0 5px;"><a href="https://www.instagram.com/humanconcernusa" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png" width="32" height="auto" alt="Instagram" title="Instagram" style="display: block; height: auto; border: 0;"></a></td>
<td style="padding:0 5px 0 5px;"><a href="https://www.linkedin.com/company/hci-usa/" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/linkedin@2x.png" width="32" height="auto" alt="LinkedIn" title="LinkedIn" style="display: block; height: auto; border: 0;"></a></td>
</tr>
</table>
</div>
</td>
</tr>
</table>
<table class="html_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad">
<div style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif;text-align:center;" align="center"><div style="margin-top: 25px;border-top:1px dashed #D6D6D6;margin-bottom: 20px;"></div></div>
</td>
</tr>
</table>
<table class="paragraph_block block-4" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
<tr>
<td class="pad">
<div style="color:#000000;font-family:Tahoma,Verdana,Segoe,sans-serif;font-size:12px;line-height:120%;text-align:center;mso-line-height-alt:14.399999999999999px;">
<p style="margin: 0; word-break: break-word;">Fighting poverty for over 40 years. HUMAN CONCERN USA is a 501(C)3 Tax Exempt Nonprofit our EIN# 92-2388570</p>
</div>
</td>
</tr>
</table>
<table class="html_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
<tr>
<td class="pad">
<div style="font-family:Arial, Helvetica Neue, Helvetica, sans-serif;text-align:center;" align="center"><div style="height-top: 20px;">&nbsp;</div></div>
</td>
</tr>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table><!-- End -->
</body>

</html>`;
}

// Example usage
