import {
  addDataToFirebase,
  updateAppealRaisedAmount,
} from "../../firebase/firebaseFunctions";

// Utility function to store payment data in Firebase
export const storePaymentDataInFirebase = async (
  checkoutID,
  formDataArray,
  paymentGateWay,
  subId
) => {
  const userId = localStorage.getItem("uid"); // Retrieve user ID once to avoid redundancy
  for (let i = 0; i < formDataArray.length; i++) {
    const formData = formDataArray[i];

    const data = {
      checkoutID: checkoutID,
      campaignData: formData.campaignData,
      userId: userId,
      name: `${formData.firstName} ${formData.lastName}`, // Corrected the template string usage
      amount: formData.selectedAmount,
      timestamp: new Date().toISOString(),
      appealId: formData.campaignData.id,
      paymentGateWay,
      joiningDate: new Date().toISOString(),
    };

    await addDataToFirebase("donation_payments", data);
    await updateAppealRaisedAmount(
      formData.campaignData.id,
      formData.selectedAmount
    );

    // Handle recurring payments and Stripe-specific logic
    if (formData.is_recurring) {
      const subscriptionDetails = {
        Status: "active",
        userId: userId,
        checkoutID: checkoutID,
        appealId: formData.campaignData.id,
        billingCycle: formData.billingCycle,
        subscritionEndDate: formData.roundEndDate,
        amount: formData.selectedAmount,
        createdAt: new Date().toISOString(),
        paymentGateWay,
        subscriptionId: subId, // Assign corresponding subscription ID
      };
      await addDataToFirebase("subscription", subscriptionDetails);
    }
  }
  localStorage.setItem("formDataArray", JSON.stringify([]));
};
