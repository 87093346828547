import React, { useEffect, useState } from 'react';
import { Col, Row, Modal, Button, Form } from 'react-bootstrap';
import DataTable from '../DataTable';
import { getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import FormGenerator from '../FormGenerator';

export default function Projects() {
    const [projects, setProjects] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);
    const [projectCode, setProjectCode] = useState('');
    const [projectName, setProjectName] = useState('');

    const handleShowAddModal = () => {
        setEditMode(false);
        setCurrentProject(null);
        setShowAddModal(true);
    };

    const handleCloseAddModal = () => setShowAddModal(false);

    const handleShowEditModal = (project) => {
        setCurrentProject(project);
        setProjectCode(project['project-code']);
        setProjectName(project['project-name']);
        setEditMode(true);
        setShowEditModal(true);
    };

    const handleCloseEditModal = () => setShowEditModal(false);

    const handleSaveProject = () => {
        // Handle add project logic here
        handleCloseAddModal();
    };

    const handleUpdateProject = () => {
        const referencePath = `projects/${currentProject.id}`;
        updateFieldsInFirebase(referencePath, { 'project-code': projectCode, 'project-name': projectName })
            .then(() => {
                // Update the project list after successful update
                setProjects(projects.map(project => 
                    project.id === currentProject.id
                        ? { ...project, 'project-code': projectCode, 'project-name': projectName }
                        : project
                ));
                handleCloseEditModal();
            });
    };

    useEffect(() => {
        const referencePath = 'projects';

        getDataFromFirebase(referencePath, (projectsArray) => {
            // Filter out projects marked as "deactivated"
            const activeProjects = projectsArray.filter(project => project.status !== 'deactivated');
            setProjects(activeProjects);
        });
    }, []);

    useEffect(() => {
        console.log(projects);
    }, [projects]);

    const handleEdit = (project) => {
        handleShowEditModal(project);
    };

    const handleDeactivate = (project) => {
        console.log(project);
        if (window.confirm(`Are you sure you want to deactivate project: ${project['project-name']}?`)) {
            const referencePath = `projects/${project.id}`;
            updateFieldsInFirebase(referencePath, { status: 'deactivated' }).then(() => {
                setProjects(projects.filter(p => p.id !== project.id));
            });
        }
    };

    const columns = [
        {
            Header: 'Project Code',
            accessor: 'project-code',
        },
        {
            Header: 'Project Name',
            accessor: 'project-name',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <Button variant="primary" onClick={() => handleEdit(row.original)} className="mr-2">Edit</Button>
                    <Button variant="danger" className="m-2" onClick={() => handleDeactivate(row.original)}>Delete</Button>
                </div>
            ),
        },
    ];

    const addFields = [
        { label: 'Project Code', type: 'text', id: 'project-code', classes: '', width: '6', ph: "Enter Project Code", value: '' },
        { label: 'Project Name', type: 'text', id: 'project-name', classes: '', width: '6', ph: "Enter Project Name", value: '' },
    ];

    const editFields = [
        { label: 'Project Code', type: 'text', id: 'project-code-edit', classes: '', width: '6', ph: "Enter Project Code", value: projectCode, onChange: (e) => setProjectCode(e.target.value) },
        { label: 'Project Name', type: 'text', id: 'project-name-edit', classes: '', width: '6', ph: "Enter Project Name", value: projectName, onChange: (e) => setProjectName(e.target.value) },
    ];

    const referencePath = "projects";

    return (
        <>
            <Row>
                <Col>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="mb-0">Projects</h4>
                        <Button variant="danger" onClick={handleShowAddModal}>Add Project</Button>
                    </div>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DataTable columns={columns} data={projects} onRowClick={() => {}} /> {/* Placeholder onRowClick handler */}
                </Col>
            </Row>

            {/* Add Project Modal */}
            <Modal show={showAddModal} onHide={handleCloseAddModal} centered size='lg'>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h6 className="mb-3 mb-md-0">Add Project</h6>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGenerator
                                fields={addFields}
                                referencePath={referencePath}
                                buttonLabel="Add Project"
                                name="Project"
                                isEditMode={false}
                                closeModal={handleCloseAddModal}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* Edit Project Modal */}
            <Modal show={showEditModal} onHide={handleCloseEditModal} centered size='lg'>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h6 className="mb-3 mb-md-0">Edit Project</h6>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form>
                                {editFields.map(field => (
                                    <Form.Group key={field.id} controlId={field.id}>
                                        <Form.Label>{field.label}</Form.Label>
                                        <Form.Control 
                                            type={field.type} 
                                            placeholder={field.ph} 
                                            value={field.value} 
                                            onChange={field.onChange}
                                        />
                                    </Form.Group>
                                ))}
                                <Button variant="primary" className="m-2" onClick={handleUpdateProject}>Update Project</Button>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
