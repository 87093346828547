import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Card, FormCheck } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAuth } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useFormData } from "./FormDataContext"; // Import context

export default function DonationForm({ campaignData }) {
  // const stripe = useStripe();
  // const elements = useElements();

  // Get today's date in YYYY-MM-DD format
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [customAmountEnabled, setCustomAmountEnabled] = useState(false);
  const [selectedDonationOption, setSelectedDonationOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [quantity, setQuantity] = useState(1);
  const today = new Date().toISOString().split("T")[0];

  const { addFormData, setCampaignData } = useFormData();
  const [billingCycle, setBillingCycle] = useState(null);
  const [roundEndDate, setRoundEndDate] = useState(today);

  const auth = useAuth();
  const Navigate = useNavigate();
  const handleAmountChange = (event) => {
    setSelectedAmount(event.target.value);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    if (value === "custom") {
      setCustomAmountEnabled(true); // Enable custom amount input
      handleAmountChange(event); // Update selectedAmount state
    } else if (event.target.name == "donation-amount") {
      setCustomAmountEnabled(false); // Disable custom amount input
      handleAmountChange(event); // Update selectedAmount state
      setSelectedOption(value); // Update selected radio button state
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    streetAddress: "",
    stateProvinceRegion: "",
    city: "",
    country: "",
  });

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    setCampaignData(campaignData); // Set the campaign data in context
  }, [campaignData, setCampaignData]);

  //   useEffect(() => {
  //   setSelectedAmount(selectedAmount * quantity);
  // }, [selectedAmount, quantity]);

  useEffect(() => {
    setSelectedAmount((prevAmount) => prevAmount * quantity);
  }, [quantity]);

  useEffect(() => {
    if (auth.currentUser) {
      setFormData({
        firstName: auth.currentUser.firstName,
        lastName: auth.currentUser.lastName,
        email: auth.currentUser.email,
        phone: auth.currentUser.phone,
        address: auth.currentUser.address,
        streetAddress: auth.currentUser.streetAddress,
        stateProvinceRegion: auth.currentUser.stateProvinceRegion,
        city: auth.currentUser.city,
        country: auth.currentUser.country,
      });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    // Update the selected donation option information
    const selectedOptionInfo = campaignData?.donation_options?.find(
      (option) => option.amount.toString() === selectedOption
    );
    setSelectedDonationOption(selectedOptionInfo);
    console.log(selectedOptionInfo);
  }, [
    selectedOption,
    campaignData,
    campaignData.donation_options,
    setSelectedDonationOption,
  ]);
  useEffect(() => {
    if (billingCycle === null && campaignData.is_recurring) {
      setBillingCycle(campaignData.billingCycle[0]);
    }
  }, [campaignData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedAmount <= 0 || selectedAmount === "custom") {
      toast.error("Fill the Donation Amount");
      return;
    }
    if (
      campaignData.is_recurring &&
      campaignData.billingCycle !== undefined &&
      billingCycle === null
    ) {
      toast.error("Select a billing cycle for subscription");
      return;
    }
    if (campaignData.is_recurring && roundEndDate === today) {
      toast.error("Please Select a Subscription End from Future");
      return;
    }
    
    try {
      let newFormData = {
        ...formData,
        selectedAmount,
        quantity,
        selectedDonationOption,
        customAmountEnabled,
        is_recurring: campaignData.is_recurring,
        campaignData,
      };
      if (campaignData.is_recurring) {
        newFormData = {
          ...newFormData,
          billingCycle,
          roundEndDate,
        };
      }

      console.log("newFormData", newFormData);
      addFormData(newFormData);
      toast.success("Your form data has been captured");
      Navigate("/cart");
    } catch (error) {
      console.log('capt', error)
      toast.error("Error capturing payment:", error.message);
    }
  };

  useEffect(() => console.log("billingCycle", billingCycle), [billingCycle]);

  // if (!billingCycle) {
  //   return <div />;
  // }

  return (
    <>
      <section>
        <div className="container">
          <Row className="py-2 mb-4">
            <Col>
              <Form onSubmit={handleSubmit}>
                <div>
                  <h3 className="mb-4 title">Choose Donation Amount</h3>
                  <Form.Group>
                    <Form.Label>Gift Amounts</Form.Label>
                    <div>
                      {campaignData && campaignData?.donation_options && (
                        <>
                          {/* Input field for custom amount */}
                          <Form.Control
                            type="number"
                            value={selectedAmount}
                            onChange={handleAmountChange}
                            disabled={!customAmountEnabled}
                            placeholder="Enter custom amount"
                          />
                          {/* Radio buttons for donation options */}
                          <Row className="mt-3 ">
                            {campaignData.donation_options.map(
                              (option, index) => (
                                <Col sm={3} key={index}>
                                  <FormCheck>
                                    <input
                                      type="radio"
                                      id={`donation-option-${index}`}
                                      value={option.amount.toString()}
                                      checked={
                                        selectedOption ===
                                        option.amount.toString()
                                      }
                                      onChange={handleRadioChange}
                                      style={{ display: "none" }} // Hide the radio button
                                      name="donation-amount"
                                    />
                                    <label
                                      htmlFor={`donation-option-${index}`}
                                      className="pill-btn mb-10"
                                      style={{
                                        backgroundColor:
                                          selectedOption ===
                                          option.amount.toString()
                                            ? "red"
                                            : "white",
                                        color:
                                          selectedOption ===
                                          option.amount.toString()
                                            ? "white"
                                            : "black",
                                        padding: "10px",
                                        width: "100%",
                                        textAlign: "center",
                                        border: "2px solid red",
                                        cursor: "pointer", // Add pointer cursor to indicate clickable
                                      }}
                                    >
                                      ${option.amount}
                                    </label>
                                  </FormCheck>
                                </Col>
                              )
                            )}
                          </Row>

                          {/* Radio button for custom amount */}
                          {campaignData.custom_amount_enabled && (
                            <>
                              <FormCheck>
                                <input
                                  type="radio"
                                  id="custom-amount"
                                  value="custom"
                                  checked={selectedOption === "custom"}
                                  onChange={handleRadioChange}
                                  style={{ display: "none" }} // Hide the radio button
                                  name="donation-amount"
                                />
                                <label
                                  htmlFor="custom-amount"
                                  className="pill-btn"
                                  style={{
                                    backgroundColor:
                                      selectedOption === "custom"
                                        ? "red"
                                        : "white",
                                    color:
                                      selectedOption === "custom"
                                        ? "white"
                                        : "black",
                                    padding: "10px",
                                    width: "100%",
                                    textAlign: "center",
                                    border: "2px solid red",
                                    cursor: "pointer", // Add pointer cursor to indicate clickable
                                  }}
                                >
                                  Custom Amount
                                </label>
                              </FormCheck>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="mt-4">
                  {campaignData.billingCycle && ( // if there is billing cycle than
                    <Form.Group>
                      <h5 className="font-700 wow fadeInUp mb-2 mt-2">
                        This Campaign is only available for Subscription
                      </h5>
                      <p>This payment will be automatically processed based on the selected billing cycle (e.g., every X day/week/month/year) and will continue until the specified date in 'Stop Donation At'.</p>
                      <Row>
                        <Col xs={6}>
                          <label className="form-label">Billing Cyle</label>
                          <select
                            className="form-control"
                            name="billing cycle"
                            value={billingCycle ? billingCycle[0] : ""}
                            onChange={(e) => {
                              setBillingCycle(
                                campaignData.billingCycle[
                                  parseInt(e.target.value)
                                ]
                              );
                            }}
                          >
                            {campaignData.billingCycle.map((cycle, index) => (
                              <option value={index}>
                                Donate Every {cycle.roundCount} {cycle.roundDuration}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col xs={6}>
                          <label className="form-label">Stop Donation At</label>
                          <Form.Control
                            type="date"
                            name="roundEndDate"
                            value={roundEndDate}
                            min={today} // Prevent selecting a date before today
                            onChange={(e) => setRoundEndDate(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  )}
                </div>

                <div className="mt-4">
                  <h3>Enter Donor Information</h3>
                  <FormCheck
                    type="radio"
                    className="mt-3 mb-3"
                    id="organization-behalf"
                    label="Make this gift on behalf of an organization (One-time gifts only)"
                    onChange={handleRadioChange}
                    name="other-options"
                  />
                  <hr />

                  {/*<Row className="mt-3">*/}
                  {/*  <Col xs={6}>*/}
                  {/*    <label className='form-label'>First Name</label>*/}
                  {/*    <input className='form-control' type="text" name="firstName" value={formData.firstName} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={6}>*/}
                  {/*    <label className='form-label'>Last Name</label>*/}
                  {/*    <input className='form-control' type="text" name="lastName" value={formData.lastName} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={12}>*/}
                  {/*    <label className='form-label'>Email</label>*/}
                  {/*    <input className='form-control' type="email" name="email" value={formData.email} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={12}>*/}
                  {/*    <label className='form-label'>Phone</label>*/}
                  {/*    <input className='form-control' type="tel" name="phone" value={formData.phone} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={12}>*/}
                  {/*    <label className='form-label'>Address</label>*/}
                  {/*    <input className='form-control' type="text" name="address" value={formData.address} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={6}>*/}
                  {/*    <label className='form-label'>Street Address</label>*/}
                  {/*    <input className='form-control' type="text" name="streetAddress" value={formData.streetAddress} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={6}>*/}
                  {/*    <label className='form-label'>State / Province / Region</label>*/}
                  {/*    <input className='form-control' type="text" name="stateProvinceRegion" value={formData.stateProvinceRegion} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={6}>*/}
                  {/*    <label className='form-label'>City</label>*/}
                  {/*    <input className='form-control' type="text" name="city" value={formData.city} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  <Col xs={6}>*/}
                  {/*    <label className='form-label'>Country</label>*/}
                  {/*    <input className='form-control' type="text" name="country" value={formData.country} onChange={handleChange} />*/}
                  {/*  </Col>*/}
                  {/*  { campaignData?.quantity_field ===true ? (*/}

                  {/*      <Col xs={6}>*/}
                  {/*    <label className='form-label'>Quantity</label>*/}
                  {/*    <input className='form-control' type="number" name="quantity" value={formData.quantity}*/}
                  {/*           onChange={handleQuantityChange}/>*/}
                  {/*  </Col>):null}*/}
                  {/*  /!*<Col>*!/*/}
                  {/*  /!*  <Form.Label>Credit or Debit Card</Form.Label>*!/*/}
                  {/*  /!*  <CardElement*!/*/}
                  {/*  /!*    className="form-control"*!/*/}
                  {/*  /!*    options={{*!/*/}
                  {/*  /!*      style: {*!/*/}
                  {/*  /!*        base: {*!/*/}
                  {/*  /!*          fontSize: "16px",*!/*/}
                  {/*  /!*          color: "#424770",*!/*/}
                  {/*  /!*          "::placeholder": {*!/*/}
                  {/*  /!*            color: "#aab7c4",*!/*/}
                  {/*  /!*          },*!/*/}
                  {/*  /!*        },*!/*/}
                  {/*  /!*        invalid: {*!/*/}
                  {/*  /!*          color: "#9e2146",*!/*/}
                  {/*  /!*        },*!/*/}
                  {/*  /!*      },*!/*/}
                  {/*  /!*    }}*!/*/}
                  {/*  /!*  />*!/*/}
                  {/*  /!*</Col>*!/*/}
                  {/*</Row>*/}
                  <Row className="mt-3">
                    <Col md={12}>
                      <Form.Group controlId="formPrompt">
                        <Form.Label>
                          What prompted you to donate today?
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter your reason for donating"
                          name="reason"
                          value={formData.reason}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="formDonationMessage" className="mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter a message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="mt-3">
                  <button type="submit" className="btn-primary-fill pill-btn">
                    Add to cart
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
