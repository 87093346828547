// Payment.js
import { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import DonationForm from "./DonationForm";
import { loadStripe } from "@stripe/stripe-js";
import { fetchDataBySlug } from "../../firebase/firebaseFunctions";
import { useParams } from "react-router-dom";
const stripePromise = loadStripe(
  "pk_test_51PJQcsHXm3AIrcKTHKRprNqZTtHGfNiyOyKzVV6R28SjytoZpxwgsxuzR2JGI2nr6yqBa3qAmbCUAadXPwClfO5o00pVf9hd7P"
);

export default function DonationFormParent({ appealData }) {
  const { slug } = useParams();
  const [campaignData, setCampaignData] = useState({});

  // no nedd to fetch appeal data again
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDataBySlug(slug);
      setCampaignData(data);
      console.log(data);
    };
    fetchData();
  }, [slug]);
  return (
    <>
      <Elements stripe={stripePromise}>
        <DonationForm campaignData={appealData} />
      </Elements>
    </>
  );
}
