import React, { useEffect, useState } from "react";
import {
  getAppealDetails,
  getPaymentFromFirebase,
} from "../../firebase/firebaseFunctions";
import DataTable from "../DataTable";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuth } from "../Auth/AuthContext";
import * as XLSX from "xlsx";

export default function Payment() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [appealDetails, setAppealDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  useEffect(() => {
    getPaymentFromFirebase("checkoutDetails", (fetchedData) => {
      console.log("Fetched Data:", fetchedData);
      const processedData = fetchedData.map((item) => ({
        id: item.id,
        userName: `${item.firstName || ""} ${item.lastName || ""}`,
        email: item.email || "",
        date: item.date, // Date extracted from the backend
        time: item.time, // Time extracted from the backend
        paymentGateway: item.paymentGateway
          ? item.paymentGateway.toUpperCase()
          : "",
        totalAmount: item.appealDetails
          ? item.appealDetails.reduce(
              (total, appeal) => total + parseFloat(appeal.amount || 0),
              0
            )
          : 0,
        appealDetails: item.appealDetails || [],
        PaymentTime: item.createdAt || "",
        address: `${item.streetAddress || ""}, ${item.city || ""}, ${
          item.stateProvinceRegion || ""
        }, ${item.country || ""}`,
        phone: item.phone || "",
        userId: item.userId,
      }));

      console.log("time", processedData);
      setData(processedData);
      setLoading(false);
    });
  }, []);

  const columns = [
    { Header: "Payment-Gateway", accessor: "paymentGateway" },
    { Header: "Date", accessor: "date" },
    { Header: "Time", accessor: "time" },
    { Header: "User Name", accessor: "userName" },
    { Header: "User Email", accessor: "email" },
    {
      Header: "Total Amount",
      accessor: "totalAmount",
      Cell: ({ value }) => `$${value.toFixed(2)}`, // Format as currency
    },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) => (
        <Button onClick={() => handleShowAppeals(row.original)}>
          Show Details
        </Button>
      ),
    },
  ];

  const handleShowAppeals = (item) => {
    console.log("Selected Item:", item);
    setSelectedItem(item);
    setAppealDetails([]);

    // Fetch additional details for each appeal
    if (item.appealDetails.length) {
      const detailsPromises = item.appealDetails.map(
        (appeal) =>
          new Promise(
            (resolve) => getAppealDetails(appeal.appealId, resolve) // Fetch details for each appeal
          )
      );

      Promise.all(detailsPromises)
        .then((details) => {
          setAppealDetails(details);
        })
        .catch((error) => {
          console.error("Error fetching appeal details:", error);
        });
    }

    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setAppealDetails([]);
  };

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.role === "user") {
      // Filter the data to include only items where the userId matches the current user's UID
      setData((prevData) =>
        prevData.filter((item) => item.userId === auth.currentUser.uid)
      );
    }
  }, [auth.currentUser, data]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Donations");
    XLSX.writeFile(wb, `Payment.xlsx`);
  };

  return (
    <Row>
      <Col>
        <div className="d-flex">
          <h4>Payment List</h4>
          <Button
            style={{ marginLeft: "auto" }}
            onClick={() => exportToExcel()}
          >
            Export to Excel
          </Button>
        </div>
        <hr />
        {loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <DataTable
            data={data}
            columns={columns}
            onRowClick={(row) => handleShowAppeals(row)}
          />
        )}
      </Col>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem ? (
            <>
            <Row className="mb-4">
              <Col>
                <h5>User Details</h5>
                <div className="d-flex flex-column gap-10">
                  <p className="d-flex justify-content-between text-dark mb-0">
                    <strong>Name:</strong> {selectedItem.userName}
                  </p>
                  <p className="d-flex justify-content-between text-dark mb-0">
                    <strong>Email:</strong> {selectedItem.email}
                  </p>
                  <p className="d-flex justify-content-between text-dark mb-0">
                    <strong>Phone:</strong> {selectedItem.phone}
                  </p>
                  <p className="d-flex justify-content-between text-dark mb-0">
                    <strong>Address:</strong> {selectedItem.address}
                  </p>
                </div>
              </Col>
            </Row>
            <hr/>
            <Row className="mt-4">
              <Col>
                <h5>Appeal Details</h5>
                {appealDetails.length ? (
                  <>
                  <div className="mt-3">
                    {appealDetails.map((appeal, index) => (
                    <>
                    <div key={index} className="d-flex flex-column gap-10">
                      <p className="d-flex justify-content-between text-dark mb-0">
                        <strong>Appeal Name:</strong> {appeal.title}
                      </p>
                      <p className="d-flex justify-content-between text-dark mb-0">
                        <strong>Amount:</strong> ${appeal.donation_options[0]?.amount || "N/A"}
                      </p>
                      <p className="d-flex justify-content-between text-dark mb-0">
                        <strong>Campaign Code:</strong> {appeal.campaign_code}{" "}
                      </p>
                      <p className="d-flex justify-content-between text-dark mb-0">
                        <strong>End Date:</strong> {appeal.end_date}
                      </p>
                      <p className="d-flex justify-content-between text-dark mb-0">
                        <strong>Fund Code:</strong> {appeal.fund_code}
                      </p>
                    </div>
                      
                    </>
                    ))}
                  </div>
                  </>
                  ) : (
                    <p>No appeal details available.</p>
                  )}
              </Col>
            </Row>
            <div>
            </div>
            </>
          ) : (
            <p>Select an item to view details.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}
