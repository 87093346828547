
export const getSendOtpTemplate=(displayName, otp) =>  { 
	return `<!DOCTYPE html>
	<html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
	
	<head>
		<title></title>
		<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
		<meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!-->
		<link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet" type="text/css"><!--<![endif]-->
		<style>
			* {
				box-sizing: border-box;
			}
	
			body {
				margin: 0;
				padding: 0;
			}
	
			a[x-apple-data-detectors] {
				color: inherit !important;
				text-decoration: inherit !important;
			}
	
			#MessageViewBody a {
				color: inherit;
				text-decoration: none;
			}
	
			p {
				line-height: inherit
			}
	
			.desktop_hide,
			.desktop_hide table {
				mso-hide: all;
				display: none;
				max-height: 0px;
				overflow: hidden;
			}
	
			.image_block img+div {
				display: none;
			}
	
			sup,
			sub {
				line-height: 0;
				font-size: 75%;
			}
	
			@media (max-width:700px) {
	
				.desktop_hide table.icons-inner,
				.social_block.desktop_hide .social-table {
					display: inline-block !important;
				}
	
				.icons-inner {
					text-align: center;
				}
	
				.icons-inner td {
					margin: 0 auto;
				}
	
				.mobile_hide {
					display: none;
				}
	
				.row-content {
					width: 100% !important;
				}
	
				.stack .column {
					width: 100%;
					display: block;
				}
	
				.mobile_hide {
					min-height: 0;
					max-height: 0;
					max-width: 0;
					overflow: hidden;
					font-size: 0px;
				}
	
				.desktop_hide,
				.desktop_hide table {
					display: table !important;
					max-height: none !important;
				}
	
				.row-3 .column-1 .block-1.heading_block h3,
				.row-3 .column-1 .block-3.heading_block h3,
				.row-3 .column-1 .block-4.heading_block h3,
				.row-3 .column-1 .block-5.heading_block h3 {
					font-size: 12px !important;
				}
	
				.row-3 .column-1 .block-2.button_block a,
				.row-3 .column-1 .block-2.button_block div,
				.row-3 .column-1 .block-2.button_block span {
					font-size: 16px !important;
					line-height: 32px !important;
				}
	
				.row-2 .column-1,
				.row-4 .column-1 .block-3.social_block td.pad {
					padding: 0 !important;
				}
	
				.row-1 .column-1 {
					padding: 10px 0 !important;
				}
	
				.row-3 .column-1 {
					padding: 20px 20px 30px !important;
				}
	
				.row-4 .column-1 {
					padding: 20px !important;
				}
			}
		</style><!--[if mso ]><style>sup, sub { font-size: 100% !important; } sup { mso-text-raise:10% } sub { mso-text-raise:-10% }</style> <![endif]-->
	</head>
	
	<body class="body" style="background-color: #fafafa; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
		<table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #fafafa;">
			<tbody>
				<tr>
					<td>
						<table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
							<tbody>
								<tr>
									<td>
										<table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 680px; margin: 0 auto;" width="680">
											<tbody>
												<tr>
													<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 20px; padding-top: 20px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
														<div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
														<table class="image_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
																	<div class="alignment" align="center" style="line-height:10px">
																		<div style="max-width: 170px;"><img src="https://humanconcernusa.org/wp-content/uploads/2023/01/USA-LOGO-2048x532.png" style="display: block; height: auto; border: 0; width: 100%;" width="170" height="auto"></div>
																	</div>
																</td>
															</tr>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
							<tbody>
								<tr>
									<td>
										<table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 680px; margin: 0 auto;" width="680">
											<tbody>
												<tr>
													<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 20px; padding-top: 20px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
														<div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
														<table class="image_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
																	<div class="alignment" align="center" style="line-height:10px">
																		<div style="max-width: 102px;"><img src="https://0a860f4f0e.imgdist.com/pub/bfra/65fct4bx/rrj/uui/gc1/padlock.png" style="display: block; height: auto; border: 0; width: 100%;" width="102" height="auto"></div>
																	</div>
																</td>
															</tr>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
							<tbody>
								<tr>
									<td>
										<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 680px; margin: 0 auto;" width="680">
											<tbody>
												<tr>
													<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 30px; padding-left: 50px; padding-right: 50px; padding-top: 40px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
														<table class="heading_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="padding-bottom:10px;padding-top:15px;text-align:center;width:100%;">
																	<h3 style="margin: 0; color: #000000; direction: ltr; font-family: Poppins, Arial, Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: normal; line-height: 180%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 28.8px;"><span class="tinyMce-placeholder" style="word-break: break-word;"><strong>Dear ${displayName},</strong><br>Thank you for taking the next step with Human Concern USA. To ensure the security of your account, please use the following One-Time Password (OTP) to complete your login:<br><br>Your OTP is:<br></span></h3>
																</td>
															</tr>
														</table>
														<table class="button_block block-2" width="100%" border="0" cellpadding="10" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad">
																	<div class="alignment" align="center"><!--[if mso]>
	<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:56px;width:194px;v-text-anchor:middle;" arcsize="8%" stroke="false" fillcolor="#e32226">
	<w:anchorlock/>
	<v:textbox inset="0px,0px,0px,0px">
	<center dir="false" style="color:#ffffff;font-family:Arial, sans-serif;font-size:23px">
	<![endif]-->
																		<div style="background-color:#e32226;border-bottom:0px solid transparent;border-left:0px solid transparent;border-radius:4px;border-right:0px solid transparent;border-top:0px solid transparent;color:#ffffff;display:inline-block;font-family:Poppins, Arial, Helvetica, sans-serif;font-size:23px;font-weight:400;mso-border-alt:none;padding-bottom:5px;padding-top:5px;text-align:center;text-decoration:none;width:auto;word-break:keep-all;"><span style="word-break: break-word; padding-left: 60px; padding-right: 60px; font-size: 23px; display: inline-block; letter-spacing: normal;"><span style="word-break: break-word; line-height: 46px;">${otp}</span></span></div><!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
																	</div>
																</td>
															</tr>
														</table>
														<table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="padding-bottom:10px;padding-top:5px;text-align:center;width:100%;">
																	<h3 style="margin: 0; color: #000000; direction: ltr; font-family: Poppins, Arial, Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: normal; line-height: 180%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 28.8px;"><span class="tinyMce-placeholder" style="word-break: break-word;">Please do not share this code with anyone. If you did not request this OTP, please contact our support team immediately.<br><br>For your safety, always ensure you are accessing our services through secure and trusted devices.</span></h3>
																</td>
															</tr>
														</table>
														<table class="heading_block block-4" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="padding-top:15px;text-align:center;width:100%;">
																	<h3 style="margin: 0; color: #000000; direction: ltr; font-family: Poppins, Arial, Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: normal; line-height: 180%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 28.8px;"><span class="tinyMce-placeholder" style="word-break: break-word;"><strong>Thank You,</strong><br></span></h3>
																</td>
															</tr>
														</table>
														<table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="padding-bottom:5px;padding-top:5px;text-align:center;width:100%;">
																	<h3 style="margin: 0; color: #000000; direction: ltr; font-family: Poppins, Arial, Helvetica, sans-serif; font-size: 16px; font-weight: 400; letter-spacing: normal; line-height: 180%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 28.8px;"><span class="tinyMce-placeholder" style="word-break: break-word;"><a href="tel:18005835841" target="_blank" title="tel:18005835841" rel="noopener" style="text-decoration: underline; color: #0076da;">1800-583-5841</a><br><a href="mailto:info@humanconcernusa.org" target="_blank" title="info@humanconcernusa.org" rel="noopener" style="text-decoration: underline; color: #0076da;">info@humanconcernusa.org</a><br><a href="https://www.humanconcernusa.org" target="_blank" rel="noopener" style="text-decoration: underline; color: #0076da;">https://www.humanconcernusa.org</a></span></h3>
																</td>
															</tr>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						<table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
							<tbody>
								<tr>
									<td>
										<table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 680px; margin: 0 auto;" width="680">
											<tbody>
												<tr>
													<td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 20px; padding-top: 20px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
														<table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
																	<div class="alignment" align="center" style="line-height:10px">
																		<div style="max-width: 170px;"><img src="https://humanconcernusa.org/wp-content/uploads/2023/01/USA-LOGO-2048x532.png" style="display: block; height: auto; border: 0; width: 100%;" width="170" height="auto"></div>
																	</div>
																</td>
															</tr>
														</table>
														<table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
															<tr>
																<td class="pad" style="padding-bottom:10px;padding-left:30px;padding-right:30px;padding-top:15px;">
																	<div style="color:#000000;font-family:Poppins, Arial, Helvetica, sans-serif;font-size:10px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
																		<p style="margin: 0; word-break: break-word;">Fighting poverty for over 40 years. HUMAN CONCERN USA is a 501(C)3 Tax Exempt Nonprofit our EIN# 92-2388570</p>
																	</div>
																</td>
															</tr>
														</table>
														<table class="social_block block-3" width="100%" border="0" cellpadding="5" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
															<tr>
																<td class="pad">
																	<div class="alignment" align="center">
																		<table class="social-table" width="180px" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block;">
																			<tr>
																				<td style="padding:0 2px 0 2px;"><a href="https://www.facebook.com/HumanConcernUSA" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png" width="32" height="auto" alt="Facebook" title="facebook" style="display: block; height: auto; border: 0;"></a></td>
																				<td style="padding:0 2px 0 2px;"><a href="https://www.instagram.com/humanconcernusa" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png" width="32" height="auto" alt="Instagram" title="instagram" style="display: block; height: auto; border: 0;"></a></td>
																				<td style="padding:0 2px 0 2px;"><a href="https://www.linkedin.com/company/hci-usa/" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/linkedin@2x.png" width="32" height="auto" alt="Linkedin" title="linkedin" style="display: block; height: auto; border: 0;"></a></td>
																				<td style="padding:0 2px 0 2px;"><a href="https://twitter.com/hci_usa" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/twitter@2x.png" width="32" height="auto" alt="Twitter" title="Twitter" style="display: block; height: auto; border: 0;"></a></td>
																				<td style="padding:0 2px 0 2px;"><a href="https://humanconcernusa.org" target="_blank"><img src="https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/website@2x.png" width="32" height="auto" alt="Web Site" title="Web Site" style="display: block; height: auto; border: 0;"></a></td>
																			</tr>
																		</table>
																	</div>
																</td>
															</tr>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						
					</td>
				</tr>
			</tbody>
		</table><!-- End -->
	</body>
	
	</html>`
	}
	