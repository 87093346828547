import React, { useEffect } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function TravelWithUs() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://form.jotform.com/jsform/243016207160039";
    script.async = true;

    const jotformContainer = document.querySelector('.jotform-container');
    
    if (jotformContainer) {
      jotformContainer.appendChild(script);
    }

    return () => {
      const container = document.querySelector('.jotform-container');
      if (container && script) {
        container.removeChild(script);
      }
    };
  }, []);

    const [key, setKey] = React.useState('home');
  return (
    <>
      <section>
        <div className="single-slider mt-4 mb-4 px-2">
          <div className="container">
            <div className="row justify-content-between inner-hero" style={{ backgroundImage: "url('/images/about.webp')" }}>
              <div className="col-12">
                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">Travel With Us</h3>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-12 col-xl-12 col-lg-12 my-auto">
                <div className=" mb-20">
                  <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">Discover a <span className='text-red'>Transformative</span> Experience to Learn, Understand, and Give Back</h1>
                  <p className="pera wow fadeInUp mt-3" data-wow-delay="0.3s">
                  Our program invites community members to embark on a life-changing journey with Human Concern USA, offering a unique opportunity to contribute to humanitarian efforts in developing regions. Participants will work closely with our dedicated staff to implement and sustain impactful projects in these communities. Central to our approach is the principle of Ihsan—striving for excellence in every action while fostering a deep commitment to ethical and compassionate engagement.
                  </p>
                  <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  This journey goes beyond successful fundraising; it is an immersive experience that embodies the values of Ihsan, encouraging participants to engage with mindfulness and dedication, thereby making a meaningful difference in the lives we touch.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>

    <section class="helpful-area padding-bottom mb-50">
        <div class="container">
          <div className="row justify-content-start">
            <div className="col-xl-7">
              <div className="section-tittle text-left">
                <h2 className="font-700 wow fadeInUp" data-wow-delay="0.1s">How It <span className='text-red'>Works</span></h2>
              </div>
            </div>
          </div>
          <div class="row gy-24 mb-4 mt-4">
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-plant-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h6 class="text-secondary text-uppercase mt-1 mb-1 text-center">Step 1</h6>
                  <h4 class="caption-title text-center">Initial Contact</h4>
                  <p class="caption-para text-center">Our team will personally reach out to you to establish a connection and discuss your involvement.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-plant-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h6 class="text-secondary text-uppercase mt-1 mb-1 text-center">Step 2</h6>
                  <h4 class="caption-title text-center">Set Fundraising Goal</h4>
                  <p class="caption-para text-center">You will receive a specific fundraising target to achieve before your chosen trip.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-plant-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h6 class="text-secondary text-uppercase mt-1 mb-1 text-center">Step 3</h6>
                  <h4 class="caption-title text-center">Coaching and Training</h4>
                  <p class="caption-para text-center">We will provide personalized coaching and training on effective fundraising strategies to help you meet your goal.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-lg-6 my-2">
              <div class="helpful-card h-calc wow fadeInLeft d-flex flex-column align-items-center" data-wow-delay="0.2s" >
                <div class="helpful-card-icon">
                <i class="ri-plant-fill"></i>
                </div>
                <div class="helpful-card-caption">
                  <h6 class="text-secondary text-uppercase mt-1 mb-1 text-center">Step 4</h6>
                  <h4 class="caption-title text-center">Trip Planning</h4>
                  <p class="caption-para text-center">Once you reach your fundraising target, we will proceed with further discussions to finalize your trip details.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="helpful-area padding-bottom mb-50">
        <div class="container">
          <div className="row justify-content-start">
            <div className="col-xl-12">
            <div className="jotform-container"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
