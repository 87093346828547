import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DataTable from '../DataTable';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';
import { useNavigate } from 'react-router-dom';

export default function AdminGroups() {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('Blog', (blogs) => {
            console.log('blogs:', blogs);
            setBlogs(blogs);
        });
    }, []);

    const handleRow =(blog) => {
        console.log('on blog click', blog);
        navigate(`/dashboard/blog/${blog.id}`)
    }


    const columns = [
        {
            Header: 'Blog title',
            accessor: 'blogtitle', // accessor is the "key" in the data
        },
        {
            Header: 'Blog Description',
            accessor: 'blogDescription', // accessor is the "key" in the data
        },
    ];

    return (
    <>
    <Row>
        <Col>
            <div className='d-flex justify-content-between align-items-center'>
                <h4 className="mb-0">Blogs</h4>
                <Button style={{textAlignLast:"end"}} onClick={()=>{navigate('/dashboard/blog')}}>Add Blog</Button>
            </div>
            <hr/>
        </Col>
    </Row>
    <Row>
        <Col>
            <DataTable columns={columns} data={blogs} onRowClick={handleRow} />
        </Col>
    </Row>
    </>
    )
}
