import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeroSlider = ({ slides = [], mobileSlides = [] }) => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  // Detect if the screen size is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1, // Only show one image at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500, // Auto slide every 3.5 seconds
    arrows: false,
    pauseOnHover: true,
    pauseOnFocus: true
  };

  const handleSlideClick = (url) => {
    if (url) {
      navigate(url); // Redirect to the URL when an image is clicked
    }
  };

  // Determine which slides to use based on screen size
  const currentSlides = isMobile ? mobileSlides : slides;

  if (!currentSlides || currentSlides.length === 0) {
    return null;
  }

  return (
    <div className="hero-slider" style={{ cursor: 'pointer' }}>
      <Slider {...settings}>
        {currentSlides.map((slide, index) => (
          <div
            key={index}
            className="slide"
            onClick={() => handleSlideClick(slide.url)}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={slide.image}
              alt={`slide-${index}`}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;
