import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDataFromFirebase } from '../../firebase/firebaseFunctions';

export default function Blogs() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 1; // Adjust this value to set the number of blogs per page

  useEffect(() => {
    getDataFromFirebase('Blog', (blogs) => setBlogs(blogs));
  }, []);

  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const navigateToBlog = (blog) => {
    navigate(`/blog/single/${blog.id}`);
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  return (
    <>
      <section className='mt-4 p-2'>
        <div className="single-slider">
          <div className="container">
            <div className="row justify-content-between inner-hero blog-hero" style={{ backgroundImage: "url('/images/blogs.webp')", backgroundPosition: '0 -160px' }}>
              <div className="col-12">
                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">Blogs</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-section-three my-4 py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <span className="sub-tittle text-capitalize font-600">
                  Blogs & News
                </span>
                <h2 className="title font-700 text-capitalize">
                Authentic Voices, <span className="text-red">Real Narratives</span>.
                </h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                Meet the faces behind the tales and see how your kind donations transform lives!
 
From educating underprivileged children to offering medical support to ailing elders – this blog shines a light on the ripple effect of generosity. Dive into these inspiring stories and witness the profound difference your giving makes.
                </p>
              </div>
            </div>
          </div>
          <div className="row donate-section gy-24 gap-x-25">
            {currentBlogs.length > 0 ? (
              currentBlogs.map((blog, index) => (
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper" key={index}>
                  <div className="single-donate h-calc">
                    <div className="donate-img position-relative">
                      <img
                        className="w-100"
                        src={blog.blogImage}
                        alt="Blog"
                      />
                    </div>
                    <div className="donate-info">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="donate-info-title w-100">
                          <h4 className="title text-capitalize mb-1">
                            {blog.blogtitle}
                          </h4>
                          <p className="subtitle mb-0">
                            {blog.blogDescription.length > 70
                              ? `${blog.blogDescription.substring(0, 70)}...`
                              : blog.blogDescription}
                          </p>
                          <a
                            onClick={() => navigateToBlog(blog)} target='_blank'
                            className="btn btn-primary text-white donate-btn w-100 mt-15"
                          >
                            View Blog
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="row justify-content-center">
                <div className="col-xl-7">
                  <p className="text-center">No blogs available at this time.</p>
                </div>
              </div>
            )}
          </div>

          {/* Render pagination only if there are blogs */}
          {blogs.length > 0 && (
            <div style={{ width: "100%" }}>
              <p style={{ width: "100%", textAlign: "center", marginTop: "50px", color: "black", fontWeight: "bold" }}>
                Page {currentPage} of {totalPages}
              </p>
              <nav className="pagination-nav">
                <ul className="pagination">
                  {currentPage !== 1 &&
                    <li className="page-item" onClick={handlePreviousPage}>
                      <a className="page-link next" href="javascript:void(0)" rel="next" aria-label="Next »"><i className="ri-arrow-left-line"></i></a>
                    </li>
                  }
                  {[...Array(totalPages).keys()].map(page => (
                    <li key={page + 1} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageClick(page + 1)}>
                        {page + 1}
                      </button>
                    </li>
                  ))}
                  {currentPage !== totalPages &&
                    <li className="page-item" onClick={handleNextPage}>
                      <a className="page-link next" href="javascript:void(0)" rel="next" aria-label="Next »"><i className="ri-arrow-right-line"></i></a>
                    </li>
                  }
                </ul>
              </nav>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
