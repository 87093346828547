import React from 'react'
import animationData from '../../loading.json';
import Lottie from 'lottie-react';
import { Row, Col } from 'react-bootstrap';

export default function LoadingPage() {
  return (
    <>
        <Row style={{height: '50vh'}}>
            <Col className='d-flex justify-content-center align-items-center p-0'>
                <Lottie 
                    animationData={animationData} 
                    loop={true} 
                    autoplay={true} 
                    style={{ width: 150, height: 150 }} 
                />
            </Col>
        </Row>
    </>
  )
}
