import { Col, Row } from "react-bootstrap";
import DataTable from "../DataTable";
import { useEffect, useState } from "react";
import { getDataFromFirebase, updateFieldsInFirebase } from "../../firebase/firebaseFunctions";
import './Tickets.css';
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Tickets() {
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [statusOptions] = useState(['unanswered', 'answered', 'cancel', 'paused']);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for descending, 'asc' for ascending
    const navigate = useNavigate();

    useEffect(() => {
        getDataFromFirebase('tickets', (fetchedTickets) => {
            const sortedTickets = sortTickets(fetchedTickets, sortOrder);
            setTickets(sortedTickets);
            filterTickets(sortedTickets, startDate, endDate);
        });
    }, [sortOrder, startDate, endDate]);

    const sortTickets = (tickets, order) => {
        return tickets.sort((a, b) => {
            const dateA = new Date(a.timestamp || 0);
            const dateB = new Date(b.timestamp || 0);
            return order === 'desc' ? dateB - dateA : dateA - dateB; // Sort based on order
        });
    };

    const filterTickets = (tickets, start, end) => {
        let filtered = tickets;
        if (start) {
            filtered = filtered.filter(ticket => new Date(ticket.timestamp) >= new Date(start));
        }
        if (end) {
            filtered = filtered.filter(ticket => new Date(ticket.timestamp) <= new Date(end));
        }
        setFilteredTickets(filtered);
    };

    const handleStatusChange = async (ticketId, status) => {
        try {
            // Update the ticket's status in Firebase
            await updateFieldsInFirebase(`tickets/${ticketId}`, { status: status });
            alert('Status updated successfully!');
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Failed to update status.');
        }
    }

    const handleRow = (ticket) => {
        navigate(`/dashboard/tickets/${ticket.id}`);
    }

    useEffect(() => {
        filterTickets(tickets, startDate, endDate);
    }, [tickets, startDate, endDate]);

    // Define columns for DataTable
    const columns = [
        {
            Header: 'Subject',
            accessor: 'subject',
            Cell: ({ row }) => (
                <span style={{ cursor: 'pointer' }} onClick={() => handleRow(row.original)}>
                    {row.original.subject}
                </span>
            ),
        },
        {
            Header: 'Priority',
            accessor: 'priority',
        },
        {
            Header: 'Department',
            accessor: 'department',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <select
                        style={{ padding: "6px 16px", textAlign: 'center', borderColor: "#C2C2CB", textTransform: 'capitalize' }}
                        className={
                            row.original.status === 'cancel' ? 'cancel'
                            : row.original.status === 'answered' ? 'answered'
                            : row.original.status === 'paused' ? 'paused'
                            : 'unanswered'
                        }
                        value={row.original.status || ''}
                        onChange={(e) => handleStatusChange(row.original.id, e.target.value)}
                    >
                        <option value="" disabled>Select Status</option>
                        {statusOptions.map((status) => (
                            <option key={status} value={status}>{status}</option>
                        ))}
                    </select>
                </div>
            ),
        },
    ];

    return (
        <div>
            <Row className="mb-3">
                <Col sm={12}>
                    <div className="d-flex">
                        <h4 className="mb-3 mb-md-0">Projects Overview</h4>
                        <div className="d-flex" style={{ marginLeft: "auto" }}>
                            <div>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="form-control"
                                    placeholderText="Start Date"
                                />
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    className="form-control"
                                    placeholderText="End Date"
                                />
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <select
                                    value={sortOrder}
                                    onChange={(e) => setSortOrder(e.target.value)}
                                    className="form-control"
                                >
                                    <option value="desc">Newest First</option>
                                    <option value="asc">Oldest First</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <DataTable
                        data={filteredTickets}
                        columns={columns}
                        onRowClick={handleRow}
                    />
                </Col>
            </Row>
        </div>
    );
}
