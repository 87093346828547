// Payment.js
import {useState, useEffect} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CustomDonationForm from './CustomDonationForm';

const stripePromise = loadStripe('pk_test_51PJQcsHXm3AIrcKTHKRprNqZTtHGfNiyOyKzVV6R28SjytoZpxwgsxuzR2JGI2nr6yqBa3qAmbCUAadXPwClfO5o00pVf9hd7P');


export default function CustomDonationFormParent() {
  return (
    <>
      <Elements stripe={stripePromise}>
        <CustomDonationForm/>
      </Elements>
    </>
  );
}
