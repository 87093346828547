import React, { useEffect, useState } from "react";
import { Form, useNavigate, useParams } from "react-router-dom";
import { Table, Button, Row, Col } from "react-bootstrap";
import {
  _getDataFromFirebase,
  addDataToFirebase,
  fetchDataBySlug,
  getDataFromFirebase,
  updateFieldsInFirebase,
} from "../../firebase/firebaseFunctions";
import * as XLSX from "xlsx";
import DataTable from "../DataTable";

export default function DonorDetailsPage() {
  const { id, donorEmail } = useParams();
  const [donations, setDonations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    isActive: "",
  });
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const params = useParams();
  console.log("params:", params);

  useEffect(() => {
    const fetchDonations = async () => {
      const donationsReferencePath = "donation_payments";
      const campaignsReferencePath = "campaigns";
      const projectsReferencePath = "projects";

      // Fetch donations data
      _getDataFromFirebase(donationsReferencePath, (donationsData) => {
        // Filter donations for the specific donor
        console.log(
          "donations data:",
          donationsData,
          "donor email:",
          donorEmail
        );
        const donorDonations = donationsData.filter(
          (donation) => donation.userId === id
        );
        console.log("donor donations:", donorDonations);

        // Fetch campaigns data
        _getDataFromFirebase(campaignsReferencePath, (campaignsData) => {
          // Fetch projects data
          getDataFromFirebase(projectsReferencePath, (projectsData) => {
            getDataFromFirebase("appeals", (appealData) => {
              const campaignsMap = new Map(
                campaignsData.map((campaign) => [campaign.id, campaign])
              );
              const projectsMap = new Map(
                projectsData.map((project) => [project.id, project])
              );
              const appealsMap = new Map(
                appealData.map((appeal) => [appeal.id, appeal])
              );

              const donationsWithDetails = donorDonations.map((donation) => {
                const campaign = campaignsMap.get(
                  donation.campaignData.campaign_code
                );
                const project = projectsMap.get(
                  donation.campaignData.fund_code
                );
                const appeal = appealsMap.get(donation.campaignData.id);
                return {
                  ...donation,
                  projectName: project ? project["project-name"] : "Unknown",
                  campaignName: campaign
                    ? campaign["campaign-name"]
                    : "Unknown",
                  appealName: appeal ? appeal.title : "Unknown",
                };
              });

              console.log("donationsWithDetails", donationsWithDetails);
              setDonations(donationsWithDetails);
            });
          });
        });
      });
    };

    fetchDonations();
  }, [donorEmail]);

  useEffect(() => {
    getDataFromFirebase("roles", (rolesArray) => {
      const updatedRoles = rolesArray.map((role) => role.roleName);
      setRoles(updatedRoles);
    });
  }, []);

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      donations.map((donation) => ({
        Project: donation.projectName,
        Campaign: donation.campaignName,
        Appeal: donation.appealName,
        DonationAmount: parseFloat(donation.amount).toFixed(2),
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Donations");
    XLSX.writeFile(workbook, `Donations_${donorEmail}.xlsx`);
  };

  const columns = [
    {
      Header: "Project",
      accessor: "projectName", // accessor is the "key" in the data
    },
    {
      Header: "Campaign",
      accessor: "campaignName", // accessor is the "key" in the data
    },
    {
      Header: "Appeal",
      accessor: "appealName", // accessor is the "key" in the data
    },
    {
      Header: "Donation Amount",
      accessor: "amount", // accessor is the "key" in the data
    },
  ];
  const handleRow = (donor) => {
    // navigate(`/dashboard/donor-details/${donor.email}`)
  };
  const handleCheckbox = () => {};

  const handleEdit = () => {
    const { firstName, lastName, email, role, phone, isActive } = formData;
    if (firstName && lastName && email && phone) {
      if (params.id) {
        updateFieldsInFirebase(`users/${id}`, formData);
      } else {
        addDataToFirebase("users", formData);
      }

      navigate("/dashboard/users-signup");
    } else {
      setError("Please fill all fields");
    }
  };
  useEffect(() => {
    console.log("roles here", roles);
  }, [roles]);

  useEffect(() => {
    if (id) {
      getDataFromFirebase("users", (users) => {
        const requiredUser = users.find((user) => user.id === id);
        console.log("required user:", requiredUser);
        setFormData(requiredUser);
      });
    }
  }, [params]);

  useEffect(() => {
    console.log("form data:", formData);
  }, [formData]);

  const handleRole = (event) => {
    console.log("value", event.target.value);
    setFormData({ ...formData, role: event.target.value });
  };

  return (
    <>
      <form onSubmit={handleEdit}>
        <Row className="mb-3">
          <h3>{params.id ? "Update User" : "Add User"}</h3>
          <hr />
        </Row>
        <Row className="gap-3">
          <Col xs={12}>
            <label className="form-label">First Name:</label>
            <input
              className="form-control"
              type="text"
              placeholder="eg. Ali"
              name="firstName"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              required
            />
          </Col>
          <Col xs={12}>
            <label className="form-label">Last Name:</label>
            <input
              className="form-control"
              type="text"
              placeholder="eg. Ahmed"
              name="lastName"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              required
            />
          </Col>
          <Col xs={12}>
            <label className="form-label">Phone Number:</label>
            <input
              className="form-control"
              type="text"
              placeholder="eg. +1 123 456 7890"
              name="phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              required
            />
          </Col>
          <Col xs={12}>
            <label className="form-label">Email Address:</label>
            <input
              className="form-control"
              type="email"
              placeholder="eg. ron@xyz.com"
              name="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.value })
              }
              required
            />
          </Col>
          <Col xs={12}>
            <label className="form-label">Role:</label>
            <div>
              <select
                value={formData.role}
                onChange={handleRole}
                className="form-control"
              >
                <option value="" disabled>
                  Select role
                </option>
                {roles.length > 0 &&
                  roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
              </select>
            </div>
          </Col>
          <Col xs={12} className="d-flex align-items-center gap-2">
            <label className="form-label mb-0">Status:</label>
            <input
              type="checkbox"
              name="isActive"
              checked={formData.isActive}
              onChange={(e) =>
                setFormData({ ...formData, [e.target.name]: e.target.checked })
              }
            ></input>
          </Col>
          {error && (
            <Col xs={12}>
              <span style={{ color: "red" }}>{error}</span>
            </Col>
          )}

          <Col xs={12}>
            <div className="d-flex">
              <Button onClick={handleEdit}>
                {params.id ? "Edit User" : "Add User"}{" "}
              </Button>
              <Button
                style={{ marginLeft: "15px" }}
                onClick={() => {
                  navigate("/dashboard/users-signup");
                }}
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
        <div></div>
      </form>
      {params.id && (
        <div>
          <h4 className="mb-3 mb-md-0">Donations by {donorEmail}</h4>
          <div style={{ textAlignLast: "end", marginBottom: "15px" }}>
            <Button variant="primary" onClick={exportToExcel}>
              Export to Excel
            </Button>
          </div>
          <hr />

          <DataTable
            data={donations}
            columns={columns}
            onRowClick={handleRow}
          />
        </div>
      )}
    </>
  );
}
