import React from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FaHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function About() {
  const [key, setKey] = React.useState('home');

  return (
    <>
      <section>
        <div className="single-slider mt-4 mb-4 px-2">
          <div className="container">
            <div className="row justify-content-between inner-hero" style={{ backgroundImage: "url('/images/about.webp')" }}>
              <div className="col-12">
                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">Who Are We?</h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero-area">
        <div className="single-slider mt-4 mb-4">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xxl-12 col-xl-12 col-lg-12 my-auto mb-4 pb-4">
                <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 d-flex justify-content-center gap-2 bg-light br-100 about-tabs">
                  <Tab eventKey="home" title="Who Are We?" className='about-tab'>
                    <>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center align-items-center flex-column text-center'>
                                <h2 className='heading-main'>Decades of <span className='text-red'>Combating</span> Poverty & Providing Aid.</h2>
                                <p className='text-para'>
                                  In 1983, a group of compassionate individuals founded one of the first Muslim relief organizations in North America, setting the stage for our impactful journey. Having disbursed hundreds of millions in aid, sponsored thousands of orphans, and saved countless lives during catastrophes, we take pride in our accomplishments. Yet, we recognize that our mission is far from complete. Guided by our core vision, mission, and values, we remain steadfast in our pursuit of a more just and equitable world.
                                </p>
                            </div>
                        </div>
                        <section className="hero-area">
                          <div className="single-slider hero-padding">
                            <div className="container">
                              <div className="row justify-content-between align-items-center mobile-reverse">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 p-m-0">
                                  <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">Embrace Our <span className='text-red'>Vision</span>, Be Part of the <span className='text-red'>Cause</span>.</h1>
                                  <p className="wow fadeInUp mb-0" data-wow-delay="0.3s">
                                  For more than four decades, our leaders, team members, volunteers, and supporters have united under a common goal – to create a world free of poverty and injustice. This is no small feat, yet, as stewards of God’s creation, we must strive for nothing less.
                                  <br/>
                                  Whether responding to emergencies or assessing global needs, we are on a mission to transition families from crisis to self-sufficiency.
                                  Our aim isn’t to foster dependence on aid, but to empower communities to thrive independently.
                                  </p>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 mb-4 p-m-0">
                                  <div className="d-flex gap-44">
                                    <img src="/images/who-are-we.webp" alt="img" className="w-100 tilt-effect wow fadeInUp mt-20" data-wow-delay="0.1s" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="hero-area">
                          <div className="single-slider hero-padding">
                            <div className="container">
                              <div className="row justify-content-between align-items-center">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 p-m-0">
                                  <div className="d-flex gap-44">
                                    <img src="/images/who-are-we-2.webp" alt="img" className="w-100 tilt-effect wow fadeInUp mt-20" data-wow-delay="0.1s" />
                                  </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 mt-4 p-m-0">
                                  <h1 className="font-700 wow fadeInUp" data-wow-delay="0.1s">Our Guiding <span className='text-red'>Principles</span>.</h1>
                                  <p className="wow fadeInUp" data-wow-delay="0.3s">
                                    <b>Embracing and Uplifting:</b> We value inclusion and foster a culture where everyone can thrive.
                                  </p>
                                  <p className="wow fadeInUp" data-wow-delay="0.3s">
                                    <b>Respect and Compassion:</b> We believe in the inherent worth of every individual and strive to show love and respect to help them reach their full potential.
                                  </p>
                                  <p className="wow fadeInUp" data-wow-delay="0.3s">
                                    <b>Advocacy for Fairness:</b> We work to empower communities to challenge and change structures of injustice and inequality, both locally and globally.
                                  </p>
                                  <p className="wow fadeInUp" data-wow-delay="0.3s">
                                    <b>Integrity and Openness:</b> We are committed to being an accountable organization that values transparency and honesty in all our interactions.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section class="helpful-area mb-4">
                          <div>
                            <div className="row justify-content-start">
                              <div className="col-xl-12">
                                <div className="section-tittle mb-50">
                                  <span className="sub-tittle text-capitalize font-600">Empowering Lives</span>
                                  <h2 className="title font-700">Over <span className='text-red'>40 Decades</span>, Across <span className='text-red'>40 Countries</span>.</h2>
                                  <p>Throughout its history, Human Concern USA has led numerous relief initiatives in the wake of natural disasters, conflicts, and other crises. These efforts encompass distributing vital necessities like food, shelter, and medical equipment, along with executing long-term developmental programs that empower communities to fortify their resilience and achieve self-sufficiency. In addition, Human Concern USA places a strong emphasis on collaborating with local partners to guarantee the efficient and effective delivery of aid. Through its relief efforts, Human Concern USA has significantly improved the lives of numerous individuals and communities grappling with challenges and hardships.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                    </>
                  </Tab>
                  <Tab eventKey="profile" title="Our Approach" className='about-tab'>
                  <>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center align-items-start flex-column text-left'>
                            <h2 className='heading-main mb-20'>Our Global <span className='text-red'>Strategy</span> to Combat Poverty.</h2>
                            <p className='text-para'>
                            At Human Concern USA, we are dedicated to uplifting communities and individuals across the globe by creating avenues for sustainable transformation and progress. Our strategy begins with conducting needs assessments in collaboration with our partners, ensuring active community participation. Engaging with local communities is essential for designing and implementing successful programs. Their insights and guidance help us identify the most effective solutions.
Leveraging our vast network of partners, our goal is to enhance healthcare services, educational programs, economic opportunities, and access to clean water. After identifying solutions, we mobilize resources to address issues and align with the United Nations’ SDG goals. Our team conducts on-site visits, maintains frequent communication, and oversees project activities to ensure objectives are met. Detailed financial reports from our partners allow us to transparently share the impact of your donations, empowering communities with the tools they need to thrive.
Your generous donations are the catalyst for this transformative work. Together, we can forge a path toward a global future where poverty is eradicated and prosperity abounds for all.
                            </p>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12 d-flex justify-content-center align-items-start flex-column text-left'>
                            <h2 className='heading-main mb-20'>Innovative, Eco-Friendly <span className='text-red'>Solutions</span>.</h2>
                            <p className='text-para'>
                            Lasting change arises from tackling the root causes of poverty in communities. Through research, field studies, and dialogues with locals, we pinpoint the programs that will be most impactful. Investing in education, healthcare, clean water, skills training, and agriculture, Human Concern USA empowers communities to break free from the cycle of poverty.
Our approach includes identifying solutions that address specific needs and align with the United Nations’ SDG goals. We focus on creating innovative and eco-friendly solutions that foster self-reliance and prosperity. Your support today ensures a lasting positive impact for future generations.
</p>
                        </div>
                    </div>
                    </>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
