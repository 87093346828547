import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col,  Row } from 'react-bootstrap';
import { addDataToFirebase, getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import axios from 'axios';

function AddUpdateBlog() {
    const [formData, setFormData] = useState({ title: "", description: "" });
    const [blogs, setBlogs] = useState([]);
    const [error, setError] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(()=>{
        getDataFromFirebase('Blog', (blogs)=>setBlogs(blogs));
    },[])
 
    useEffect(() => {
        if (params.id) {
            let requiredBlog = {}
            if (blogs.length > 0) {
                requiredBlog = blogs.find(blog => blog.id === params.id);
            }
            if (requiredBlog) {
                console.log('required blog----------->', requiredBlog);
                const blog = { title: requiredBlog.blogtitle, description: requiredBlog.blogDescription, blogImage: requiredBlog.blogImage }
                setFormData(blog)
            }
        }
    }, [params.id,blogs]);

    useEffect(()=>{
        console.log('form data--------------->', formData);
    },[formData])

    const uploadImage = (formData) => {
        console.log('image upload api called----------->', formData)
        return axios.post('https://hci-webapp.onrender.com/upload-images', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      };

    const handleImage = (event) => {
        const file = event.target.files[0];
        setImageFile(file)
    }

    const handleBlog = async (e) => {
        e.preventDefault();
        try {
            // Fetch user data from Firebase based on email
            if (formData.title && formData.description ) {
                let blogObject = { blogtitle: formData.title, blogDescription: formData.description, comments:"[]" }
                if (imageFile !== null) {
                    const formData = new FormData();
                    formData.append("files", imageFile);
                    const response = await  uploadImage(formData);
                    if (response.data.success) {
                        const fileName = response.data.fileNames;
                        blogObject = {
                          ...blogObject,
                          blogImage: `https://hci-webapp.onrender.com/images/${fileName[0]}`,
                        };
                    } else {
                        // Throw an error if the file upload failed
                        throw new Error("File upload failed");
                    }
                }
                if (params.id) {
                    await updateFieldsInFirebase(`Blog/${params.id}`, blogObject);
                }
                else {

                    await addDataToFirebase('Blog', blogObject);
                }
                navigate('/dashboard/blogs')
                setError('');
            }
            else {
                setError('Please fill all fields')
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            toast.error('Error fetching user data');
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col>  
                            <h1 className='title font-700 wow fadeInUp mt-2 mb-3'>{params.id ? "Update Blog" : "Create Blog"}</h1>
                        </Col>
                    </Row>
                    <form onSubmit={handleBlog}>
                        <Row className='gap-3'>
                            <Col xs={12}>
                                <label className='form-label'>Blog Title</label>
                                <input className='form-control' name='title' type="text" value={formData.title} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Blog Description</label>
                                <input className='form-control' name='description' type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            {formData.blogImage &&
                                <Col xs={12}>
                                    <label className='form-label'>Uploaded Image URL</label>
                                    <p className='form-control'>{formData.blogImage}</p>
                                </Col>
                            }
                            <Col xs={12}>
                                <label className='form-label'>Blog Image</label>
                                <input className='form-control' name='blogImage' type="file" onChange={handleImage} required />
                            </Col>
                            {error &&
                                <div>
                                    <span style={{ color: 'red' }}>{error}</span>
                                </div>
                            }
                        </Row>
                        <Row className='mt-3'>
                            <div className='d-flex'><Button className='btn btn-success' onClick={handleBlog}>{params.id ? "Update Blog" : "Create Blog"}</Button>
                                <Button className='btn btn-danger' style={{ marginLeft: "15px" }} onClick={() => navigate('/dashboard/blogs')}>Cancel</Button>
                            </div>
                        </Row>
                    </form>
                </Col>
            </Row>
        </>
    );
}

export default AddUpdateBlog;
