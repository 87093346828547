import React, { createContext, useContext, useState, useEffect } from 'react';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const [formDataArray, setFormDataArray] = useState(() => {
    const savedData = localStorage.getItem('formDataArray');
    return savedData ? JSON.parse(savedData) : [];
  });

  const [campaignData, setCampaignData] = useState(() => {
    const savedData = localStorage.getItem('campaignData');
    return savedData ? JSON.parse(savedData) : null;
  });

  useEffect(() => {
    localStorage.setItem('campaignData', JSON.stringify(campaignData));
  }, [campaignData]);

  useEffect(() => {
    localStorage.setItem('formDataArray', JSON.stringify(formDataArray));
  }, [formDataArray]);

  const addFormData = (newFormData) => {
    setFormDataArray((prevArray) => [...prevArray, newFormData]);
  };

  return (
    <FormDataContext.Provider value={{ formDataArray, addFormData, setFormDataArray, campaignData, setCampaignData }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
