import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { addDataToFirebase } from "../../firebase/firebaseFunctions";
import { toast } from "react-toastify";
import { getSendOtpTemplate } from "../../emailTemplates/SendOtpTemplate";
import {
  addUserDataToFirebase,
  getOtpVerifiedStatus,
} from "../../firebase/firebaseFunctions";

export default function SendOtp() {
  const [otpInputs, setOtpInputs] = useState(new Array(4).fill(""));
  const [storedOtp, setStoredOtp] = useState("");
  const [error, setError] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("tempUser"));
  //   if (!user || !user.uid) {
  //     // navigate("/login"); // Redirect to login page if no uid is found
  //     return;
  //   }
  // }, [navigate]);

  const generateOTP = () => {
    const randomNum = Math.random() * 9000;
    const otp = Math.floor(1000 + randomNum);
    return otp;
  };

  const sendOtp = async () => {
    setOtpInputs(new Array(4).fill(""));
    setError("");
    const otp = generateOTP();
    setStoredOtp(otp);
    await sendEmail(otp);
  };

  const sendEmail = async (otp) => {
    try {
      const emailData = {
        to: auth.currentUser.email,
        subject: "Your One-Time Password (OTP) - Human Concern USA",
        html: `${getSendOtpTemplate(auth.currentUser.displayName, otp)}`,
      };
      const response = await axios.post(
        "https://hci-webapp.onrender.com/send-email",
        emailData
      );
      if (response.status === 200) {
        toast.success("OTP has been sent");
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;
    const otp = [...otpInputs];
    otp[index] = element.value;
    setOtpInputs(otp);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  // const verifyOtp = () => {
  //     const enteredOtp = otpInputs.join('');
  //     if (storedOtp === Number(enteredOtp)) {
  //         navigate('/');
  //         toast.success('Logged In successfully');
  //     } else {
  //         setError('OTP is incorrect');
  //     }
  // }

  const verifyOtp = async () => {
    const enteredOtp = otpInputs.join("");
    if (storedOtp === Number(enteredOtp)) {
      // const user = JSON.parse(localStorage.getItem("tempUser"));

      try {
        // Save user data to Firebase
        // await addUserDataToFirebase(user.uid, {
        //   firstName: user.displayName.split(" ")[0],
        //   lastName: user.displayName.split(" ")[1],
        //   email: user.email,
        //   role: "user",
        // });
        // const userData = JSON.stringify({
        //   firstName: user.displayName.split(" ")[0],
        //   lastName: user.displayName.split(" ")[1],
        //   email: user.email,
        //   role: "user",
        // });
        // Set verification status in local storage
        localStorage.setItem("isVerified", true);

        // Clear temp user data
        localStorage.removeItem("tempUser");
// localStorage.setItem("userData", userData);
        toast.success("Logged in successfully");
        console.log("Logged in successfully");
        navigate("/dashboard"); // Redirect to the dashboard
      } catch (error) {
        console.error("Error saving user data to Firebase:", error);
        toast.error("Error saving user data");
      }
    } else {
      localStorage.setItem("isVerified", false);
    //   ----
      setError("OTP is incorrect");
    }
  };

  useEffect(() => {
    const otp = generateOTP();
    setStoredOtp(otp);
    sendEmail(otp);
  }, []);

  return (
    <div>
      <section className="hero-area">
        <div className="single-slider hero-padding">
          <div className="container authContainer">
            <Row className="text-center">
              <Col>
                <h1 className="title font-700 wow fadeInUp mt-2 mb-3">
                  Please enter the One-Time Password to verify your account
                </h1>
                <p>
                  A One-Time Password has been sent to:{" "}
                  {auth?.currentUser?.email}
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                xs={12}
                className="d-flex justify-content-center text-center"
              >
                {otpInputs.map((input, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="otpInput form-control mx-2"
                    value={input}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                    style={{
                      width: "50px",
                      textAlign: "center",
                      fontSize: "24px",
                    }}
                  />
                ))}
              </Col>
              {error && (
                <div className="text-red text-center mt-3">{error}</div>
              )}
            </Row>
            <Row className="mt-4">
              <Col xs={12} className="text-center">
                <Button onClick={sendOtp}>Resend OTP</Button>
                <Button style={{ marginLeft: "15px" }} onClick={verifyOtp}>
                  Verify OTP
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
}
