import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataFromFirebase } from "../../firebase/firebaseFunctions";
import { Button } from "react-bootstrap";

export default function Reports() {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 9; // Adjust this value based on how many reports you want per page

  useEffect(() => {
    getDataFromFirebase("reports", (fetchedReports) => {
      const activeReports = fetchedReports.filter(
        (report) => report.status === "active"
      );
      setReports(activeReports);
    });
  }, []);

  const handleAddEvent = () => {
    navigate("/report");
  };

  const updateReport = (reportId) => {
    navigate(`report/${reportId}`);
  };

  // Pagination logic
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = reports.slice(indexOfFirstReport, indexOfLastReport);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(reports.length / reportsPerPage);

  return (
    <>
      <section className="mt-4 p-2">
        <div className="single-slider">
          <div className="container">
            <div
              className="row justify-content-between inner-hero blog-hero"
              style={{ backgroundImage: "url('/images/reports.webp')", backgroundPosition: '0 -200px' }}
            >
              <div className="col-12">
                <h3 className="font-700 mb-0 wow fadeInUp" data-wow-delay="0.0s">
                  Reports
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-section-three top-bottom-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7">
              <div className="section-tittle text-center mb-50">
                <h2 className="title font-700 text-capitalize">
                  Reports <span className="text-red">&</span> Publications
                </h2>
                <p className="pera wow fadeInUp" data-wow-delay="0.3s">
                  Explore our Strategic Plan, Impact Reports, Annual Reports, and Financial Reports to see how Human Concern USA is making a difference and managing your donations responsibly. Our commitment to transparency and accountability ensures that your contributions are effectively utilized to create meaningful change.
                </p>
              </div>
            </div>
          </div>

          {/* Render reports if available */}
          {currentReports.length > 0 ? (
            <>
              <div className="row donate-section gy-24 gap-x-25">
                {currentReports.map((report, index) => (
                  <div
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12 view-wrapper"
                    key={index}
                  >
                    <div className="single-donate h-calc">
                    <div className="donate-img position-relative">
                      <img
                          className="w-100"
                          src={report.image}
                        />
                    </div>
                      <div className="donate-info">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="donate-info-title w-100">
                            <h4 className="title text-capitalize mb-0">
                              {report.title}
                            </h4>
                            <p className="subtitle mb-0">{report.description}</p>
                            <a
                              href={`${report.document}`}
                              target="_blank"
                              className="btn btn-primary text-white donate-btn w-100 mt-23"
                            >
                              View Report
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              {totalPages > 1 && (
                <nav className="pagination-nav">
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "50px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    Page {currentPage} of {totalPages}
                  </p>
                  <ul className="pagination">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button
                          className="page-link next"
                          onClick={() => paginate(currentPage - 1)}
                          aria-label="Previous"
                        >
                          <i className="ri-arrow-left-line"></i>
                        </button>
                      </li>
                    )}
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          index + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    {currentPage < totalPages && (
                      <li className="page-item">
                        <button
                          className="page-link next"
                          onClick={() => paginate(currentPage + 1)}
                          aria-label="Next"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </button>
                      </li>
                    )}
                  </ul>
                </nav>
              )}
            </>
          ) : (
            <div className="row justify-content-center">
              <div className="col-xl-7">
                <p className="text-center">No reports available at this time.</p>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
