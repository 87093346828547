import React, { useState, useEffect } from 'react';
import { Col, Row, Button, ProgressBar, Container } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { addDataToFirebase } from '../../../firebase/firebaseFunctions';
import { useAuth } from '../../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import { serverTimestamp } from 'firebase/database';
import { sendEmail } from '../SendEmail';
import { updateFieldsInFirebase } from '../../../firebase/firebaseFunctions';

export default function CustomDonationForm() {

  const stripe = useStripe();
  const elements = useElements();
  const auth = useAuth();

  const Navigate = useNavigate();

  const [formData, setFormData] = useState({
    zakat: 0,
    sadaqah: 0,
    emergencyAppeal: 0,
    childSponsor: 0,
    generalAmount: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    streetAddress: '',
    stateProvinceRegion: '',
    city: '',
    country: ''
  });

  useEffect(() => {
    if(auth.currentUser) {
      setFormData({
        firstName: auth.currentUser.firstName,
        lastName: auth.currentUser.lastName,
        email: auth.currentUser.email,
        phone: auth.currentUser.phone,
        address: auth.currentUser.address,
        streetAddress: auth.currentUser.streetAddress,
        stateProvinceRegion: auth.currentUser.stateProvinceRegion,
        city: auth.currentUser.city,
        country: auth.currentUser.country,
      });
    }
  }, [auth.currentUser])

  const [step, setStep] = useState(1);

  const nextStep = () => {
      setStep((prevStep) => prevStep + 1);
  }

  const prevStep = () => {
      setStep((prevStep) => prevStep - 1);
  }

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value
      }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { token, error } = await stripe.createToken(elements.getElement(CardElement));
    if (error) {
      toast.error(error.message);
    }
    try {
      const totalAmount = parseInt(formData.zakat) + parseInt(formData.sadaqah) + parseInt(formData.childSponsor) + parseInt(formData.generalAmount) + parseInt(formData.emergencyAppeal);
      const response = await fetch('https://hci-webapp.onrender.com/capture-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': 'https://hci-webapp.vercel.app/',
        },
        mode: 'cors',
        body: JSON.stringify({
          tokenId: token.id,
          amount: totalAmount,
        }),
      });
  
      const result = await response.json();
      console.log('result: ', result);

      if (result.success) {
        try {
          const referencePath = "donation_payments";
          const data = {
            userId: auth.currentUser.uid,
            amount: totalAmount,
            zakat: formData.zakat,
            sadaqah: formData.sadaqah,
            emergencyAppeal: formData.emergencyAppeal,
            childSponsor: formData.childSponsor,
            generalAmount: formData.generalAmount,
            transactionId: result.paymentIntent.id,
            timestamp: serverTimestamp()
          }
          await addDataToFirebase(referencePath, data);

          const userData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            streetAddress: formData.streetAddress,
            stateProvinceRegion: formData.stateProvinceRegion,
            city: formData.city,
            country: formData.country,
          }

          updateFieldsInFirebase(`users/${auth.currentUser.uid}`, userData).then(() => {
            console.log('Data updated successfully');
          })
          .catch((error) => {
              console.error('Error updating data:', error);
          });

          await sendEmail(auth.currentUser.email, 'Thank you for donating - HCI Canada', '<p>Donation has been successfull</p>');

          toast.success('Your payment has been successful');
          Navigate('/thank-you');
        } catch (error) {
          console.log(error.message); 
        }
      } else if (result.error) {
        toast.error('Payment failed:', result.error);
      }
    } catch (error) {
      toast.error('Error capturing payment:', error.message);
    }
  };

  return (
    <>
      <section className="mb-3">
        <Container>
        <form onSubmit={handleSubmit}>
          <Row className='section d-flex justify-content-center'>
          <h2 className='mb-4'>Custom Amount</h2>
          <ProgressBar now={(step / 2) * 100} className="mb-4"/>
          {step === 1 &&
            <Col xs={8}>
                  <Row className='gap-3'>
                      <Col xs={12}>
                          <label className='form-label'>Zakat:</label>
                          <input className='form-control' type="number" name="zakat" value={formData.zakat} onChange={handleChange} />
                      </Col>
                      <Col xs={12}>
                          <label className='form-label'>Sadaqah Jariyah</label>
                          <input className='form-control' type="number" name="sadaqah" value={formData.sadaqah} onChange={handleChange} />
                      </Col>
                      <Col xs={12}>
                          <label className='form-label'>Emergency Appeal</label>
                          <input className='form-control' type="number" name="emergencyAppeal" value={formData.emergencyAppeal} onChange={handleChange} />
                      </Col>
                      <Col xs={12}>
                          <label className='form-label'>Child Sponsorship Program</label>
                          <input className='form-control' type="number" name="childSponsor" value={formData.childSponsor} onChange={handleChange} />
                      </Col>
                      <Col xs={12}>
                          <label className='form-label'>General (Where needed most)</label>
                          <input className='form-control' type="number" name="generalAmount" value={formData.generalAmount} onChange={handleChange} />
                      </Col>
                      <div className="d-flex align-items-center">
                          <Button onClick={nextStep}>Next</Button>
                      </div>
                  </Row>
            </Col>
          }
          {step === 2 &&
            <Col xs={8}>
                <Row>
                    <Col xs={6}>
                        <label className='form-label'>First Name</label>
                        <input className='form-control' type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-label'>Last Name</label>
                        <input className='form-control' type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                    </Col>
                    <Col xs={12}>
                        <label className='form-label'>Email</label>
                        <input className='form-control' type="email" name="email" value={formData.email} onChange={handleChange} />
                    </Col>
                    <Col xs={12}>
                        <label className='form-label'>Phone</label>
                        <input className='form-control' type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                    </Col>
                    <Col xs={12}>
                        <label className='form-label'>Address</label>
                        <input className='form-control' type="text" name="address" value={formData.address} onChange={handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-label'>Street Address</label>
                        <input className='form-control' type="text" name="streetAddress" value={formData.streetAddress} onChange={handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-label'>State / Province / Region</label>
                        <input className='form-control' type="text" name="stateProvinceRegion" value={formData.stateProvinceRegion} onChange={handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-label'>City</label>
                        <input className='form-control' type="text" name="city" value={formData.city} onChange={handleChange} />
                    </Col>
                    <Col xs={6}>
                        <label className='form-label'>Country</label>
                        <input className='form-control' type="text" name="country" value={formData.country} onChange={handleChange} />
                    </Col>
                    <Col xs={12}>
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    I would like to get news and updates from Human Concern USA
                    </label>                        
                    </Col>
                    <Col xs={12} className="d-flex">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                    I confirm the accuracy of the following statements: I am initiating a daily contribution that will occur every day for the number of times I have specified in "How Often?" option.
                    </label>                        
                    </Col>
                    <Row className="mt-3">
                    <Col>
                      <label>Credit or Debit Card</label>
                      <CardElement
                        className="form-control"
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#424770",
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                    <div className="d-flex align-items-center gap-3 mt-3">
                        <Button onClick={prevStep}>Prev</Button>
                        <Button type="submit" id="submit">Submit</Button>
                    </div>
                </Row>
            </Col>
          }
          
          <Col xs={4}>
          <div>
              <h3>Summary</h3>
              <hr/>
              <p>Nothing Selected Yet</p>
              <hr/>
              <div className="d-flex justify-content-between">
                  <p><b>Total</b></p>
                  <p><b>${parseInt(formData.zakat) + parseInt(formData.sadaqah) + parseInt(formData.childSponsor) + parseInt(formData.generalAmount) + parseInt(formData.emergencyAppeal)}</b></p>
              </div>
          </div>
          </Col>
          </Row>
          </form>
        </Container>
      </section>
    </>
  );
}
