import React from 'react'
import { BiSolidDonateHeart } from 'react-icons/bi'
import { GoProject, GoProjectRoadmap } from 'react-icons/go'
import { LuLayoutDashboard } from 'react-icons/lu'
import { MdKeyboardArrowDown, MdEventNote, MdOutlinePayment } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { HiOutlineTemplate } from "react-icons/hi";
import { GrGroup,GrAdd } from "react-icons/gr";
import { GoKey } from "react-icons/go";
import { BiDonateHeart,BiVoicemail, BiSupport } from "react-icons/bi";
import { useAuth } from "../Auth/AuthContext";
import { FaRegNewspaper, FaInfinity, FaRegFilePdf } from "react-icons/fa6";

export default function Sidebar() {
  const { currentUser } = useAuth();
  console.log('current user:', currentUser);
  return (
    <>
      <ul className="nav">
        {currentUser?.role == 'admin' ? (<>

          <li className="nav-item nav-category">Main</li>
          <li className="nav-item">
            <Link to={'/dashboard'} className="nav-link">
              <LuLayoutDashboard />
              <span className="link-title">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item nav-category">Projects & Campaigns</li>

          <li className="nav-item">
            <Link to={'/dashboard/projects'} className="nav-link">
              <GoProjectRoadmap />
              <span className="link-title">Projects</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to={'/dashboard/campaigns'} className="nav-link">
              <GoProject />
              <span className="link-title">Campaigns</span>
            </Link>
          </li>

          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="collapse" href="#uiComponents" role="button" aria-expanded="false"
              aria-controls="uiComponents">
              <BiDonateHeart />
              <span className="link-title">Appeals</span>
              <MdKeyboardArrowDown className="link-arrow" />
            </a>
            <div className="collapse" id="uiComponents">
              <ul className="nav sub-menu">
                <li className="nav-item">
                  <Link to={'/dashboard/appeals'} className="nav-link">All Appeals</Link>
                </li>
                <li className="nav-item">
                  <Link to={'/dashboard/add-appeal'} className="nav-link">Add Appeal</Link>
                </li>
              </ul>
            </div>
          </li>
          {/*
          <li className="nav-item nav-category">Emails</li>
           <li className="nav-item">
            <Link to={'/dashboard/html-editor'} className="nav-link">
              <HiOutlineTemplate />
              <span className="link-title">Add Template</span>

            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/email-templates'} className="nav-link">
              <HiOutlineTemplate />
              <span className="link-title">View Templates</span>
            </Link>
          </li> */}
          
          <li className="nav-item nav-category">Payments & Subscriptions</li>
          <li className="nav-item">
            <Link to={'/dashboard/subscription'} className="nav-link">
              <FaInfinity />
              <span className="link-title">Subscriptions</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link to={'/dashboard/payment'} className="nav-link">
              <MdOutlinePayment />
              <span className="link-title">Payment</span>
            </Link>
          </li>
          <li className="nav-item nav-category">Users</li>
          <li className="nav-item">
            <Link to={'/dashboard/users-signup'} className="nav-link">
              <GrGroup />
              <span className="link-title">All Users</span>
            </Link>
          </li>
          <li className="nav-item nav-category">Roles</li>
          <li className="nav-item">
            <Link to={'/dashboard/roles'} className="nav-link">
              <GoKey />
              <span className="link-title">Add Roles</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/add-group'} className="nav-link">
              <GrAdd />
              <span className="link-title">Create Group</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/groups'} className="nav-link">
              <GrGroup />
              <span className="link-title">Groups</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/send-email'} className="nav-link">
              <BiVoicemail />
              <span className="link-title">Send Bulk Email</span>
            </Link>
          </li>

          <li className="nav-item nav-category">CMS</li>

          <li className="nav-item">
            <Link to={'/dashboard/blogs'} className="nav-link">
              <FaRegNewspaper/>
              <span className="link-title">Blogs</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/events'} className="nav-link">
            <MdEventNote />
              <span className="link-title">Events</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/reports'} className="nav-link">
            <GoProjectRoadmap />  
              <span className="link-title">Reports</span>
            </Link>
          </li>

          <li className="nav-item nav-category">Support & Careers</li>

          <li className="nav-item">
            <Link to={'/dashboard/tickets'} className="nav-link">
              <BiSupport />
              <span className="link-title">Tickets</span>
            </Link>
          </li>
          
          <li className="nav-item">
            <Link to={'/dashboard/job-openings'} className="nav-link">
              <FaRegFilePdf />
              <span className="link-title">Job Openings</span>
            </Link>
          </li>
        </>) : (<>
          <li className="nav-item nav-category">Donations</li>
          <li className="nav-item">
            <Link to={'/dashboard/donations'} className="nav-link">
              <GoProject />
              <span className="link-title">View Donations</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/add-ticket'} className="nav-link">
              <GoProjectRoadmap />
              <span className="link-title">Add Ticket</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={'/dashboard/subscription'} className="nav-link">
              <GrGroup />
              <span className="link-title">Subscription</span>
            </Link>
          </li>
        </>)}


      </ul>
    </>
  )
}
