import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Alert, Card, Col, Row, Spinner, Table, Button } from "react-bootstrap";
import { getDataFromFirebase } from "../../firebase/firebaseFunctions";
import * as XLSX from "xlsx";
import DataTable from "../DataTable";
import DatePicker from "react-datepicker";

export default function Campaigns() {
  const { projectId } = useParams();
  const [campaigns, setCampaigns] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [donationsToday, setDonationsToday] = useState(0);
  const [donationsThisWeek, setDonationsThisWeek] = useState(0);
  const [donationsThisMonth, setDonationsThisMonth] = useState(0);
  const [totalDonors, setTotalDonors] = useState(0);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [projects, setProjects] = useState([]);
  const [appeals, setAppeals] = useState([]);
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const projectsUpdated = useRef(false);
  const [shouldCalculate, setShouldCalculate] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const navigate = useNavigate();

  const isInRange = (date) => {
    date = new Date(date);
    return date >= startDate && date <= endDate;
  };

  const fetchCampaignsAndDonations = async () => {
    const appealsReferencePath = "appeals";
    getDataFromFirebase(appealsReferencePath, (appealsData) => {
      let projectAppeals = appealsData.filter(
        (appeal) => appeal.fund_code === projectId
      );
      const campaignCodes = projectAppeals.map(
        (appeal) => appeal.campaign_code
      );

      const campaignsReferencePath = "campaigns";
      getDataFromFirebase(campaignsReferencePath, (campaignsData) => {
        const projectCampaigns = campaignsData.filter((campaign) =>
          campaignCodes.includes(campaign.id)
        );

        const donationPaymentsReferencePath = "donation_payments";
        getDataFromFirebase(donationPaymentsReferencePath, (donationsData) => {
          if (startDate && endDate) {
            donationsData = donationsData.filter((data) =>
              isInRange(data.timestamp)
            );
          }
          const updatedCampaigns = projectCampaigns.map((campaign) => {
            const filteredDonations = donationsData.filter(
              (donation) => donation.campaignData.campaign_code === campaign.id
            );
            const today = new Date();
            const startOfWeek = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - today.getDay()
            );
            const startOfMonth = new Date(
              today.getFullYear(),
              today.getMonth(),
              1
            );
            const startOfLastMonth = new Date(
              today.getFullYear(),
              today.getMonth() - 1,
              1
            );
            const endOfLastMonth = new Date(
              today.getFullYear(),
              today.getMonth(),
              0
            );
            const startOfQuarter = new Date(
              today.getFullYear(),
              Math.floor(today.getMonth() / 3) * 3,
              1
            );
            const startOfYear = new Date(today.getFullYear(), 0, 1);
            const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
            const endOfLastYear = new Date(today.getFullYear(), 0, 0);

            let thisWeek = 0;
            let thisMonth = 0;
            let lastMonth = 0;
            let thisQuarter = 0;
            let thisYear = 0;
            let lastYear = 0;
            let totalGoalAchieved = 0;

            filteredDonations.forEach((donation) => {
              const donationDate = new Date(donation.timestamp);
              const amount = parseFloat(donation.amount);

              if (donationDate >= startOfWeek) {
                thisWeek += amount;
              }
              if (donationDate >= startOfMonth) {
                thisMonth += amount;
              }
              if (
                donationDate >= startOfLastMonth &&
                donationDate <= endOfLastMonth
              ) {
                lastMonth += amount;
              }
              if (donationDate >= startOfQuarter) {
                thisQuarter += amount;
              }
              if (donationDate >= startOfYear) {
                thisYear += amount;
              }
              if (
                donationDate >= startOfLastYear &&
                donationDate <= endOfLastYear
              ) {
                lastYear += amount;
              }
              totalGoalAchieved += amount;
            });

            return {
              ...campaign,
              thisWeek,
              thisMonth,
              lastMonth,
              thisQuarter,
              thisYear,
              lastYear,
              totalGoalAchieved,
            };
          });

          setCampaigns(updatedCampaigns);
          setFilteredCampaigns(updatedCampaigns);
        });
      });
    });
  };

  useEffect(() => {
    fetchCampaignsAndDonations();
  }, [projectId]);

  const fetchData = async () => {
    setLoading(true);
    try {
      getDataFromFirebase("donation_payments", (data) => {
        processDonationData(data);
        setDonations(data);
      });

      getDataFromFirebase("projects", (data) => {
        setProjects(data);
        setTotalProjects(data.length);
        const project = data.find((p) => p.id === projectId);
        if (project) {
          setProjectName(project["project-name"]);
        }
      });

      getDataFromFirebase("appeals", (data) => setAppeals(data));
    } catch (err) {
      setError("An error occurred while fetching data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!projectsUpdated.current && projects.length > 0 && appeals.length > 0) {
      const updatedProjects = projects.map((project) => {
        const campaignCount = appeals.filter(
          (appeal) => appeal.fund_code === project.id
        ).length;
        return { ...project, campaignCount };
      });
      setProjects(updatedProjects);
      projectsUpdated.current = true;
    }
  }, [projects, appeals]);

  useEffect(() => {
    if (donations.length > 0 && projects.length > 0) {
      setShouldCalculate(true);
    }
  }, [donations.length, projects.length]);

  useEffect(() => {
    if (shouldCalculate) {
      calculateDonationAmounts();
      setShouldCalculate(false);
    }
  }, [shouldCalculate]);

  const processDonationData = (data) => {
    const today = new Date();
    const startOfToday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    let donationsToday = 0;
    let donationsThisWeek = 0;
    let donationsThisMonth = 0;
    let donors = new Set();

    data.forEach((donation) => {
      const donationDate = new Date(donation.timestamp);
      const amount = parseFloat(donation.amount);
      console.log("donation", donation);
      if (donation.campaignData.fund_code === projectId) {
        if (donationDate >= startOfToday) {
          donationsToday += amount;
        }
        if (donationDate >= startOfWeek) {
          donationsThisWeek += amount;
        }
        if (donationDate >= startOfMonth) {
          donationsThisMonth += amount;
        }
        donors.add(donation.userId);
      }
    });
    setDonationsToday(donationsToday);
    setDonationsThisWeek(donationsThisWeek);
    setDonationsThisMonth(donationsThisMonth);
    setTotalDonors(donors.size);
  };

  const calculateDonationAmounts = () => {
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const startOfQuarter = new Date(
      today.getFullYear(),
      Math.floor(today.getMonth() / 3) * 3,
      1
    );
    const startOfYear = new Date(today.getFullYear(), 0, 1);
    const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
    const endOfLastYear = new Date(today.getFullYear(), 0, 0);

    const updatedProjects = projects.map((project) => {
      let thisWeek = 0;
      let thisMonth = 0;
      let lastMonth = 0;
      let thisQuarter = 0;
      let thisYear = 0;
      let lastYear = 0;
      let totalGoalAchieved = 0;
      donations.forEach((donation) => {
        const donationDate = new Date(donation.timestamp);
        const amount = parseFloat(donation.amount);

        if (donation.campaignData.fund_code === project.id) {
          if (donationDate >= startOfWeek) {
            thisWeek += amount;
          }
          if (donationDate >= startOfMonth) {
            thisMonth += amount;
          }
          if (
            donationDate >= startOfLastMonth &&
            donationDate <= endOfLastMonth
          ) {
            lastMonth += amount;
          }
          if (donationDate >= startOfQuarter) {
            thisQuarter += amount;
          }
          if (donationDate >= startOfYear) {
            thisYear += amount;
          }
          if (
            donationDate >= startOfLastYear &&
            donationDate <= endOfLastYear
          ) {
            lastYear += amount;
          }
          totalGoalAchieved += amount;
        }
      });

      return {
        ...project,
        thisWeek,
        thisMonth,
        lastMonth,
        thisQuarter,
        thisYear,
        lastYear,
        totalGoalAchieved,
      };
    });

    setProjects(updatedProjects);
  };

  const formatDate = (date) => {
    const dateObject = new Date(date);

    // Extract the month, day, and year
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  // const handleDate = () => {
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);
  //   end.setDate(end.getDate() + 1); // End date should be exclusive

  //   const filteredCampaigns = campaigns.filter((campaign) => {
  //     const donationDate = new Date(campaign.donationDate); // Assuming `donationDate` is available on campaigns
  //     return donationDate >= start && donationDate < end;
  //   });

  //   console.log("campaigns", campaigns)

  //   console.log("filteredCampaigns", filteredCampaigns);
  //   setFilteredCampaigns(filteredCampaigns);
  // };

  useEffect(() => {
    if (startDate && endDate) {
      fetchCampaignsAndDonations();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    console.log("Projects", projects);
  });

  const exportToExcel = () => {
    const formatDate = (date) => {
      return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "2-digit",
      });
    };
    const formattedTime = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const data = [
      ["Generation Data", formatDate(new Date())],
      ["Generation Time", formattedTime],
      ["Project Name", projectName],
    ];

    if (startDate && endDate) {
      data.push([
        "Data of",
        `${formatDate(startDate)} - ${formatDate(endDate)}`,
      ]);
    }

    data.push(
      [], // Empty row for spacing
      [
        "Campaign Name",
        "This Week",
        "This Month",
        "Last Month",
        "This Quarter",
        "This Year",
        "Last Year",
        "Total Goals",
      ]
    );

    // Add project data rows
    campaigns.forEach((campaign) => {
      data.push([
        campaign["campaign-name"],
        campaign["thisWeek"],
        campaign["thisMonth"],
        campaign["lastMonth"],
        campaign["thisQuarter"],
        campaign["thisYear"],
        campaign["lastYear"],
        campaign["totalGoalAchieved"],
      ]);
    });

    // Convert data to worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);
    const summaryData = [
      ["Donations Today", `$${donationsToday.toFixed(2)}`],
      ["Donations This Week", `${donationsThisWeek}`],
      ["Donations This Month", `${donationsThisMonth}`],
      ["Total Donors", totalDonors],
      ["Total Campaigns", totalCampaigns],
      ["Total Projects", totalProjects],
    ];
    const sm = XLSX.utils.aoa_to_sheet(summaryData);

    // Create workbook and append the sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Projects");
    XLSX.utils.book_append_sheet(wb, sm, "Summary");

    // Write workbook to file
    let fileName = `Campaign`;
    if (startDate && endDate) {
      fileName += ` - ${formatDate(startDate)} to ${formatDate(endDate)}`;
    }
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  const columns = [
    {
      Header: "Campaign Name",
      accessor: "campaign-name", // accessor is the "key" in the data
    },
    {
      Header: "This week",
      accessor: "thisWeek", // accessor is the "key" in the data
    },
    {
      Header: "This Month",
      accessor: "thisMonth", // accessor is the "key" in the data
    },
    {
      Header: "Last Month",
      accessor: "lastMonth", // accessor is the "key" in the data
    },
    {
      Header: "This Quarter",
      accessor: "thisQuarter", // accessor is the "key" in the data
    },
    {
      Header: "This Year",
      accessor: "thisYear", // accessor is the "key" in the data
    },
    {
      Header: "Last Year",
      accessor: "lastYear", // accessor is the "key" in the data
    },
    {
      Header: "Total Goals",
      accessor: "totalGoalAchieved", // accessor is the "key" in the data
    },
  ];

  const handleRow = (campaign) => {
    navigate(`/dashboard/campaigns/${campaign.id}`);
  };

  return (
    <div>
      <h4 className="mb-3 mb-md-0">Campaigns for Project {projectName}</h4>
      <hr />
      <div style={{ textAlignLast: "end" }}>
        <Button onClick={exportToExcel} className="mb-4">
          Export to Excel
        </Button>
      </div>

      <Row>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations Today</h6>
              <h2 className="text-red fw-bold">${donationsToday.toFixed(2)}</h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations This Week</h6>
              <h2 className="text-red fw-bold">
                ${donationsThisWeek.toFixed(2)}
              </h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="4" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Donations This Month</h6>
              <h2 className="text-red fw-bold">
                ${donationsThisMonth.toFixed(2)}
              </h2>
              <p className="mb-0">{new Date().toLocaleDateString()}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Donors</h6>
              <h2 className="text-red fw-bold">{totalDonors}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Campaigns</h6>
              <h2 className="text-red fw-bold">{campaigns.length}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" md="3" className="mb-4">
          <Card>
            <Card.Body>
              <h6>Total Projects</h6>
              <h2 className="text-red fw-bold">{totalProjects}</h2>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className="d-flex" style={{ marginLeft: "auto" }}>
        <div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="form-control"
            placeholderText="Start Date"
          />
        </div>

        <div style={{ marginLeft: "15px" }}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="form-control"
            placeholderText="End Date"
          />
        </div>
      </div>

      <DataTable data={campaigns} columns={columns} onRowClick={handleRow} />
    </div>
  );
}
