import React, { useEffect, useState } from 'react';
import { EditorState, RichUtils, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'draft-js/dist/Draft.css';
import '../../HtmlEditor.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { addDataToFirebase } from '../../firebase/firebaseFunctions';
import { Card, Col, Row } from 'react-bootstrap';

let Editor = () => <React.Fragment />;

const HtmlEditor = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [templateName, setTemplateName] = useState('');

    useEffect(() => {
        Editor = require("react-draft-wysiwyg").Editor;
        setEditorState(EditorState.createEmpty());
    }, []);

    const saveTemplate = async (event) => {
        event.preventDefault();
        if (templateName.trim() !== '') {
            const contentState = editorState.getCurrentContent();
            const htmlContent = draftToHtml(convertToRaw(contentState));
            console.log(htmlContent);
            const templateData = {
                templateName, // Ensure key matches the table accessor
                content: htmlContent,
                timestamp: Date.now(),
            };
            try {
                await addDataToFirebase('email_templates', templateData); // Correct path
                alert('Template saved!');
            } catch (error) {
                console.error('Error saving template:', error);
                alert('Failed to save template.');
            }
        } else {
            alert('Please enter a template name.');
        }
    };

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onBoldClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    };

    const onItalicClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    };

    const onUnderlineClick = () => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    };

    return (
        <>

        <Row>
            <Col>
                <Card>
                    <Card.Body>
                        <h4>Email Template Editor</h4>
                        <div>
                            <input
                                type="text"
                                placeholder="Template Name"
                                value={templateName}
                                onChange={(e) => setTemplateName(e.target.value)}
                                className='form-control'
                            />
                            <button className='btn btn-primary mt-2' onClick={saveTemplate}>Save Template</button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
        
        <Row className='mt-3'>
            <Col>
                <Card>
                    <Card.Body>
                        <div>
                            <Editor
                                editorStyle={{
                                    width: "100%",
                                    minHeight: "250px",
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "lightgray",
                                    backgroundColor: "white",
                                    padding: "1rem",
                                }}
                                editorState={editorState}
                                onEditorStateChange={(editorState) => setEditorState(editorState)}
                                handleKeyCommand={handleKeyCommand}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                }}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row> 
            

        </>
    );
};

export default HtmlEditor;
