import Stripe from "stripe";
export const stripe = new Stripe(
  "sk_test_51PJQcsHXm3AIrcKTfLBsLmpBrFidwDrEwceHFlwb4h36o6VagdsNrQXP3QPnL4ZlIzSCjF7JOH4Ns2rL3LZE31ir00AHz1EF5S"
);

export const fetchStripeSubscription = async (subscriptionId) => {
  try {
    const stripeSubscription = await stripe.subscriptions.retrieve(
      subscriptionId
    );
    let status = stripeSubscription.status;
    if (status === "canceled") {
      status = "cancelled";
    }
    return {
      status,
      startDate: stripeSubscription.start_date,
      currentPeriodEnd: stripeSubscription.current_period_end,
      items: stripeSubscription.items.data,
    };
  } catch (error) {
    console.error("Error fetching Stripe subscription details:", error);
    return {};
  }
};
