import React, { useEffect, useMemo, useState } from "react";
import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import DataTable from "../DataTable";
import {
  getDataFromFirebase,
  updateFieldsInFirebase,
} from "../../firebase/firebaseFunctions";
import FormGenerator from "../FormGenerator";

export default function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [showAddModal, setAddShowModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState();

  const handleShow = () => setAddShowModal(true);
  const handleClose = () => setAddShowModal(false);

  useEffect(() => {
    const referencePath = "campaigns";

    getDataFromFirebase(referencePath, (campaignsArray) => {
      console.log(campaignsArray);
      campaignsArray = campaignsArray.map((cmp) => {
        return {
          ...cmp,
          active: cmp.active ? "Yes" : "No",
        };
      });
      setCampaigns(campaignsArray);
    });
  }, []);

  const columns = [
    {
      Header: "campaign code",
      accessor: "campaign-code", // accessor is the "key" in the data
    },
    {
      Header: "campaign name",
      accessor: "campaign-name", // accessor is the "key" in the data
    },
    {
      Header: "Active",
      accessor: "active", // accessor is the "key" in the data
    },
  ];

  const data = [
    {
      name: "John Doe",
      age: 28,
      country: "USA",
    },
    {
      name: "Jane Smith",
      age: 34,
      country: "UK",
    },
    {
      name: "Sam Green",
      age: 23,
      country: "Canada",
    },
  ];

  const addModalFields = [
    {
      label: "Campaign Code",
      type: "text",
      id: "campaign-code",
      classes: "",
      width: "6",
      ph: "Enter Campaign Code",
    },
    {
      label: "Campaign Name",
      type: "text",
      id: "campaign-name",
      classes: "",
      width: "6",
      ph: "Enter Campaign Code",
    },
    {
      label: "Is Active",
      type: "checkbox",
      id: "active",
      classes: "",
      width: "6",
      ph: "Set Campaign Active Status",
    },
  ];

  const referencePath = "campaigns";

  const handleRow = (row) => {
    console.log("row", row);
    setSelectedCampaign(row);
    setEditShowModal(true);
  };

  const handleUpdateCampaign = () => {
    if (!selectedCampaign) return;
    updateFieldsInFirebase(
      `campaigns/${selectedCampaign.id}`,
      selectedCampaign
    );
    setEditShowModal(false);
    setSelectedCampaign(null);
  };

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">Campaigns</h4>
            <Button variant="danger" onClick={handleShow}>
              Add Campaign
            </Button>
          </div>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <DataTable
            columns={columns}
            data={campaigns}
            onRowClick={handleRow}
          />
        </Col>
      </Row>

      <Modal show={showAddModal} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <Row>
            <Col>
              <h6 className="mb-3 mb-md-0">Add Campaign</h6>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGenerator
                fields={addModalFields}
                referencePath={referencePath}
                buttonLabel="Add Campaign"
                name="Campaign"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {showEditModal && (
        <Modal
          show={showEditModal}
          onHide={() => setEditShowModal(false)}
          centered
          size="lg"
        >
          <Modal.Body>
            <Row>
              <Col>
                <h6 className="mb-3 mb-md-0">Edit Campaign</h6>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="form-label">Campaign code</label>
                <input
                  className="form-control"
                  type="text"
                  value={selectedCampaign["campaign-code"]}
                  onChange={(e) =>
                    setSelectedCampaign({
                      ...selectedCampaign,
                      "campaign-code": e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label className="form-label">Campaign Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={selectedCampaign["campaign-name"]}
                  onChange={(e) =>
                    setSelectedCampaign({
                      ...selectedCampaign,
                      "campaign-name": e.target.value,
                    })
                  }
                />
              </Col>
              <Col>
                <label className="form-label mb-0">Is Active</label>
                <input
                  type="checkbox"
                  name="isActive"
                  checked={selectedCampaign.active === "No" ? false : true}
                  onChange={(e) =>
                    setSelectedCampaign({
                      ...selectedCampaign,
                      "active": e.target.checked,
                    })
                  }
                />
              </Col>
            </Row>
            <button
              className="btn btn-danger mt-3"
              type="button"
              onClick={handleUpdateCampaign}
            >
              Update Campaign
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
