import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col,  Row } from 'react-bootstrap';
import { addDataToFirebase, getDataFromFirebase, updateFieldsInFirebase } from '../../firebase/firebaseFunctions';
import { imageDb } from '../../firebase/firebaseConfig';
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import axios from 'axios';


function AddUpdateEvent() {
    const [formData, setFormData] = useState({ title: "", description: "", date:"", time:"", bookingUrl:"", thumbnailImage: "" });
    const [events, setEvents] = useState([]);
    const [imageUrl , setImageUrl] = useState('');
    const [imageValue, setImageValue] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const params = useParams();

    useEffect(()=>{
        getDataFromFirebase('events', (events)=>setEvents(events));
    },[])
 
    useEffect(() => {
        if (params.id) {
            let requiredEvent = {}
            if (events.length > 0) {
                requiredEvent = events.find(event => event.id === params.id);
            }
            if (requiredEvent) {
                console.log('required blog----------->', requiredEvent);
                const event = { title: requiredEvent.title, description: requiredEvent.description, image: requiredEvent.image, date:requiredEvent.date, time:requiredEvent.time, bookingUrl:requiredEvent.bookingUrl }
                setFormData(event)
            }
        }
    }, [params.id,events]);

    useEffect(()=>{
        console.log('form data--------------->', formData);
        // console.log('image url---->', imageUrl);
    },[formData, imageUrl]);

    const uploadImage = (formData) => {
        console.log('image upload api called----------->', formData)
        return axios.post('https://hci-webapp.onrender.com/upload-images', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      };

    // useEffect(()=>{
   
    //     listAll(ref(imageDb,"files")).then(imgs=>{
    //         console.log('lisAll() images:',imgs);
    //         imgs.items.forEach(val=>{
    //            getDownloadURL(val).then(url=>{
    //                 // setImageUrl(data=>[...data,url
    //                 // ])
    //                 console.log('image url------------------->', imageUrl);
    //                 setImageUrl(url)
    //              })
    //         })
    //     })
    
    // },[imageValue])

    const handleImage = async (event) => {
        console.log('target:', event.target.files[0]);
        const file = event.target.files[0];
        setImageFile(file);
    }

    const handleEvent = async (e) => {
        e.preventDefault();
        try {
            // Fetch user data from Firebase based on email
            if (formData.title && formData.description && formData.date && formData.time && formData.bookingUrl) {

                let eventObject = { title: formData.title, description: formData.description, image: imageUrl, date:formData.date, time:formData.time, bookingUrl:formData.bookingUrl }
                if (imageFile !== null) {
                    const formData = new FormData();
                    formData.append("files", imageFile);
                    const response = await  uploadImage(formData);
                    if (response.data.success) {
                        const fileName = response.data.fileNames;
                        eventObject = {
                          ...eventObject,
                          image: `https://hci-webapp.onrender.com/images/${fileName[0]}`,
                        };
                    } else {
                        // Throw an error if the file upload failed
                        throw new Error("File upload failed");
                    }
                }
                if (params.id) {
                    await updateFieldsInFirebase(`events/${params.id}`, eventObject);
                }
                else {

                    await addDataToFirebase('events', eventObject);
                }
                navigate('/dashboard/events')
                setError('');
            }
            else {
                setError('Please fill all fields')
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            toast.error('Error fetching user data');
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h4 className='title font-700 wow fadeInUp mt-2 mb-3'>{params.id ? "Update Event" : "Create Event"}</h4>
                        </Col>
                    </Row>
                    <form onSubmit={handleEvent}>
                        <Row className='gap-3'>
                            <Col xs={12}>
                                <label className='form-label'>Event Title</label>
                                <input className='form-control' name='title' type="text" value={formData.title} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Event Description</label>
                                <textarea className='form-control' name='description' type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required >
                                    
                                </textarea>
                            </Col>
                            {/* <Col xs={12}>
                                <label className='form-label'>Event Image</label>
                                <input className='form-control'
                                 name='image' accept='image/*'
                                  type="file"
                                //  value={formData.image} 
                                 onChange={handleImage} required />
                            </Col> */}
                            <Col xs={12}>
                                <label className='form-label'>Event Date</label>
                                <input className='form-control' name='date' type="date" value={formData.date} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Event Time</label>
                                <input className='form-control' name='time' type="time" value={formData.time} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            <Col xs={12}>
                                <label className='form-label'>Booking Url</label>
                                <input className='form-control' name='bookingUrl' type="text" value={formData.bookingUrl} onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })} required />
                            </Col>
                            {formData.image &&
                                <Col xs={12}>
                                    <label className='form-label'>Uploaded Image URL</label>
                                    <p className='form-control' >{formData.image}</p>
                                </Col>
                            }
                            <Col xs={12}>
                                <label className='form-label'>Upload Thumbnail Image</label>
                                <input className='form-control' name='thumbnail' type="file" onChange={(e) => handleImage(e)} required />
                            </Col>
                            {error &&
                                <div>
                                    <span style={{ color: 'red' }}>{error}</span>
                                </div>
                            }
                        </Row>
                        <Row className='mt-3'>
                            <div className='d-flex'><Button className='btn btn-success' onClick={handleEvent}>{params.id ? "Update Event" : "Create Event"}</Button>
                                <Button className='btn btn-danger' style={{ marginLeft: "15px" }} onClick={() => navigate('/dashboard/events')}>Cancel</Button>
                            </div>
                        </Row>
                    </form>
                </Col>
            </Row>
        </>
    );
}

export default AddUpdateEvent;
