import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { getNodeFromFirebase } from '../../firebase/firebaseFunctions';
import { useParams } from 'react-router-dom';

export default function DonationDetails() {

    const { id } = useParams();
    const [donationData, setDonationData] = useState();
    useEffect(() => {
        console.log("Donation Data", donationData);
    }, [donationData])
    useEffect(() => {
        const fetchDonationData = async () => {
            try {
                // Define a function to get data from Firebase as a promise
                const getNodeFromFirebasePromise = (referencePath) => {
                    return new Promise((resolve, reject) => {
                        getNodeFromFirebase(referencePath, (data) => {
                            if (data) {
                                resolve(data);
                            } else {
                                resolve(null);
                                reject(`No data found at ${referencePath}`);
                            }
                        });
                    });
                };

                // Fetch donation data
                let referencePath = `donation_payments/${id}`;
                const donation = await getNodeFromFirebasePromise(referencePath);

                // Fetch user, project, and campaign data in parallel
                const [user, project, campaign] = await Promise.all([
                    getNodeFromFirebasePromise(`users/${donation.userId}`),
                    getNodeFromFirebasePromise(`projects/${donation.project}`),
                    getNodeFromFirebasePromise(`campaigns/${donation.campaign}`)
                ]);

                // Merge the data
                const mergedDonation = {
                    ...donation,
                    user: user,
                    projectName: project && project['project-name'] ? project['project-name'] : 'Custom Donation',
                    campaignTitle: campaign && campaign.title ? campaign.title : 'Custom',
                };

                // Log and set the merged donation data
                console.log('mergedDonation: ', mergedDonation);
                setDonationData(mergedDonation);
            } catch (error) {
                console.error('Error fetching donation data:', error);
            }
        };

        fetchDonationData();
    }, [id]);


    return (
        <>
            <Row>
                <Col>
                    <h6 className="mb-3 mb-md-0">Donation Details</h6>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className='donorDetails'>
                                <Col>
                                    <p>Donor Name: {donationData?.name}</p>
                                    <p>Joining Date:{ new Date(donationData?.joiningDate).toDateString()}</p>
                                    <p>Last Donated:{ new Date(donationData?.timestamp).toDateString()}</p>
                                </Col>
                                <Col>
                                    <p>{donationData?.campaignTitle && `Campaign: ${donationData.campaignData
                                        .title}`}</p>
                                    <p>{donationData?.projectName && `Project: ${donationData.projectName}`}</p>
                                    <p>Amount: ${donationData?.amount}</p>
                                </Col>
                                <Col>
                                    <p>{donationData?.childSponsor && `Child Sponsorship: $${donationData.childSponsor}`}</p>
                                    <p>{donationData?.zakat && `Zakat: $${donationData.zakat}`}</p>
                                    <p>{donationData?.generalAmount && `General Amount: $${donationData.generalAmount}`}</p>
                                    <p>{donationData?.sadaqah && `Sadaqah: $${donationData.sadaqah}`}</p>
                                    <p>{donationData?.emergencyAppeal && `Emergency Appeal:$ ${donationData.emergencyAppeal}`}</p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    <h1></h1>
                    <h1></h1>
                    <h1></h1>
                    <h1></h1>
                    <h1></h1>
                    <h1></h1>
                    <h1></h1>
                </Col>
            </Row>
        </>
    )
}
