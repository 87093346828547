import React, { useEffect, useState } from "react";
import EditAppealForm from "../EditAppealForm";
import { Col, Row } from "react-bootstrap";
import { getDataFromFirebase } from "../../firebase/firebaseFunctions";

export default function EditAppeal() {
  const [projects, setProjects] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const projectsPath = "projects";

    getDataFromFirebase(projectsPath, (projectsArray) => {
      const formattedProjects = projectsArray.map((project) => ({
        label: `${project["project-code"]} - ${project["project-name"]}`,
        value: `${project["id"]}`,
      }));
      setProjects(formattedProjects);
      console.log("projectsArray", projectsArray);
    });

    const campaignPath = "campaigns";

    getDataFromFirebase(campaignPath, (campaignsArray) => {
      const formattedCampaigns = campaignsArray.map((campaign) => ({
        label: `${campaign["campaign-code"]} - ${campaign["campaign-name"]}`,
        value: `${campaign["id"]}`,
      }));
      setCampaigns(formattedCampaigns);
      console.log("campaignsArray", campaignsArray);
    });
  }, []);

  const faqChilds = [
    {
      label: "Question",
      type: "text",
      id: "question",
      classes: "mb-3",
      width: "6",
      ph: "Question",
    },
    {
      label: "Answer",
      type: "text",
      id: "answer",
      classes: "mb-3",
      width: "6",
      ph: "Answer",
    },
  ];
  const donationChilds = [
    {
      label: "Label",
      type: "text",
      id: "label",
      classes: "mb-3",
      width: "12",
      ph: "Label",
    },
    {
      label: "Amount",
      type: "text",
      id: "amount",
      classes: "mb-3",
      width: "12",
      ph: "Amount",
    },
    {
      label: "Description",
      type: "textarea",
      id: "description",
      classes: "mb-3",
      width: "12",
      ph: "Enter Description",
      rows: "6",
    },
  ];
  const fields = [
    {
      label: "Campaign Code",
      type: "select",
      id: "campaign_code",
      classes: "mb-3",
      width: "6",
      options: campaigns,
    },
    {
      label: "Project/Fund Code",
      type: "select",
      id: "fund_code",
      classes: "mb-3",
      width: "6",
      options: projects,
    },
    {
      label: "Title",
      type: "text",
      id: "title",
      classes: "mb-3",
      width: "12",
      ph: "Enter Title",
    },
    {
      label: "Slug",
      type: "text",
      id: "slug",
      classes: "mb-3",
      width: "12",
      ph: "Enter Slug",
    },
    {
      label: "Description",
      type: "textarea",
      id: "description",
      classes: "mb-3",
      width: "12",
      rows: "6",
    },
    {
      label: "Thumbnail",
      type: "file",
      id: "thumbnail",
      classes: "mb-3",
      width: "12",
    },
    {
      label: "Goal",
      type: "number",
      id: "goal",
      classes: "mb-3",
      width: "4",
      min: 1,
    },
    {
      label: "Start Date",
      type: "date",
      id: "start_date",
      classes: "mb-3",
      width: "4",
    },
    {
      label: "End Date",
      type: "date",
      id: "end_date",
      classes: "mb-3",
      width: "4",
    },
    {
      label: "FAQs",
      type: "repeater",
      id: "FAQs",
      classes: "mb-3",
      width: "12",
      faqChilds,
    },
    {
      label: "Donation Options",
      type: "repeater",
      id: "donation_options",
      classes: "mb-3",
      width: "12",
      donationChilds,
    },
    {
      label: "Allow Custom Amount?",
      type: "checkbox",
      id: "custom_amount_enabled",
      classes: "mb-3",
      width: "3",
    },
    {
      label: "Is Recurring?",
      type: "checkbox",
      id: "is_recurring",
      classes: "mb-3",
      width: "3",
    },
    {
      label: "Do you want quantity field?",
      type: "checkbox",
      id: "quantity_field",
      classes: "mb-3",
      width: "3",
    },
  ];

  const referencePath = "appeals";

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3 mb-md-0">Edit Appeal</h4>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditAppealForm
            fields={fields}
            referencePath={referencePath}
            buttonLabel="Edit Appeal"
            name="Appeal"
          />
        </Col>
      </Row>
    </>
  );
}
