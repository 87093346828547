import axios from 'axios';

export const sendEmail = async (to, subject, html) => {
  const emailData = {
    to: 'hasnainkh79@gmail.com',
    subject,
    html
  };

  try {
    const response = await axios.post('https://hci-webapp.onrender.com/send-email', emailData);
    console.log('response: ', response);
    if (response.status == 200) {
      console.log('Email sent successfully');
    } else {
      console.error('Failed to send email');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};
