import React from 'react'
import { Col, Row } from 'react-bootstrap';
import FormGenerator from '../FormGenerator';

export default function AddCampaign() {

    const fields = [
        { label: 'Campaign Code', type: 'text', id: 'campaign-code', classes: '', width: '6', ph: "Enter Campaign Code" },
        { label: 'Campaign Name', type: 'text', id: 'campaign-name', classes: '', width: '6', ph: "Enter Campaign Code" },
    ];

    const referencePath = "campaign";

    return (
        <>
        <Row>
            <Col>
                <h4 className="mb-3 mb-md-0">Add Campaign</h4>
                <hr/>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGenerator fields={fields} referencePath={referencePath} buttonLabel="Add Campaign" name="Campaign"/>
            </Col>
        </Row>
        </>
    )
}
