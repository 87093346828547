import React from 'react';
import { Accordion } from 'react-bootstrap';

const FAQAccordion = ({ appealData }) => {
  return (
    <Accordion defaultActiveKey="0">
      {appealData?.FAQs?.map((faq, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header>{faq.question}</Accordion.Header>
          <Accordion.Body>
            {faq.answer}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default FAQAccordion;
