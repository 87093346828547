import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  fetchDataBySlug,
  updateFieldsInFirebase,
} from "../../firebase/firebaseFunctions";
import DonationFormParent from "./DonationFormParent";
import { Accordion, Button, Card } from "react-bootstrap";
import { useAuth } from "../Auth/AuthContext";
import LoadingPage from "../Layouts/LoadingPage";
import FAQAccordion from "./FAQAccordion";

export default function AppealSingle() {
  const [showForm, setShowForm] = useState(false);
  const { slug } = useParams();
  const [comment, setComment] = useState("");
  const auth = useAuth();
  const [appealData, setAppealData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDataBySlug(slug);
  
        console.log("data", data);
        if (!data) {
          // If no data is found for the slug, redirect to 404
          navigate("/404");
        } else {
          console.log("campaign :", data);
          setAppealData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/404"); // Redirect to 404 in case of error
      } finally {
        setLoading(false); // Set loading to false once data is fetched or error occurs
      }
    };
    fetchData();
  }, [slug, navigate]); // Add navigate as a dependency

  const handleShowForm = () => setShowForm(true);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  let content = null;
  if (showForm) {
    content = (
      <div className="container mt-2">
        <Button variant="danger" onClick={handleCloseForm} className="mb-3">
          Close Form
        </Button>
        <DonationFormParent appealData={appealData} />
      </div>
    );
  }

  const formatDate = (date) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    const parts = formatter.formatToParts(date);

    // Construct the formatted date string
    const month = parts.find((part) => part.type === "month").value;
    const day = parts.find((part) => part.type === "day").value;
    const year = parts.find((part) => part.type === "year").value;
    const hour = parts.find((part) => part.type === "hour").value;
    const minute = parts.find((part) => part.type === "minute").value;
    const ampm = parts.find((part) => part.type === "dayPeriod").value;

    return `${month} ${day}, ${year} ${hour}:${minute} ${ampm}`;
  };

  const addComment = async (appealId, comments) => {
    let updatedComments = [];

    if (comments) {
      try {
        updatedComments = JSON.parse(comments);
      } catch (error) {
        console.warn("Failed to parse comments:", error);
      }
    }

    const commentObject = {
      submitter: auth?.currentUser?.displayName || "Anonymous",
      comment: comment,
      time: formatDate(new Date()),
    };

    updatedComments.push(commentObject);

    await updateFieldsInFirebase(`appeals/${appealId}`, {
      comments: JSON.stringify(updatedComments),
    });

    setComment("");

    // Re-fetch appeal data to update the comments section
    const updatedAppealData = await fetchDataBySlug(slug);
    setAppealData(updatedAppealData);
  };

  const calculateProgress = (goal, amount) => {
    if (amount) {
      console.log("goal:", goal, "amount:", Number(amount));
      const progress = (amount / Number(goal)) * 100;
      console.log("progress:", progress);
      return progress.toFixed(2);
    }
    return 0;
  };

  // Show loading page while data is being fetched
  if (loading) {
    return <LoadingPage />;
  }

  // Show content once data is loaded
  if (!appealData) {
    return <></>;
  }

  return (
    <section className="donation-section top-bottom-padding2">
      <div className="container">
        <div className="row gy-24">
          <div className="col-12">
              <h2 className="fw-bolder mb-4">{appealData.title}</h2>
          </div>
        </div>
        <div className="row gy-24">
          <div className="col-xxl-9 col-xl-8 col-lg-8">
            <div className="donate-details">
              <div className="donate-img position-relative">
                <a>
                  <img
                    className="w-100"
                    src={appealData && appealData.thumbnail}
                    alt="img"
                  />
                </a>
                <div className="donate-img-overlay"></div>
              </div>
            </div>

            {showForm ? (
              content
            ) : (
              <div class="donate-content">
                <div 
                  dangerouslySetInnerHTML={{ __html: appealData.description }} 
                />
              </div>
            )}

            {/* <div className="row">
              <div className="col-12">
                <section className="question-area ">
                  <div className="section-tittle">
                    <h3 className="font-700 mb-4">Comments</h3>
                  </div>
                  <div>
                    {appealData.comments &&
                    JSON.parse(appealData?.comments)?.length > 0 ? (
                      JSON.parse(appealData?.comments)?.map(
                        (comment, index) => (
                          <div key={index} className="mb-3">
                            <Card>
                              <Card.Body>
                                <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                  <h6>{comment.submitter}</h6>
                                  <h6 style={{fontWeight: "300"}}>{comment.time}</h6>
                                </div>
                                <p className="mb-0 mt-2">{comment.comment}</p>
                              </Card.Body>
                            </Card>
                          </div>
                        )
                      )
                    ) : (
                      <p>No comments available</p>
                    )}
                  </div>
                  <div>
                    {auth?.currentUser ? (
                      <>
                        <textarea
                          required
                          placeholder="Write a Comment"
                          value={comment}
                          className="form-control mb-2"
                          onChange={(event) => {
                            setComment(event.target.value);
                          }}
                        ></textarea>
                        <Button
                          onClick={() =>
                            addComment(appealData.id, appealData?.comments)
                          }
                        >
                          Add Comment
                        </Button>
                      </>
                    ) : (
                      <p>
                        <Link to="/login" className="text-red">
                          Login
                        </Link>{" "}
                        to add a comment
                      </p>
                    )}
                  </div>
                </section>
              </div>
            </div> */}
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4">
            <div className="right-element">
              <div className="row">
                <div className="col-12">
                  <div className="contact-us-section ">
                    {/* <div className="progress custom-progress-two mt-1">
                      <div
                        className="progress-bar"
                        style={{
                          width: `${calculateProgress(
                            appealData.goal,
                            appealData.raisedAmount
                          )}%`,
                        }}
                      >
                        {calculateProgress(
                          appealData.goal,
                          appealData.raisedAmount
                        )}
                        %
                      </div>
                    </div> */}
                    <div className="row">
                      {/* <div className="col-6">
                        <h6>Raised:</h6>
                        <h4 className="fw-bold mt-2">
                          $
                          {appealData.raisedAmount
                            ? appealData.raisedAmount
                            : 0}
                        </h4>
                      </div>
                      <div className="col-6">
                        <h6>Goal:</h6>
                        <h4 className="fw-bold mt-2">${appealData.goal}</h4>
                      </div> */}
                      {showForm ? null : (
                        <div>
                          <Link
                            to={appealData.donation_url}
                            target="_blank"
                            className="btn-primary-fill w-100 mt-2"
                          >
                            Donate Now
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {appealData?.donation_options?.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <h4 className="mb-3 singleHeadingRight">
                      Giving Levels:
                    </h4>
                  </div>
                  <div className="col-12">
                    {appealData?.donation_options?.map((donation, index) => (
                      <div
                        key={index}
                        id={index.toString()}
                        className="contact-us-section"
                      >
                        {donation.amount && (
                          <h4 className="fw-bold mb-0">${donation.amount}</h4>
                        )}

                        {donation.label && (
                          <h5 className="mt-2">{donation.label}</h5>
                        )}

                        {donation.description && (
                          <p className="mb-0">{donation.description}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
        {appealData?.FAQs?.length > 0 && (
              <div className="row gy-24 mt-4">
                  <div className="col-12 appealsFaq">
                    <section className="question-area bottom-padding">
                      <div className="section-tittle">
                        <h3 className="font-700 mb-4">
                          Frequently Asked <span className="text-red">Questions</span>
                        </h3>
                      </div>
                      <div>
                        <FAQAccordion appealData={appealData}/>  
                      </div>
                      <div className="accordion" id="accordionExample">
                        {/* {appealData?.FAQs?.map((faq, index) => (
                          <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={`heading${index}`}>
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded="true"
                                aria-controls={`collapse${index}`}
                              >
                                {faq.question}
                              </button>
                            </h2>
                            <div
                              id={`collapse${index}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`heading${index}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">{faq.answer}</div>
                            </div>
                          </div>
                        ))} */}
                      </div>
                    </section>
                  </div>
                          </div>
              )}
      </div>
    </section>
  );
}