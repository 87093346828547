import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaEnvelope, FaMapPin, FaPhoneAlt } from 'react-icons/fa';

export default function Contact() {

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://form.jotform.com/jsform/242604797761062";
    script.async = true;

    const jotformContainer = document.querySelector('.jotform-container');
    
    if (jotformContainer) {
      jotformContainer.appendChild(script);
    }

    return () => {
      const container = document.querySelector('.jotform-container');
      if (container && script) {
        container.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <section>
        <div className="container">
          <Row className="contactSection">
            <Col xs="12" md="6" className="d-flex flex-column justify-content-center">
              <h1 className="font-700 wow fadeInUp">
                Get in touch with <span className="text-red">Human Concern USA</span> today!
              </h1>
              <div className="mt-3">
                <div className="contactLink">
                  <FaMapPin /> 600 E Carmel Drive Suite 147 Carmel, IN 46032
                </div>
                <div className="contactLink">
                  <FaEnvelope /> info@humanconcernusa.org
                </div>
                <div className="contactLink">
                  <FaPhoneAlt /> 1-800-583-5841
                </div>
              </div>
            </Col>
            <Col xs="12" md="6">
              <Card className="p-2 mt-2">
                <Card.Body className="p-3">
                  <div className="jotform-container"></div> {/* JotForm will be injected here */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
